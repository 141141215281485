import { Identifiable } from '@lessonup/utils';
import _ from 'lodash';
import { Channel } from '../../channels';
import { LessonPlan } from '../../lessonPlan';
import { LessonStats } from '../../stats';
import { MongoUser } from '../../user';
import { SearchResponse, SearchResultItem } from '../SearchResponse';
import { FacetMetaData } from './FacetMetaData';
import { IndexedChannelInfo } from './IndexedChannelInfo';
import { IndexedContent } from './IndexedContent';
import { IndexedLessonBase } from './IndexedLesson';
import { IndexedLessonContent } from './IndexedLessonContent';
import { Sanitizer } from './Sanitizer';

export interface IndexedLessonPlan extends Identifiable {
  docType: 'lessonPlan';
  plan: LessonPlan;
  lessons: IndexedLessonBase[];
  indexedContent: IndexedContent;
  statistics: LessonStats;
  metaData: FacetMetaData;
  channel: IndexedChannelInfo | undefined;
  hash: string;
}

export type PartialIndexedLessonPlan = Pick<IndexedLessonPlan, '_id' | 'channel' | 'plan' | 'metaData' | 'lessons'>;
export namespace IndexedLessonPlan {
  export function from(
    plan: LessonPlan,
    lessons: IndexedLessonBase[],
    channel: Channel | undefined,
    statistics: LessonStats,
    user?: MongoUser
  ): IndexedLessonPlan | undefined {
    const lessonContent = IndexedLessonContent.fromIndexed(lessons);
    const childC = childContent(plan.children);
    const combinedContent = IndexedContent.flatten([lessonContent, childC]);

    const metaData = FacetMetaData.fromPlan(plan, user);
    const channelInfo = IndexedChannelInfo.fromChannel(channel);

    const indexedContent = IndexedContent.addContentForMultiMatch(combinedContent, plan.name, metaData, channelInfo);

    return {
      _id: plan._id,
      docType: 'lessonPlan',
      plan,
      hash: plan._id,
      lessons,
      statistics,
      metaData,
      indexedContent,
      channel: channelInfo,
    };
  }

  function childContent(children: LessonPlan.Children): IndexedContent {
    const important: string[] = [];
    const normal: string[] = [];
    _.each(children, (item) => {
      if (item.type == 'header') {
        important.push(item.text);
      } else if (item.type == 'text') {
        important.push(item.text);
      } else if (item.type == 'attachment') {
        if (item.name) important.push(item.name);
        if (item.url) normal.push(item.url);
      } else if (item.type == 'link') {
        if (item.url) normal.push(item.url);
      }
    });
    return {
      normal: normal.map((str) => Sanitizer.sanitize(str)),
      important: important.map((str) => Sanitizer.sanitize(str)),
    };
  }

  export function stripUnusedDataFromResponse(
    resp: SearchResponse<IndexedLessonPlan>
  ): SearchResponse<PartialIndexedLessonPlan> {
    const items = resp.items.map((item) => {
      const doc: SearchResultItem<PartialIndexedLessonPlan> = {
        ...item,
        doc: stripUnusedData(item.doc),
      };
      return doc;
    });
    return {
      ...resp,
      items,
    };
  }

  export function stripUnusedData(plan: IndexedLessonPlan): PartialIndexedLessonPlan {
    const firstLesson = LessonPlan.lessons(plan.plan)
      .slice(0, 1)
      .map((v) => v.id)[0];
    const lessons = plan.lessons.filter((l) => l._id === firstLesson);

    return {
      _id: plan._id,
      plan: plan.plan,
      lessons,
      channel: plan.channel,
      metaData: plan.metaData,
    };
  }
}
