export default function listFormat(t: (...args) => string, values: string[]): string | undefined {
  if (values.length === 0) return;
  if (values.length === 1) return values[0];

  return values.reduce((acc, current, index) => {
    if (values.length - index === 1) {
      return acc + ` ${t('and', { ns: 'common' })} ` + current;
    }

    return acc + ', ' + current;
  });
}
