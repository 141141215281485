import { ChannelDetails, ExtendedChannelInfo } from '../../../shared-core/domain';
import { Api } from '../../../shared-core/domain/api/LessonupApi';
import { Channel } from '../../../shared-core/domain/channels/Channel';
import { UserContext } from '../../../shared-core/domain/user';
import { TooltipName } from '../../utils/tooltips/TooltipContext';

export interface FetchedUserAction {
  type: 'fetchedUser';
  user: UserContext | undefined;
}

export const setUserAction = (user: UserContext | undefined): FetchedUserAction => ({
  type: 'fetchedUser',
  user,
});

export interface SignOutUserAction {
  type: 'signOutUser';
}

export const signOutUserAction = (): SignOutUserAction => ({
  type: 'signOutUser',
});

export interface FetchedLessonAction {
  type: 'fetchedLesson';
  response: Api.LessonByIdResponse;
}

export const fetchedLessonAction = (response: Api.LessonByIdResponse): FetchedLessonAction => ({
  type: 'fetchedLesson',
  response,
});

export interface FetchingLessonAction {
  type: 'fetchingLesson';
  lessonId: string;
}

export const fetchingLessonAction = (lessonId: string): FetchingLessonAction => ({ type: 'fetchingLesson', lessonId });

export interface ChangeRatingAction {
  type: 'changeRating';
  lessonId: string;
  rating: number;
}

export const changeRatingAction = (lessonId: string, rating: number): ChangeRatingAction => ({
  type: 'changeRating',
  rating,
  lessonId,
});

export interface FetchedLessonResultsAction {
  type: 'fetchedLessonResults';
  response: Api.LessonSearchResponse;
}

export const fetchedLessonsAction = (response: Api.LessonSearchResponse): FetchedLessonResultsAction => ({
  type: 'fetchedLessonResults',
  response,
});

export const fetchedPlansAction = (response: Api.PlanSearchResponse) => ({
  type: 'fetchedPlansResults' as const,
  response,
});

export interface FetchedPlanAction {
  type: 'fetchedPlan';
  response: Api.PlanByIdResponse;
}

export const fetchedPlanAction = (response: Api.PlanByIdResponse): FetchedPlanAction => ({
  type: 'fetchedPlan',
  response,
});

export interface FetchingPlanAction {
  type: 'fetchingPlan';
  planId: string;
}

export const fetchingPlanAction = (planId: string): FetchingPlanAction => ({ type: 'fetchingPlan', planId });

export type FetchedPinResultsAction = {
  type: 'fetchedPinResults';
  response: Api.PinSearchResponse;
};
export const fetchedPinResultsAction = (response: Api.PinSearchResponse) => ({
  type: 'fetchedPinResults',
  response,
});

export interface FetchedClusterResultsAction {
  type: 'fetchedClusterResult';
  response: Api.LessonClusterResponse;
  clusterId: string;
  forLessonId: string;
}

export const fetchedClusterAction = (
  response: Api.LessonClusterResponse,
  clusterId: string,
  forLessonId: string
): FetchedClusterResultsAction => ({
  type: 'fetchedClusterResult',
  response,
  clusterId,
  forLessonId,
});

export interface ShowTooltipAction {
  type: 'showTooltip';
  tooltipId: TooltipName;
}

export const showTooltipAction = (id: TooltipName): ShowTooltipAction => ({
  type: 'showTooltip',
  tooltipId: id,
});

export interface HideTooltipAction {
  type: 'hideTooltip';
  tooltipId: TooltipName;
}

export const hideTooltipAction = (id: TooltipName): HideTooltipAction => ({
  type: 'hideTooltip',
  tooltipId: id,
});

export const selectPinsAction = (pinIds: string[]) => ({
  type: 'selectPins' as const,
  pinIds,
});

export const deselectPinsAction = (pinIds: string[]) => ({
  type: 'deselectPins' as const,
  pinIds,
});

export const deselectAllPinsAction = () => ({
  type: 'deselectAllPins' as const,
});

export const followChannelAction = (channelSlug: string) => ({
  type: 'followChannel' as const,
  channelSlug,
});

export const unfollowChannelAction = (channelSlug: string) => ({
  type: 'unfollowChannel' as const,
  channelSlug,
});

export const fetchFollowedChannelsAction = (channels: string[]) => ({
  type: 'fetchFollowedChannels' as const,
  channels,
});

export interface SaveLessonAction {
  type: 'saveLesson';
  lessonId: string;
}

export const saveLessonAction = (lessonId: string): SaveLessonAction => ({
  type: 'saveLesson',
  lessonId,
});

export interface RemoveSavedLessonAction {
  type: 'removeSavedLesson';
  lessonId: string;
}

export const removeSavedLessonAction = (lessonId: string): RemoveSavedLessonAction => ({
  type: 'removeSavedLesson',
  lessonId,
});
export interface FetchLessonErrorAction {
  type: 'fetchLessonError';
  code: 403 | 404 | 500;
  lessonId: string;
  productError?: boolean;
  channel?: ExtendedChannelInfo;
}

export const fetchLessonErrorAction = (
  code: FetchLessonErrorAction['code'],
  lessonId: string,
  productError?: boolean
): FetchLessonErrorAction => ({
  type: 'fetchLessonError',
  code,
  productError,
  lessonId,
});

export interface FetchPlanErrorAction {
  type: 'fetchPlanError';
  code: 403 | 404 | 500;
  planId: string;
}

export const fetchPlanErrorAction = (code: FetchPlanErrorAction['code'], planId: string): FetchPlanErrorAction => ({
  type: 'fetchPlanError',
  code,
  planId,
});

export const fetchingChannelAction = (slug: string) => ({
  type: 'fetchingChannel' as const,
  slug,
});

export const fetchedChannelAction = (details: ChannelDetails) => ({
  type: 'fetchedChannel' as const,
  details,
});

export const fetchedChannelErrorAction = (code: 404 | 500, slug: string) => ({
  type: 'fetchedChannelError' as const,
  code,
  slug,
});

export const fetchedChannelContentPageAction = (contentPage: Channel.ContentPageData | undefined) => ({
  type: 'fetchedChannelContentPage' as const,
  contentPage,
});

export const fetchedFolderPageAction = (folderPage: Channel.FolderPageData | undefined) => ({
  type: 'fetchedFolderPage' as const,
  folderPage,
});

export const fetchedCurriculaPageAction = (curriculaPage: Channel.CurriculaPageData | undefined) => ({
  type: 'fetchedCurriculaPage' as const,
  curriculaPage,
});

export const navFromPageAction = (nav: string) => ({
  type: 'navFromPage' as const,
  nav,
});

export const fetchedChannelOverviewChannelsAction = (channels: ExtendedChannelInfo[]) => {
  return {
    type: 'fetchedChannelOverviewChannels' as const,
    channels,
  };
};

export const reloadChannelOverviewAction = () => {
  return {
    type: 'reloadChannelOverview' as const,
  };
};

export const clearGlobalErrorAction = () => {
  return {
    type: 'clearGlobalError' as const,
  };
};

export type AnyAction =
  | FetchedLessonResultsAction
  | FetchedLessonAction
  | FetchedPlanAction
  | ChangeRatingAction
  | FetchingLessonAction
  | FetchingPlanAction
  | FetchedUserAction
  | SignOutUserAction
  | FetchedClusterResultsAction
  | ShowTooltipAction
  | HideTooltipAction
  | FetchedPinResultsAction
  | SaveLessonAction
  | RemoveSavedLessonAction
  | FetchLessonErrorAction
  | FetchPlanErrorAction
  | ReturnType<typeof selectPinsAction>
  | ReturnType<typeof deselectPinsAction>
  | ReturnType<typeof deselectAllPinsAction>
  | ReturnType<typeof followChannelAction>
  | ReturnType<typeof unfollowChannelAction>
  | ReturnType<typeof fetchFollowedChannelsAction>
  | ReturnType<typeof fetchedPlansAction>
  | ReturnType<typeof fetchingChannelAction>
  | ReturnType<typeof fetchedChannelErrorAction>
  | ReturnType<typeof fetchedChannelAction>
  | ReturnType<typeof fetchedChannelContentPageAction>
  | ReturnType<typeof fetchedFolderPageAction>
  | ReturnType<typeof fetchedCurriculaPageAction>
  | ReturnType<typeof navFromPageAction>
  | ReturnType<typeof fetchedChannelOverviewChannelsAction>
  | ReturnType<typeof reloadChannelOverviewAction>
  | ReturnType<typeof clearGlobalErrorAction>;
