import React from 'react';
import './PinSearchNavBar.less';

interface Props {}

const PinSearchTopBar: React.FunctionComponent<Props> = ({ children }) => (
  <div className="pin-search-bar top-bar">{children}</div>
);

export default PinSearchTopBar;
