import classNames from 'classnames';
import _, { orderBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ChannelDetails, LanguageSingleton } from '../../../shared-core/domain';
import { Channel, ChannelTab, ChannelTitle, isDefaultChannelTab } from '../../../shared-core/domain/channels/Channel';
import { PublishContentExplorer, tryGetFolderFromExplorer } from '../../../shared-core/domain/newExplorers';
import { channelRoute } from '../../../shared-core/services/app/searchRoutes';
import { AnchorButton } from '../../../shared-core/ui/components/Button';
import capitalize from '../../../shared-core/utils/capitalize';
import { ImageUtils } from '../../../shared-core/utils/ImageUtils';
import transformServingImage from '../../../shared-core/utils/transformServingImage';
import FollowChannelButton from '../../components/buttons/FollowChannelButton/FollowChannelButton';
import { lessonPlanDetails, lessonStore, shouldShowPlansTab } from '../../redux/selectors';
import { ChannelAppService } from '../../services/appServices/ChannelAppService';
import { htmlDecode } from '../../utils/htmlDecode';
import { showTabsForLanguage } from '../../utils/tabs';
import ErrorPageView from '../error';
import './ChannelPage.less';
import ChannelPageMeta from './ChannelPageMeta/ChannelPageMeta';
import ChannelTabsList, { ChannelTabItem } from './ChannelTabsList';
import ChannelContentPage from './subpage/ChannelContentPage';
import ChannelCurriculaPage from './subpage/ChannelCurriculaPage';
import ChannelFolderPage from './subpage/ChannelFolderPage';
import ChannelLessonPage from './subpage/ChannelLessonPage';
import ChannelPlanPage from './subpage/ChannelPlanPage';
import ChannelSearchPage from './subpage/ChannelSearchPage';

interface FollowChannelApi {
  isFollowed: boolean;
  follow: (channelName: string) => void;
  unfollow: (channelName: string) => void;
}

export interface ActiveChannelTab {
  channelPage: string;
  selectionId: string | undefined;
  subSelectionId?: string | undefined;
}

type ChangeTab = (newTab: ActiveChannelTab, replace?: boolean) => void;

interface Props {
  details: ChannelDetails;
  channelService: ChannelAppService;
  userIsLoggedIn: boolean;
  activeTab: ActiveChannelTab;
  changeTab: ChangeTab;
}

const ChannelPageView: React.FC<Props> = ({ details, userIsLoggedIn, activeTab, channelService, changeTab }) => {
  const showPlansTab = useSelector(shouldShowPlansTab());

  return (
    <div className="channel-container">
      <ChannelHeader details={details} userIsLoggedIn={userIsLoggedIn} activeTab={activeTab} />
      <SubPage
        activeTab={activeTab}
        details={details}
        channelService={channelService}
        changeTab={changeTab}
        showTabs={showPlansTab && showTabsForLanguage()}
      />
      <ChannelPageMeta
        channelTitle={details.channel.title}
        page={activeTab.channelPage}
        channelIcon={details.channel.icon}
        subPage={activeTab.channelPage || 'start'}
      />
    </div>
  );
};

export interface ChannelSubPageProps {
  details: ChannelDetails;
  activeTab: ActiveChannelTab;
  channelService: ChannelAppService;
  changeTab: ChangeTab;
  showTabs?: boolean;
}

const SubPage: React.FC<ChannelSubPageProps> = (props) => {
  const { selectionId, channelPage } = props.activeTab;
  const { details } = props;

  const tab = channelPage || 'start';

  if (tab === 'lesson' && selectionId) {
    return <ChannelLessonPage {...props} />;
  } else if (tab === 'plan' && selectionId) {
    return <ChannelPlanPage {...props} />;
  }

  if (!details.channel.showSeries) {
    return <ChannelSearchPage {...props} />;
  }

  if (['start', 'about'].includes(tab)) {
    return <ChannelContentPage {...props} />;
  } else if (['series'].includes(tab)) {
    return <ChannelFolderPage {...props} />;
  } else if (tab === 'curricula') {
    return <ChannelCurriculaPage {...props} />;
  } else if (tab === 'search') {
    return <ChannelSearchPage {...props} />;
  }
  return (
    <ErrorPageView
      type="page"
      backUrl={channelRoute({
        channelSlug: props.details.channel.name,
      })}
    />
  );
};

export default ChannelPageView;

interface ChannelHeaderProps {
  details: ChannelDetails;
  userIsLoggedIn: boolean;
  coverImageOverride?: string;
  activeTab: ActiveChannelTab;
}

const ChannelHeader: React.FC<ChannelHeaderProps> = ({ details, coverImageOverride, userIsLoggedIn, activeTab }) => {
  const { channel, publicFolder } = details;
  const iconStyle = {
    backgroundImage: channel.icon && ImageUtils.urlString(transformServingImage(channel.icon, { size: 150 })),
  };

  return (
    <div className="chan-header">
      <div
        className={classNames('visual', { 'folder-visual': !!coverImageOverride })}
        style={channelBannerStyle(channel, coverImageOverride)}
      ></div>
      <div className="glasspane"></div>
      <div className="channel-icon-and-name">
        <div className="header-icon" style={iconStyle}></div>
        <ChannelNameDescription channel={channel} userIsLoggedIn={userIsLoggedIn} />
      </div>
      <ChannelTabs channel={channel} activeTab={activeTab} publicFolder={publicFolder} />
    </div>
  );
};

function channelBannerStyle(channel: Channel, coverImageOverride?: string): React.CSSProperties {
  if (coverImageOverride) {
    return {
      backgroundImage: ImageUtils.urlString(transformServingImage(coverImageOverride, { size: 1280 })),
    };
  }

  return {
    backgroundImage: channel.cover && ImageUtils.urlString(transformServingImage(channel.cover, { size: 1280 })),
    backgroundPosition: `center ${channel.coverFocus}%`,
  };
}

const ChannelNameDescription: React.FC<{
  channel: Channel;
  userIsLoggedIn: boolean;
}> = ({ channel, userIsLoggedIn }) => {
  const { t } = useTranslation('channel');

  const localizedAndDecodedChannelTitle = htmlDecode(
    ChannelTitle.localizeTitle(channel.title, LanguageSingleton.get())
  );
  const localizedAndDecodedChannelSubTitle = htmlDecode(
    ChannelTitle.localizeTitle(channel.subtitle, LanguageSingleton.get())
  );

  return (
    <div className="channel-name-description">
      <div className="channel-name">{localizedAndDecodedChannelTitle}</div>
      {channel.subtitle && <div className="subtitle">{localizedAndDecodedChannelSubTitle}</div>}
      <div className="buttonbar">
        {userIsLoggedIn && (
          <FollowChannelButton
            channelTitle={localizedAndDecodedChannelTitle}
            requireFollow={Channel.followRequired(channel)}
            tracker={Channel.channelTrackerIfEnabled(channel)}
            channelSlug={channel.name}
            followContext={{ trigger: 'channel-page' }}
            className="button-margin-right"
          />
        )}
        {channel.website && (
          <AnchorButton
            href={channel.website}
            target="_blank"
            className="icon icon-dark icon-external"
            theme="secondary"
            height="small"
          >
            {capitalize(t('visitWebsite'))}
          </AnchorButton>
        )}
      </div>
    </div>
  );
};

const getFolderTabs = (publicFolder: PublishContentExplorer): ChannelTab[] => {
  const folders = orderBy(publicFolder.children, 'order');
  const lang = LanguageSingleton.get();
  const filtered = folders.filter(
    (f) => !f.showForLanguage || f.showForLanguage === 'all' || f.showForLanguage === lang
  );
  return filtered.map((folder) => ({
    name: folder.name || folder._id,
    id: folder._id,
    path: `/series/${folder._id}`,
  }));
};

const ChannelTabs: React.FC<{
  channel: Channel;
  activeTab: { channelPage: string | undefined; selectionId: string | undefined };
  publicFolder: PublishContentExplorer | undefined;
}> = ({ channel, activeTab, publicFolder }) => {
  const { t } = useTranslation('channel');
  const plans = useSelector(lessonPlanDetails());
  const lessons = useSelector(lessonStore());

  if (!channel.showSeries) return null;

  // Note: order of defaultTabs is relevant.
  const defaultTabs: ChannelTab[] = [
    {
      id: 'start',
      name: t('start'),
      path: '/start',
    },
    {
      id: 'search',
      name: t('search'),
      path: '/search',
    },
    {
      id: 'about',
      name: t('about'),
      path: '/about',
    },
    {
      id: 'curricula',
      name: t('curricula'),
      path: '/curricula',
    },
  ].filter((tab) => channelHasTab(tab.id));

  const rootTabs =
    publicFolder && channel.useRootAsTabs
      ? getFolderTabs(publicFolder)
      : [{ id: 'series', name: t('series'), path: '/series' }];

  let tabs = [defaultTabs[0], ...rootTabs, ...defaultTabs.slice(1)];

  if (defaultTabs[0].id === 'search') {
    tabs = [...rootTabs, defaultTabs[0], ...defaultTabs.slice(1)];
  }

  function channelHasTab(tabId: string): boolean {
    switch (tabId) {
      case 'start':
        return Boolean(channel.start && Object.values(channel.start).some((val) => val && !_.isEmpty(val)));
      case 'about':
        return Boolean(channel.about && Object.values(channel.about).some((val) => val && !_.isEmpty(val)));
      case 'search':
        return true;
      case 'curricula':
        return Boolean(channel.curricula?.length);
      case 'series':
        return !channel.useRootAsTabs;
      default:
        return false;
    }
  }

  function isActive(tab: ChannelTab): boolean {
    const { channelPage } = activeTab;

    const isSeriesLessonOrPlan = channelPage && ['series', 'lesson', 'plan'].includes(channelPage);

    if (isSeriesLessonOrPlan && channel.useRootAsTabs && publicFolder && activeTab.selectionId) {
      if (channelPage === 'lesson') {
        const location = lessons.lessonDetails?.lesson.location;
        if (location?.explorer !== publicFolder._id) return false;

        const info = tryGetFolderFromExplorer(publicFolder, location.folder);
        if (!info) return false;
        const showParentAsActive = tab.id && info.parents.some((folder) => folder._id === tab.id);
        return showParentAsActive ? true : false;
      }

      if (channelPage === 'plan') {
        const location = plans.planDetails?.plan.location;
        if (location?.explorer !== publicFolder._id) return false;

        const info = tryGetFolderFromExplorer(publicFolder, location?.folder);
        if (!info) return false;
        const showParentAsActive = tab.id && info.parents.some((folder) => folder._id === tab.id);
        return showParentAsActive ? true : false;
      }

      const info = tryGetFolderFromExplorer(publicFolder, activeTab.selectionId);
      if (info) {
        const showParentAsActive = tab.id && info.parents.some((folder) => folder._id === tab.id);
        if (showParentAsActive) return true;
      }
    }

    if (channelPage && ['lesson', 'plan'].includes(channelPage)) {
      return tab.id === 'series';
    }

    if (isDefaultChannelTab(tab)) {
      return tab.id === channelPage;
    }

    return tab.id === activeTab.selectionId;
  }

  return (
    <ChannelTabsList channel={channel}>
      {tabs.map((tab) => (
        <ChannelTabItem tab={tab} active={isActive(tab)} key={tab.id} channelName={channel.name} />
      ))}
    </ChannelTabsList>
  );
};
