import classNames from 'classnames';
import React from 'react';
import { useBEM } from '../../utils/hooks';
import { Button, ButtonProps } from '../Button/Button';
import LinkButton from '../links/LinkButton';
import './Notification.less';

type Theme = 'white' | 'blue';
type ButtonType = 'button' | 'linkButton';
export interface NotificationProps {
  className?: string;
  image?: 'message' | 'danger' | 'globe' | ((className: string) => JSX.Element);
  height?: 'small' | 'medium' | 'large';
  theme?: Theme;
  onClick?: () => void;
  onDismiss?: () => void;
  button?: {
    text: string;
    type?: ButtonType;
    role?: string;
    onClick?: () => void;
    height?: ButtonProps['height'];
  };
}

export const Notification: React.FC<NotificationProps> = ({
  image,
  button,
  height = 'large',
  theme = 'white',
  onClick,
  onDismiss,
  children,
  className,
}) => {
  const bemClasses = useBEM('notification-bar');
  const heightClass = `height-${height}`;
  const themeClass = `theme-${theme}`;

  return (
    <div className={classNames('notification-bar', bemClasses({ modifiers: [heightClass, themeClass] }), className)}>
      <Image image={image} />
      {children}
      {button &&
        (button.type === 'linkButton' ? (
          <LinkButton
            bgColor="transparent"
            fgColor="blue"
            text={button.text}
            data-role={button.role}
            onClick={button.onClick || onClick}
            height={button.height}
            role="button"
          />
        ) : (
          <Button
            version="modern"
            theme="secondary"
            data-role={button.role}
            onClick={button.onClick || onClick}
            height={button.height}
            role="button"
          >
            {button.text}
          </Button>
        ))}
      {onDismiss && (
        <div
          onClick={onDismiss}
          className={bemClasses({ element: 'close-cross', className: 'icon icon-dark icon-close2' })}
        ></div>
      )}
    </div>
  );
};

const Image: React.FC<{ image: NotificationProps['image'] }> = ({ image }) => {
  if (!image) return null;

  if (typeof image === 'string') {
    return <img className="notification-image" alt="" src={`/svg/${image}.svg`} />;
  }

  return image('notification-image');
};
