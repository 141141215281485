import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { EnvConfig } from '../shared-core/services';
import { HrefLangTags } from './utils/HrefLangTags';
import pageTitle from './utils/pageTitle';

const TRANSLATION_NS = 'app';

export default function AppMetaTags({ config }: { config: EnvConfig }) {
  const { t } = useTranslation(TRANSLATION_NS);
  const location = useLocation();
  const { pathname } = location;
  const { country } = useParams<{ country: string }>();
  return (
    <>
      <Helmet>
        <title>{pageTitle(t('pageTitle'))}</title>
        <meta name="robots" content="index" />
        <meta name="twitter:site" content="@lessonup" />
        <link rel="canonical" href={config.appUrl('search', pathname)} />
      </Helmet>
      <HrefLangTags countryCode={country} />
    </>
  );
}
