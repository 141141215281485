import React, { ComponentType } from 'react';
import { MongoUser } from '../../domain';
import BlazeTemplate from './BlazeTemplate';
import { AppRouter } from './router';
import { Translations, Translator } from './translations';

export * from '../../ui/dependencies/initi18next';

// alle dependecies nodig door onze componenten in react staan hier
// deze kunnen worden geimplementeerd door de verschillende omgevingen die de react templates inladen zoals
// teacher client, teacher server (SSR)

export const CurrentUserContext: React.Context<MongoUser | undefined> = React.createContext(undefined) as React.Context<
  MongoUser | undefined
>;
// TODO: Fix casting

export function initializeBasicDependencies(
  router: AppRouter,
  translator: Translator,
  BlazeComponent: ComponentType<any>
) {
  BlazeTemplate.configure(BlazeComponent);
  Translations.initialize(translator);
  AppRouter.initialize(router);
}
