import React from 'react';

import { logger } from '@lessonup/client-integration';
import { LanguageKey, LanguageSingleton } from '../../../shared-core/domain';
import Footer, { FooterType } from '../../../shared-core/ui/components/Footer/Footer';
import LanguageSwitch from '../../../shared-core/ui/components/LanguageSwitch/LanguageSwitch';
import { useAppServices } from '../appServices/AppServicesContext';

interface Props {
  footerType: FooterType;
}

export const FooterWithLanguageSwitch: React.FC<Props> = ({ footerType, children }) => {
  const services = useAppServices();

  async function handleLanguageChange(lang: LanguageKey) {
    try {
      await services.user.setLanguage(lang);
    } catch (error) {
      logger.error(error);
    }
  }

  const Picker = (
    <LanguageSwitch
      expandDirection="top"
      handleLanguageChange={handleLanguageChange}
      className="language-switch-navbar"
      currentLanguage={LanguageSingleton.get()}
    />
  );

  return (
    <Footer footerType={footerType} slotRight={Picker}>
      {children}
    </Footer>
  );
};
