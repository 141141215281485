import { isArray, isObject, isPlainObject, isString, mapValues } from 'lodash';

type SanitizeFunction = (html: string, options: any) => string;
export namespace Sanitizer {
  export interface SanitizeFunctionOptions {
    allowedTags?: string[];
  }

  type SanitizeFunction = (html: string, options?: SanitizeFunctionOptions) => string;

  let sanitizeFunction: SanitizeFunction;
  // coupled to https://www.npmjs.com/package/sanitize-html
  // override if needed
  let sanitizeOptions: SanitizeFunctionOptions = {
    // we allow no TAGS, default of package is some
    allowedTags: [],
  };

  export function use(func: SanitizeFunction, options?: SanitizeFunctionOptions) {
    sanitizeFunction = func;
    if (options) {
      sanitizeOptions = options;
    }
  }

  export function sanitize(html: string) {
    if (!sanitizeFunction) {
      console.warn('sanitize called without sanitizer');
      return html;
    }
    const res = sanitizeFunction(html, sanitizeOptions);
    return res.trim();
  }
}

/**
 *
 * @description Given an object as an input, this method will remove any html from deeply nested string values.
 */
export function sanitizeAllStrings<T>(input: T, sanitizeFunction: (html: string) => string = Sanitizer.sanitize): T {
  if (!input) return input;

  if (isArray(input)) {
    return input.map((single) => sanitizeAllStrings(single, sanitizeFunction)) as unknown as T;
  }

  if (isPlainObject(input) && isObject(input)) {
    return mapValues(input, (single) => sanitizeAllStrings(single, sanitizeFunction)) as unknown as T;
  }

  return (isString(input) ? sanitizeFunction(input) || '' : input) as unknown as T;
}
