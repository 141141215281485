import { color, styled } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Language, LanguageKey, LanguageSingleton } from '../../../domain';
import './Footer.less';

const TRANSLATION_NS = 'footer';

export type FooterType = 'embed' | 'default';

type Props = {
  footerType: FooterType;
  slotRight?: React.ReactNode;
};

const Footer: React.FC<Props> = ({ footerType, children, slotRight }) => {
  const { t } = useTranslation(TRANSLATION_NS);

  return (
    <StyledFooter className="search-footer">
      {children}
      <div className="search-footer-nav-container">
        <div className="search-footer-nav">
          {getNavLinks(footerType, LanguageSingleton.get()).map(([translationKey, url]) =>
            footerType === 'embed' ? (
              <a key={url} href={url} target="_blank" rel="noopener noreferrer" className="search-footer-nav-item">
                {t(translationKey)}
              </a>
            ) : (
              <a key={url} href={url} className="search-footer-nav-item">
                {t(translationKey)}
              </a>
            )
          )}
        </div>
      </div>
      {slotRight && <div className="search-footer-slot-right">{slotRight}</div>}
    </StyledFooter>
  );
};

function getNavLinks(footerType: FooterType, language: LanguageKey): [string, string][] {
  const links: Language.Dict<[string, string][]> = {
    nl: [
      ['terms', '/nl/voorwaarden'],
      ['privacy-statement', '/nl/privacy'],
      ['cookie-statement', '/nl/cookie-statement'],
      ['contact', '/nl/contact'],
    ],
    en: [
      ['terms', '/en/terms'],
      ['privacy-statement', '/en/privacy'],
      ['cookie-statement', '/en/cookie-statement'],
      ['contact', '/en/contact'],
    ],
  };

  const embedLinks: Language.Dict<[string, string][]> = {
    nl: [
      ['info', 'https://www.lessonup.com/nl/wat-is-lessonup'],
      ['terms', '/nl/voorwaarden'],
      ['contact', '/nl/contact'],
    ],
    en: [
      ['info', 'https://www.lessonup.com/en/about'],
      ['terms', '/en/terms'],
      ['contact', '/en/contact'],
    ],
    fr: [
      ['info', 'https://www.lessonup.com/en/about'],
      ['terms', '/en/terms'],
      ['contact', '/en/contact'],
    ],
  };

  return footerType === 'embed'
    ? Language.fromDictWithFallback(embedLinks, language)
    : Language.fromDictWithFallback(links, language);
}

const StyledFooter = styled.footer`
  background-color: ${color.accent.secondary.background};
  color: ${color.accent.secondary.text};
`;

export default Footer;
