import { Colors, Component } from '@lessonup/teaching-core';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { FontsSingleton } from '../../../../services/fonts/FontsSingleton';
import ComponentContainer from '../componentContainer/ComponentContainer';

const TEXT_COMPONENT_CLASS = 'text-comp-inner';

interface Props {
  component: Component.Text;
  isThumb?: boolean;
}

export default class TextComponentView extends React.PureComponent<Props> {
  private initialRenderCompleted = false;
  private linkEventListeners: [Element, EventListener][] | undefined = undefined;

  public componentDidMount(): void {
    this.initialRenderCompleted = true;
    if (this.props.isThumb) return;
    const elements = document.querySelectorAll(`.${TEXT_COMPONENT_CLASS} a`);
    elements.forEach((el) => {
      function listener(event: MouseEvent) {
        event.preventDefault();
        if (!event.currentTarget || !event.currentTarget['href']) return;

        // We override the default anchor click, so that links are always opened in a new tab.
        window.open(event.currentTarget['href'], '_blank');
      }

      el.addEventListener('click', listener);

      this.linkEventListeners?.push([el, listener]);
    });
  }

  public componentWillUnmount(): void {
    this.linkEventListeners?.forEach(([el, eventListener]) => el.removeEventListener('click', eventListener));
  }

  private stylingClasses = (): string => {
    const { settings } = this.props.component;
    const { fontFamily, fontSize, textAlign, verticalAlign, transparency } = settings;
    const font = `font-${fontFamily} font-size-${fontSize}`;
    const padding = `text-padding-${settings.padding}`;
    const alignment = `text-${textAlign} ${verticalAlign}`;
    const opacity = transparency !== undefined && `opacity-${transparency}`;
    return _.compact(['text-value', font, padding, alignment, opacity]).join(' ');
  };

  private innerClasses = () => {
    const { settings } = this.props.component;
    const { columns, borderColor, borderWidth } = settings;
    const bw = borderColor && borderWidth && borderColor !== 'none' && `border-width-${borderWidth}`;
    const shadow = settings.shadow ? 'shadow' : false;
    return _.compact([columns && `text-columns-${columns}`, bw, shadow]).join(' ');
  };

  private innerStyles(): React.CSSProperties {
    const { settings } = this.props.component;
    const borderColor = (settings.borderColor !== undefined && Colors.getHexColor(settings.borderColor)) || undefined;
    const backgroundColor =
      (settings.backgroundColor !== undefined && Colors.getHexColor(settings.backgroundColor)) || undefined;
    const borderRadius = settings.borderRadius;
    return {
      borderColor,
      borderRadius,
      backgroundColor,
    };
  }

  public render() {
    const { settings } = this.props.component;
    const html = settings.text.replace(/\n/gi, '<br />');

    const { fontFamily } = this.props.component.settings;

    if (!this.initialRenderCompleted && fontFamily) {
      FontsSingleton.add([fontFamily]);
    }

    return (
      <ComponentContainer component={this.props.component}>
        <div className={classNames('text-comp-inner', this.innerClasses())} style={this.innerStyles()}>
          <div className={this.stylingClasses()} data-grippy="move" dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </ComponentContainer>
    );
  }
}
