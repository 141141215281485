import React, { MouseEvent } from 'react';
import { useBEM } from '../../../../utils/hooks';
import { Order } from '../../rows';
import { CellProps } from '../Cell/Cell';
import './HeaderCell.less';

export interface HeaderCellProps extends CellProps {
  onClick?: (event: MouseEvent<HTMLTableHeaderCellElement>) => void;
  sortOrder?: Order;
  className?: string;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({ children, className, sortOrder, ...props }) => {
  const bemClasses = useBEM('HeaderCell');

  return (
    <th className={bemClasses({ modifiers: [...(props.onClick ? ['clickable'] : [])], className })} {...props}>
      {children}
      {!!sortOrder && (
        <span className={bemClasses({ element: 'sort-indicator' })}>{sortOrder === 'asc' ? '↓' : '↑'}</span>
      )}
    </th>
  );
};
