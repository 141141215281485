import React from 'react';
import { useBEM } from '../../utils/hooks';
import './Card.less';

export interface CardProps {
  className?: string;
}
export const Card: React.FC<CardProps> = ({ children, className }) => {
  const bemClasses = useBEM('card');
  return <div className={bemClasses({ className })}>{children}</div>;
};
