import { tracker } from '@lessonup/analytics';
import { referralRoute } from '@lessonup/teacher-modern';
import { styled, ToastContainer } from '@lessonup/ui-components';
import classNames from 'classnames';
import { History } from 'history';
import i18n from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Router, Switch, useLocation } from 'react-router';
import { LanguageKey } from '../shared-core/domain';
import {
  channelOverviewRoute,
  childOrganizationPickerRoute,
  depricatedRegisterRoute,
  embedRoute,
  exportLessonRoute,
  forgotPasswordRoute,
  languageKey,
  lessonPlanRoute,
  lessonRoute,
  loginRoute,
  recoverPasswordRoute,
  registerRoute,
  registerRouteStepKey,
  renewMagicLinkRoute,
  searchClusterRoute,
  validatedErrorRoute,
  validatedSuccessRoute,
  voucherRoute,
} from '../shared-core/services/app/searchRoutes';
import CookiePopup from '../shared-core/ui/components/modals/CookiePopup';
import ModalManagerComponent from '../shared-core/ui/utils/ModalManagerComponent';
import AppMetaTags from './AppMetaTags';
import AppProviders from './AppProviders';
import { LoginWall } from './components/login/LoginWall';
import { LoginStateWatcher } from './components/LoginStateWatcher/LoginStateWatcher';
import { SearchAppLogo } from './components/Logo/SearchAppLogo';
import NavBar, { NavBarThemeOptions } from './components/navbars/NavBar';
import { FooterWithLanguageSwitch } from './components/SearchFooter/FooterWithLanguageSwitch';
import ChannelPage from './pages/channel/ChannelPage';
import ChannelOverviewPage from './pages/channelOverview/ChannelOverviewPage';
import { ChildPickerPage } from './pages/childPicker/ChildPickerPage';
import ClusterPage from './pages/cluster';
import EmbedLessonPage from './pages/embed/EmbedLessonPage';
import ErrorPageView from './pages/error';
import { ServerErrorPage } from './pages/error/ServerErrorPage';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPasswordPage';
import { ExportLessonPage } from './pages/lesson';
import LessonRootPage from './pages/lesson/LessonRootPage';
import LessonPlanRootPage from './pages/lessonPlan/LessonPlanRootPage';
import LessonThumbPage from './pages/lessonThumb';
import LoginPage from './pages/login/LoginPage';
import LtiDeeplinkError from './pages/ltiDeeplinkError';
import LtiDeeplinkPage from './pages/ltiDeeplinkPage';
import RegistrationPage from './pages/registration';
import ResetPasswordPage from './pages/registration/ResetPasswordPage/ResetPasswordPage';
import { RegistrationPageProps } from './pages/registration/types';
import { isRegistrationStep } from './pages/registration/utils/isRegistrationStep';
import { RegistrationContextProvider } from './pages/registration/utils/RegistrationContext';
import RenewMagicLinkPage from './pages/renewMagicLink/renewMagicLinkPage';
import LessonSearchPage from './pages/search/lessons';
import PinSearchPage from './pages/search/pins';
import PlanSearchPage from './pages/search/plans';
import ValidateEmailPage from './pages/validateEmail/ValidateEmailPage';
import VoucherPage from './pages/VoucherPage/VoucherPage';
import { ServerErrorDetails } from './redux/reducers/errorReducer';
import { currentError } from './redux/selectors';
import { AppStore } from './redux/store';
import { AppServices } from './services/AppServices';
import './stylesheets/all.js';
import './stylesheets/app.less';
import './stylesheets/main.less';
import { setBridgeLogger } from './utils/bridge/initBridge';
import {
  channelRouterRoute,
  popupExemptRoutes,
  routerSearchItemRoute,
  routerSearchLessonsRoute,
  routerSearchPlanRoute,
} from './utils/routeUtils/routeUtils';
import { useTopLevelClassnameContext } from './utils/TopLevelClassnameContext';

export interface Props {
  isClient: boolean;
  history: History;
  services: AppServices;
  store: AppStore;
  i18n: i18n.i18n;
}

interface LayoutProps {
  services: AppServices;
  navBarThemeOptions?: NavBarThemeOptions;
  footer?: 'default' | 'embed' | 'none';
  navBarOptions: { show: boolean; hideContent?: boolean; fixPosition?: boolean };
}

const channelNavTheme: NavBarThemeOptions = {
  theme: 'secondary',
  secondaryTheme: 'default',
  themeScrollPosition: 60,
};

setBridgeLogger();

const ToggleNavSideButtonClassName = 'toggle-nav-side-button';
const AppLayout: React.FunctionComponent<LayoutProps> = (props) => {
  const { services, footer = 'default' } = props;
  const { className } = useTopLevelClassnameContext();
  const error = useSelector(currentError());
  const { navBarOptions, navBarThemeOptions, childrenOrErrorPage } = overridePropsIfError(error, props);

  return (
    <div
      className={classNames('h-100', className, { 'app-wrapper-with-fixed-navbar': props.navBarOptions.fixPosition })}
    >
      <AppMetaTags config={services.config} />
      {props.navBarOptions.show && (
        <NavBar
          userService={services.user}
          modalService={services.modals}
          config={services.config}
          navBarThemeOptions={navBarThemeOptions}
          navBarOptions={{ hideContent: !!navBarOptions.hideContent }}
          toggleButtonClassName={ToggleNavSideButtonClassName}
        />
      )}

      <div className={'app-layout'}>
        <div className="app-layout-content">{childrenOrErrorPage}</div>
        {footer && footer !== 'none' && (
          <FooterWithLanguageSwitch footerType={footer}>
            <SearchAppLogo isSignedIn={!!services.user.currentUser()}></SearchAppLogo>
          </FooterWithLanguageSwitch>
        )}
      </div>
    </div>
  );
};

/**
 * In case on a global error, we always override the props to show the error page
 * If we do not client side will hydrate the error page, we will get a mismatch between the server and client
 */
const overridePropsIfError = (
  error: ServerErrorDetails | null,
  props: React.PropsWithChildren<LayoutProps>
): Pick<React.PropsWithChildren<LayoutProps>, 'navBarOptions' | 'navBarThemeOptions'> & {
  childrenOrErrorPage?: React.ReactNode;
} => {
  if (error) {
    return {
      navBarOptions: {
        show: true,
        hideContent: true,
      },
      navBarThemeOptions: {
        theme: 'default',
      },
      childrenOrErrorPage: <ServerErrorPage error={error} />,
    };
  }
  const { navBarOptions, navBarThemeOptions, children } = props;
  return { navBarOptions, navBarThemeOptions, childrenOrErrorPage: children };
};

export default function App({ services, history, store, i18n }: Props) {
  return (
    <Router history={history}>
      <AppProviders services={services} i18n={i18n} store={store} history={history}>
        <ModalManagerComponent service={services.modals} />
        <PopupsOnPageLoad history={history} />
        <LoginStateWatcher />
        <ToastContainerStyled id="searchToastContainer">
          <ToastContainer />
        </ToastContainerStyled>
        <Switch>
          <Route path={'/search/lti/deeplinkpage'} component={(props: RouteComponentProps) => <LtiDeeplinkPage />} />
          <Route
            path={'/search/lti/unsupported-browser'}
            component={(props: RouteComponentProps) => <LtiDeeplinkError />}
          />
          <Route
            path={lessonRoute(':id', ':pinId?', languageKey)}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                <LessonRootPage
                  {...props}
                  lessonId={props.match.params['id']}
                  preselectedPin={props.match.params['pinId']}
                />
              </AppLayout>
            )}
          />
          <Route
            path={exportLessonRoute(':id', languageKey)}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} footer={'none'} navBarOptions={{ show: false, hideContent: true }}>
                <ExportLessonPage lessonId={props.match.params['id']} />
              </AppLayout>
            )}
          />
          <Route
            path={lessonPlanRoute(':planId')}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                <LessonPlanRootPage {...props} planId={props.match.params['planId']} />
              </AppLayout>
            )}
          />
          <Route
            path={searchClusterRoute(':lessonId', languageKey)}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                <ClusterPage {...props} lessonId={props.match.params['lessonId']} services={services} />
              </AppLayout>
            )}
          />
          <Route
            path={routerSearchLessonsRoute()}
            exact
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false, fixPosition: true }}>
                <LessonSearchPage {...props} />
              </AppLayout>
            )}
          />
          <Route
            path={renewMagicLinkRoute({ language: languageKey })}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                <RenewMagicLinkPage {...props} />
              </AppLayout>
            )}
          />
          <Route
            path={forgotPasswordRoute(languageKey)}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                <ForgotPasswordPage {...props} />
              </AppLayout>
            )}
          />
          <Route
            path={recoverPasswordRoute({ language: languageKey })}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                <ResetPasswordPage {...props} services={services} />
              </AppLayout>
            )}
          />
          <Route
            path={routerSearchItemRoute()}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: false }} footer={'none'}>
                <PinSearchPage {...props} services={services} />
              </AppLayout>
            )}
          />
          <Route
            path={routerSearchPlanRoute()}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false, fixPosition: true }}>
                <PlanSearchPage {...props} />
              </AppLayout>
            )}
          />
          <Route
            path={registerRoute(undefined, true, languageKey, ':organizationId?')}
            component={(
              props: RouteComponentProps<{
                token: string;
                organizationId?: string;
                language: LanguageKey;
              }>
            ) => {
              const { organizationId } = props.match.params;

              return <RegistrationRoute services={services} organizationId={organizationId} {...props} />;
            }}
          />
          <Route
            path={referralRoute.routerPath()}
            component={(
              props: RouteComponentProps<{
                token: string;
                step: string;
                organizationId?: string;
                language: LanguageKey;
              }>
            ) => {
              const { token, organizationId, language, step } = props.match.params;

              return (
                <RegistrationRoute
                  services={services}
                  organizationId={organizationId}
                  referralToken={token}
                  {...props}
                  step={!step || !isRegistrationStep(step) ? 'referral' : step}
                  nextPage={(params) =>
                    referralRoute.href({
                      token,
                      language,
                      ...params,
                    })
                  }
                />
              );
            }}
          />
          <Route
            path={depricatedRegisterRoute(undefined, true)}
            render={() => <Redirect to={registerRoute(undefined, true)} />}
          />
          <Route
            path={channelRouterRoute()}
            component={(props: RouteComponentProps) => (
              <AppLayout
                services={services}
                navBarOptions={{ show: true, hideContent: false, fixPosition: true }}
                navBarThemeOptions={channelNavTheme}
              >
                <ChannelPage
                  {...props}
                  channelService={services.channel}
                  name={props.match.params['channelSlug']}
                  channelPage={props.match.params['channelPage']}
                  selectionId={props.match.params['selectionId']}
                  subSelectionId={props.match.params['country']} // country en subSelectionId Id, share a spot
                />
              </AppLayout>
            )}
          />
          <Route
            path={embedRoute(':id', ':pinId?', languageKey)}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: false }} footer={'none'}>
                <EmbedLessonPage
                  {...props}
                  selectionId={props.match.params['id']}
                  preselectedPin={props.match.params['pinId']}
                />
              </AppLayout>
            )}
          />
          <Route
            path={voucherRoute(languageKey)}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                <VoucherPage {...props} />
              </AppLayout>
            )}
          />
          <Route
            path={loginRoute(undefined, true, languageKey)}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                <LoginPage {...props} />
              </AppLayout>
            )}
          />
          <Route
            path={forgotPasswordRoute(languageKey)}
            component={(props: RouteComponentProps) => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                <ForgotPasswordPage {...props} />
              </AppLayout>
            )}
          />
          <Route
            path={channelOverviewRoute(languageKey)}
            component={(props: RouteComponentProps) => (
              <AppLayout
                services={services}
                navBarOptions={{ show: true, hideContent: false }}
                navBarThemeOptions={channelNavTheme}
              >
                <ChannelOverviewPage {...props} />
              </AppLayout>
            )}
          />
          <Route
            path={childOrganizationPickerRoute(languageKey)}
            component={(props: RouteComponentProps) => {
              return (
                <AppLayout services={services} navBarOptions={{ show: true, hideContent: true }}>
                  <ChildPickerPage {...props} />
                </AppLayout>
              );
            }}
          />
          <Route
            path={validatedSuccessRoute(languageKey)}
            component={(props: RouteComponentProps) => {
              return (
                <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                  <ValidateEmailPage success={true} {...props} />
                </AppLayout>
              );
            }}
          />
          <Route
            path={validatedErrorRoute(undefined, languageKey)}
            component={(props: RouteComponentProps) => {
              return (
                <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }}>
                  <ValidateEmailPage success={false} {...props} />
                </AppLayout>
              );
            }}
          />
          <Route path="/search/lesson-thumb/:id" component={() => <LessonThumbPage />} />
          <Route exact path="/" render={() => <Redirect to="search" />} />
          <Route
            render={() => (
              <AppLayout services={services} navBarOptions={{ show: true, hideContent: false }} footer={'default'}>
                <ErrorPageView type="page" />
              </AppLayout>
            )}
          />
        </Switch>
      </AppProviders>
    </Router>
  );
}

const ToastContainerStyled = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
`;

const PopupsOnPageLoad: React.FC<{ history: History }> = ({ history }) => {
  const [requiresCookieConsent, setRequiresCookieConsent] = React.useState<boolean>(tracker.requiresCookieConsent());
  const location = useLocation();

  if (typeof window === undefined || popupExemptRoutes.some((page) => location.pathname.includes(page))) {
    return null;
  }

  if (requiresCookieConsent) {
    return (
      <CookiePopup
        show={true}
        onAccept={() => {
          tracker.setCookieSettings({ analytic: true, marketing: true });
          setRequiresCookieConsent(false);
        }}
      />
    );
  }

  return <LoginWall history={history} />;
};

interface RegistrationRouteProps extends RouteComponentProps, RegistrationPageProps {
  services: AppServices;
}

const RegistrationRoute: React.FC<RegistrationRouteProps> = (props) => {
  return (
    <AppLayout
      services={props.services}
      navBarOptions={{ show: true, hideContent: true, fixPosition: true }}
      navBarThemeOptions={{ theme: 'default' }}
    >
      <RegistrationContextProvider>
        <RegistrationPage
          {...props}
          services={props.services}
          step={props.step || props.match.params[registerRouteStepKey]}
        />
      </RegistrationContextProvider>
    </AppLayout>
  );
};
