import { enClientIntegrations, frClientIntegrations, nlClientIntegrations } from '@lessonup/client-integration';
import { enModern, frModern, nlModern } from '@lessonup/teacher-modern';
import { enUIComponents, frUIComponents, nlUIComponents } from '@lessonup/ui-components';
import i18n from 'i18next';
import { merge } from 'lodash';
import enShared from '../../../shared-core/ui/translations/en.json';
import frShared from '../../../shared-core/ui/translations/fr.json';
import nlShared from '../../../shared-core/ui/translations/nl.json';
import enLocal from './en.json';
import frLocal from './fr.json';
import nlLocal from './nl.json';

const nl = merge(nlLocal, nlShared, nlModern, nlClientIntegrations, nlUIComponents);
const en = merge(enLocal, enShared, enModern, enClientIntegrations, enUIComponents);
const fr = merge(frShared, frLocal, frModern, frClientIntegrations, frUIComponents);

export const translationResources: i18n.Resource = {
  en,
  nl,
  fr,
};
