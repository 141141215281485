import classNames from 'classnames';
import React from 'react';

type Layout = 'center' | 'b-b-0' | 'right';

interface Props {
  className?: string;
  layout?: Layout;
}

const ModalButtonBar: React.FunctionComponent<Props> = ({ layout = 'center', children, className }) => {
  return <div className={classNames('modal__button-bar', layout, className)}>{children}</div>;
};

export default ModalButtonBar;
