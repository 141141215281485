import { Link, TopBarItem } from '@lessonup/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { Language, LanguageSingleton } from '../../../shared-core/domain';
import {
  channelOverviewRoute,
  channelRoute,
  ChannelRouteParams,
  lessonRoute,
  lessonSearchRoute,
} from '../../../shared-core/services/app/searchRoutes';
import { aboutLessonUpRoute } from '../../../shared-core/services/app/siteRoutes';
import {
  channelListRoute,
  editorUrl,
  editPlanRoute,
  teacherMyLessonsUrl,
} from '../../../shared-core/services/app/teacherRoutes';
import {
  activeDocumentForUser,
  geoLocation as geoLocationSelector,
  isUserSignedIn,
  loggedInUser,
  schoolForUser,
} from '../../redux/selectors';
import {
  channelRouterRoute,
  meteorRouteWithLanguageQueryParam,
  routerSearchLessonsRoute,
  routerSearchPlanRoute,
} from '../../utils/routeUtils/routeUtils';

interface Props {}

type NavData = {
  url: string;
  external: boolean; // external to search (not LessonUp)
  translationKey?: string;
};

function navigationLinks(language?: Language.Key, country?: string): Record<NavLink, NavData> {
  return {
    help: { url: aboutLessonUpRoute(), external: true },
    channels: { url: meteorRouteWithLanguageQueryParam(channelListRoute(), language), external: false },
    myLessonUp: { url: teacherMyLessonsUrl(), external: true },
    search: { url: lessonSearchRoute(country ? { country } : {}), external: false },
    activeDocumentTab: {
      url: '',
      external: true,
    },
    mySchool: { url: '', external: false },
  };
}

export type NavLink = 'help' | 'channels' | 'myLessonUp' | 'activeDocumentTab' | 'mySchool' | 'search' | 'mySchool';

let fullUrls: NavLink[] = ['help', 'channels', 'myLessonUp', 'activeDocumentTab', 'mySchool'];

export default function Navigation({}: Props) {
  const { t } = useTranslation('navbar');
  const isSignedIn = useSelector(isUserSignedIn());
  const userSchool = useSelector(schoolForUser());
  const location = useLocation();
  const userActiveLesson = useSelector(activeDocumentForUser());
  const user = useSelector(loggedInUser());
  const geoLocation = useSelector(geoLocationSelector());
  const country = user?.country || geoLocation?.countryCode || undefined;
  const navContent = navigationLinks(LanguageSingleton.get(), country);

  const navLinks: NavLink[] = !!isSignedIn
    ? ['myLessonUp', 'activeDocumentTab', 'search', 'channels']
    : ['help', 'search', 'channels'];

  if (userSchool) {
    navContent.mySchool.url = channelRoute({
      channelSlug: userSchool.slug,
      language: LanguageSingleton.get(),
    });
    navLinks.push('mySchool');
  }

  if (isSignedIn) {
    if (userActiveLesson?.type === 'lesson') {
      navContent.activeDocumentTab.url = editorUrl(userActiveLesson.lessonId, userActiveLesson.pinId);
      navContent.activeDocumentTab.translationKey = 'myLessonTab';
    }
    if (userActiveLesson?.type === 'plan') {
      navContent.activeDocumentTab.url = editPlanRoute(userActiveLesson.planId);
      navContent.activeDocumentTab.translationKey = 'myLessonPlanTab';
    }
  }

  navContent.channels.url = channelOverviewRoute();
  fullUrls = fullUrls.filter((url) => url !== 'channels');

  const language = LanguageSingleton.get();

  function pathWithCorrectLanguage(path: string): string {
    return Language.isMain(LanguageSingleton.get())
      ? path
      : Language.changePathLanguage(path, Language.fallbackForLanguage(language));
  }

  return (
    <>
      {navLinks.map((navLink) => {
        const { url, external, translationKey } = navContent[navLink];
        if (!url) return;

        const path = pathWithCorrectLanguage(url);
        const active = tabIsActive(navLink, location.pathname, userSchool?.slug);
        return (
          <TopBarItem key={navLink} active={active}>
            <Link href={path} passHref external={external}>
              <a>{t(translationKey || navLink)}</a>
            </Link>
          </TopBarItem>
        );
      })}
    </>
  );
}

function tabIsActive(navLink: NavLink, locationPathName: string, schoolSlug?: string) {
  let path: string | string[] | undefined;

  switch (navLink) {
    case 'channels':
      path = [channelRouterRoute(), channelOverviewRoute()];
      break;
    case 'search':
      path = [routerSearchLessonsRoute(), routerSearchPlanRoute(), lessonRoute(':id', ':pinId?')];
      break;
    case 'mySchool':
      path = schoolSlug ? [channelRouterRoute()] : undefined;
      break;
    default:
      path = navigationLinks()[navLink].url;
      break;
  }

  const match = matchPath(locationPathName, {
    path,
    exact: true,
  });
  if (schoolSlug) {
    const currentSlugIsSchoolSlug = (match?.params as ChannelRouteParams)?.channelSlug === schoolSlug;

    if (navLink === 'channels' && currentSlugIsSchoolSlug) {
      // if the school channel is active, disable channels tab
      return false;
    }
    if (navLink === 'mySchool' && !currentSlugIsSchoolSlug) {
      // if we only match the channel route if the slug is the same
      return false;
    }
  }

  return !!match;
}
