import React from 'react';
import { LessonPlan } from '../../../shared-core/domain';
import './css/LessonPlanText.less';

type Props = {
  child: LessonPlan.Children.Text;
};

const LessonPlanText: React.FC<Props> = ({ child }) => {
  const alignText = child.align ? `text-align-${child.align}` : 'text-align-left';

  return (
    <div
      className={`paragraph ${alignText} html auto-height no-border lesson-plan-text`}
      dangerouslySetInnerHTML={{ __html: child.text }}
    ></div>
  );
};

export default LessonPlanText;
