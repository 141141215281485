import { trackAction } from '@lessonup/client-integration';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ChannelTitle, ExtendedChannelInfo, LanguageSingleton } from '../../../shared-core/domain';
import { channelRoute } from '../../../shared-core/services/app/searchRoutes';
import { channelInfoRoute } from '../../../shared-core/services/app/siteRoutes';
import useLanguageChange from '../../../shared-core/ui/utils/hooks/useLanguageChange';
import { ImageUtils } from '../../../shared-core/utils/ImageUtils';
import { useAppServices } from '../../components/appServices/AppServicesContext';
import { fetchedChannelOverviewChannelsAction, reloadChannelOverviewAction } from '../../redux/actions/actions';
import { channelsForOverview } from '../../redux/selectors';
import { htmlDecode } from '../../utils/htmlDecode';
import pageTitle from '../../utils/pageTitle';
import { ChannelFilter } from './ChannelFilter/ChannelFilter';
import './ChannelOverviewPage.less';

const TRANSLATION_NS = 'channelOverview';
const FILTER_THRESHOLD = 15;
interface ChannelOverviewProps extends RouteComponentProps {}

const ChannelOverviewPage: React.FC<ChannelOverviewProps> = ({ history }) => {
  const { t } = useTranslation(TRANSLATION_NS);
  const services = useAppServices();
  const channelState = useSelector(channelsForOverview());
  const dispatch = useDispatch();
  useLanguageChange(() => dispatch(reloadChannelOverviewAction()));
  const [filterQuery, setFilterQuery] = useState('');

  if (!channelState.hasLoaded) {
    services.channel.fetchChannelOverviewChannels().then((res) => {
      dispatch(fetchedChannelOverviewChannelsAction(res.channels));
    });
  }

  const filteredChannels = useMemo(
    () => filterChannels(channelState.channels, filterQuery),
    [channelState.channels, filterQuery]
  );
  const [first, second, ...otherChannels] = filteredChannels;

  const onFilterQueryChange = (query: string) => {
    debouncedTrackAction(query);
    setFilterQuery(query);
  };

  return (
    <>
      <section data-page="channels">
        <MetaTags />
        <div id="section-visual"></div>
        <div id="section-layer-over-visual"></div>

        <div id="page" className="layout-channels">
          <div id="page-info">
            <h1>{t('pageTitle')}</h1>
            <p>{t('pageDescription')}</p>
            {channelState.channels.length >= FILTER_THRESHOLD && (
              <ChannelFilter onSubmit={onFilterQueryChange} onChange={onFilterQueryChange}></ChannelFilter>
            )}
          </div>
          <div id="section-content">
            {otherChannels.length > 1 && !filterQuery ? (
              <LargeChannelList featuredChannels={[first, second]} otherChannels={otherChannels} />
            ) : filteredChannels.length >= 1 ? (
              <>
                <h2>{t('title')}</h2>
                <hr></hr>
                <ChannelList channels={filteredChannels} />
              </>
            ) : (
              <p> {t('noChannelsFound') + ' '}</p>
            )}
          </div>
        </div>
      </section>
      {otherChannels.length < 2 && (
        <p className="channel-interest-message">
          {t('channelInterestMessage') + ' '}
          <a href={channelInfoRoute()}>{t('learnMore')}</a>
        </p>
      )}
    </>
  );
};

const LargeChannelList: React.FC<{ featuredChannels: ExtendedChannelInfo[]; otherChannels: ExtendedChannelInfo[] }> = ({
  featuredChannels,
  otherChannels,
}) => {
  const { t } = useTranslation(TRANSLATION_NS);
  return (
    <>
      <h2>{t('featured')}</h2>
      <FeaturedChannels channels={featuredChannels} />
      <br />
      <br />
      <h2>{t('title')}</h2>
      <hr></hr>
      <ChannelList channels={otherChannels} />
      <div className="flex-item dummy rel"></div>
      <div className="flex-item dummy rel"></div>
      <div className="flex-item dummy rel"></div>
      <div className="clearfix"></div>
    </>
  );
};

const FeaturedChannels: React.FC<{ channels: ExtendedChannelInfo[] }> = (props) => {
  return <ChannelSection channels={props.channels} name="featured" />;
};

const ChannelList: React.FC<{ channels: ExtendedChannelInfo[] }> = (props) => {
  return <ChannelSection channels={props.channels} name="channels" />;
};

const ChannelSection: React.FC<{ channels: ExtendedChannelInfo[]; name: string }> = (props) => {
  return (
    <div className={classNames('channel-section channels-wrapper', props.name)}>
      <div className="channel-wrapper">
        {props.channels.map((channel) => {
          const props = { ...channel, key: channel._id };
          return <ChannelCard {...props} />;
        })}
      </div>
    </div>
  );
};

const ChannelCard: React.FC<ExtendedChannelInfo> = (props) => {
  if (!props.name) return null;

  function getTitleToRender(title: ChannelTitle): string | undefined {
    return title && htmlDecode(ChannelTitle.localizeTitle(title, LanguageSingleton.get()));
  }

  return (
    <Link
      to={channelRoute({ channelSlug: props.name })}
      className="channel-card flex-item"
      style={{
        backgroundImage: ImageUtils.urlString(ImageUtils.checkImageUrl(props.cover, false, 500)),
        backgroundPosition: `center ${props.coverFocus ? props.coverFocus + '%' : 'center'}`,
      }}
    >
      <div className="glass"></div>
      <div
        className="channel-icon"
        style={{ backgroundImage: ImageUtils.urlString(ImageUtils.checkImageUrl(props.icon)) }}
      ></div>
      <div className="channel-info-text">
        <div className="name">{getTitleToRender(props.title)}</div>
        <div className="subtitle">{getTitleToRender(props.subtitle)}</div>
      </div>
    </Link>
  );
};

function MetaTags() {
  const { t } = useTranslation(TRANSLATION_NS);

  return (
    <>
      <Helmet>
        <title>{pageTitle(t('metaTitle'))}</title>
        <meta name="description" content={t('metaDescription')} />
      </Helmet>
    </>
  );
}

export function filterChannels(channels: ExtendedChannelInfo[], query: string): ExtendedChannelInfo[] {
  const lowerCaseQuery = query.toLocaleLowerCase();

  const isMatch = (toCheck: string): boolean => {
    return toCheck.toLocaleLowerCase().includes(lowerCaseQuery);
  };

  return channels.filter(
    (channel) =>
      !lowerCaseQuery ||
      isMatch(channel.name) ||
      Object.values(channel.title).some(isMatch) ||
      Object.values(channel.subtitle).some(isMatch)
  );
}

const debouncedTrackAction = debounce((query) => trackAction('channel-filter', { query }), 1000);

export default ChannelOverviewPage;
