import { AppError } from '@lessonup/utils';
export type Translator = (key: string, params?: any) => string;

export namespace Translations {
  let _translator: Translator;

  export function initialize(translator: Translator) {
    _translator = translator;
  }

  export function __(key: string, params?: any): string {
    if (!_translator) {
      throw new AppError('unexpected-data', 'translations.ts not initialized');
    }
    return _translator(key, params);
  }
}
