import _, { isArray } from 'lodash';
import { IndexedContent } from './IndexedContent';
import { IndexedLessonBase } from './IndexedLesson';
import { Sanitizer } from './Sanitizer';

export namespace IndexedLessonContent {
  export let sanitizer: any;

  export function fromIndexed(lessonOrLessons: IndexedLessonBase[] | IndexedLessonBase): IndexedContent {
    const indexedLessons = isArray(lessonOrLessons) ? lessonOrLessons : [lessonOrLessons];

    const importantItems: string[] = [];
    const normalItems: string[] = [];

    function add(list: string[], item: string) {
      list.push(Sanitizer.sanitize(item));
    }
    // Loop through the pins and add all valid values to the arrays
    indexedLessons.forEach((il) => {
      const { lesson, firstPin, indexedContent } = il;
      if (lesson.name) add(importantItems, lesson.name);
      if (lesson.description) add(importantItems, lesson.description);
      if (lesson.information) add(importantItems, lesson.information);
      importantItems.push(...indexedContent.important);
      normalItems.push(...indexedContent.normal);
    });

    return {
      important: _.compact(importantItems),
      normal: _.compact(normalItems),
    };
  }
}
