import classNames from 'classnames';
import React, { useRef } from 'react';
import useOnClickOutside from '../../utils/useOnClickOutside';
import useToggle from '../../utils/useToggle';
import ChevronSvg from '../svgIcons/ChevronSvg';
import './ContextMenu.less';

export interface ContextMenuProps {
  onClose?: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ onClose, children }) => {
  const ref = useRef(null);

  const [isOpen, toggleIsOpen] = useToggle(false, (opened) => {
    if (!opened && onClose) {
      onClose();
    }
  });

  useOnClickOutside(ref, () => {
    if (isOpen) {
      toggleIsOpen(false);
    }
  });

  return (
    <div className={classNames('context-menu', { open: isOpen })} ref={ref}>
      <div className="open-chevron" onClick={() => toggleIsOpen()}>
        <ChevronSvg />
      </div>
      {isOpen && (
        <ContextMenuInner isOpen={isOpen} toggleIsOpen={toggleIsOpen}>
          {children}
        </ContextMenuInner>
      )}
    </div>
  );
};

interface InnerProps {
  toggleIsOpen: (val?: boolean | undefined) => void;
  isOpen: boolean;
}

const ContextMenuInner: React.FC<InnerProps> = ({ isOpen, toggleIsOpen, children }) => {
  return <div className="context-menu-inner">{children}</div>;
};

export default ContextMenu;
