import classNames from 'classnames';
import React from 'react';

import { Poll } from '@lessonup/teaching-core';
import { ImageUtils } from '../../../../../utils/ImageUtils';
import './RealtimePollMultipleChoice.less';

type HandleSubmit = (answer: number) => Promise<void>;
interface Props {
  pinContent: Poll.MultipleChoice;
  pollAnswer: number | undefined;
  handleSubmitAnswer?: HandleSubmit;
  fontSize?: string;
  teacherViewClass?: boolean;
}

const RealtimePollMultipleChoice: React.FC<Props> = (props) => {
  const {
    pinContent: { options, optionFontSize },
    handleSubmitAnswer,
    pollAnswer,
    fontSize,
  } = props;
  const optionCount = options.length;

  const teacherViewClass = props.teacherViewClass ? 'teacher-view' : undefined;

  const wrapperClassname =
    optionCount > 8 || (optionCount === 5 && props.teacherViewClass)
      ? 'mc-wrapper-10'
      : optionCount > 6 || (optionCount === 4 && props.teacherViewClass)
      ? 'mc-wrapper-8'
      : optionCount > 4 || (optionCount === 3 && props.teacherViewClass)
      ? 'mc-wrapper-6'
      : optionCount === 1
      ? 'mc-wrapper-1'
      : 'mc-wrapper-4';

  return (
    <div className={classNames('multiplechoice-wrapper', wrapperClassname, teacherViewClass)}>
      {options.map((option) => {
        return option.type === 'text' ? (
          <PollTextInput
            fontSize={fontSize || `font-size-${optionFontSize || 40}`}
            option={option as Poll.Answer.AnswerTypeText}
            handleSubmitAnswer={handleSubmitAnswer}
            pollAnswer={pollAnswer}
            key={option.value}
          />
        ) : (
          <PollImageInput
            option={option as Poll.Answer.AnswerTypeImage}
            handleSubmitAnswer={handleSubmitAnswer}
            pollAnswer={pollAnswer}
            key={option.value}
          />
        );
      })}
    </div>
  );
};

export default RealtimePollMultipleChoice;

type OptionProps<T extends Poll.Answer.AnswerTypeImage | Poll.Answer.AnswerTypeText> = {
  option: T;
  pollAnswer: number | undefined;
  handleSubmitAnswer?: HandleSubmit;
  fontSize?: string;
};

export const PollTextInput: React.FC<OptionProps<Poll.Answer.AnswerTypeText>> = ({
  pollAnswer,
  option,
  handleSubmitAnswer,
  fontSize,
}) => {
  const handleClick = () => handleSubmitAnswer && handleSubmitAnswer(option.value);

  return (
    <div
      onClick={handleClick}
      className={classNames('option text-option', fontSize, {
        'not-transparent': typeof pollAnswer === 'undefined',
        active: option.value === pollAnswer,
        clickable: !!handleSubmitAnswer,
      })}
    >
      <div dangerouslySetInnerHTML={{ __html: option.text }}></div>
    </div>
  );
};

export const PollImageInput: React.FC<OptionProps<Poll.Answer.AnswerTypeImage>> = ({
  option,
  handleSubmitAnswer,
  pollAnswer,
}) => {
  const handleClick = () => handleSubmitAnswer && handleSubmitAnswer(option.value);

  const checkedUrl = option.url && ImageUtils.checkImageUrl(option.url);

  return (
    <div
      onClick={handleClick}
      style={checkedUrl ? { backgroundImage: `url(${checkedUrl})` } : {}}
      className={classNames('option image-option', `mc-image-${option.backgroundSize}`, {
        active: option.value === pollAnswer,
        'not-transparent': typeof pollAnswer === 'undefined',
        'empty icon-dark icon-camera': !option.url,
        clickable: !!handleSubmitAnswer,
      })}
    ></div>
  );
};

interface EmptyPollProps {
  onClick: () => void;
}
export const CreateNewInput: React.FC<EmptyPollProps> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      onKeyDown={(e) => e.keyCode === 13 && onClick()}
      className={classNames('option create-option font-size-40 icon-plus')}
      tabIndex={0}
    ></div>
  );
};
