/**
 *
 * @description Can be used to render HTML from a string, without evaluating said HTML.
 * For more information, see https://stackoverflow.com/a/34064434
 */
export function htmlDecode(input: string | undefined): string | undefined {
  if (!input || typeof DOMParser === 'undefined') return input;
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent ?? undefined;
}
