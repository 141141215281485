import _ from 'lodash';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  FacetKey,
  Facets,
  isValueFacet,
  SearchParams,
  SelectedFacet,
  ValueFacetKey,
} from '../../../../shared-core/domain';
import { PAGINATION_SIZE } from '../../../../shared-core/domain/search/constants/paginationSize';
import { IndexType } from '../../../../shared-core/domain/search/IndexType';
import useCallbackIfParamsChanged from '../../../../shared-core/ui/utils/hooks/useCallbackIfParamsChanged';
import useDeepCompareMemoize from '../../../../shared-core/ui/utils/hooks/useDeepCompareMemoize';
import { useContextGenerator } from '../../../../shared-core/ui/utils/react';
import { isUserSignedIn } from '../../../redux/selectors';
import { AppSearchService } from '../../../services/AppServices';

export interface SearchContextProps {
  currentParams: () => SearchParams;
  selectedFacets: SelectedFacet[];
  selectedText: string | undefined;
  setSearchText: (value: string | undefined) => void;
  setFacet: (key: FacetKey, value: string) => void;
  resetFacet: (key: FacetKey) => void;
  paginate: () => void;
}

type Context = SearchContextProps | undefined;

export const SearchContext = React.createContext<Context>(undefined);

export const useSearchContext = useContextGenerator(SearchContext, 'SearchContext');

export type FacetAction =
  | {
      type: 'setValueFacet';
      key: ValueFacetKey;
      value: string;
    }
  | {
      type: 'resetFacet';
      key: FacetKey;
    }
  | {
      type: 'setAllFacets';
      facets: SelectedFacet[];
      text?: string;
      size?: number;
    }
  | {
      type: 'setSearchText';
      value: string | undefined;
    }
  | {
      type: 'paginate';
    };

function resetPaginationParams(state: SearchParams): SearchParams {
  return { ...state, size: undefined };
}

function selectedFacetsReducer(state: SearchParams, action: FacetAction): SearchParams {
  switch (action.type) {
    case 'setValueFacet':
      const facets = state.facets
        .filter((selectedFacet) => selectedFacet.key !== action.key)
        .concat({ key: action.key, value: action.value });
      return { ...resetPaginationParams(state), facets };
    case 'resetFacet':
      const dependentKeys = Facets.dependentKeys(action.key);
      const keysToReset: FacetKey[] = [action.key].concat(dependentKeys ? dependentKeys : []);
      return {
        ...resetPaginationParams(state),
        facets: state.facets.filter((selectedFacet) => !keysToReset.some((key) => selectedFacet.key === key)),
      };
    case 'setSearchText':
      return { ...resetPaginationParams(state), text: action.value };
    case 'setAllFacets':
      return { ...state, facets: action.facets, text: action.text, size: action.size };
    case 'paginate':
      return { ...state, size: state.size ? state.size + PAGINATION_SIZE : PAGINATION_SIZE * 2 };
    default:
      return state;
  }
}

interface SearchContextProviderProps {
  service: AppSearchService;
  storeSearchParams: SearchParams | undefined;
  fetchCallBack: (SearchParams: SearchParams, isPagination?: boolean) => Promise<void>;
  indexType: IndexType;
  routeParams: SearchParams;
  urlCreator: (params: SearchParams) => string;
  facetSubType?: Facets.FacetSubTypes[];
}

// always start with the url params
export function areSearchParamsEqual(
  urlParams: SearchParams | undefined,
  params2: SearchParams | undefined,
  ignoreSize = false
) {
  if (!urlParams && !params2) return true;
  if (!urlParams || !params2) return false;
  if (urlParams.index !== params2.index) return false;
  // all falsy string values are equal to us
  // eslint-disable-next-line eqeqeq
  if (urlParams.text != params2.text) return false;
  // we only care about the size if its in the url
  if (!ignoreSize && urlParams.size && urlParams.size !== params2.size) return false;
  return _.isEqual(_.sortBy(urlParams.facets, ['key']), _.sortBy(params2.facets, ['key']));
}

export const SearchContextProvider: React.FunctionComponent<SearchContextProviderProps> = (props) => {
  const { service, fetchCallBack, urlCreator, routeParams } = props;
  const history = useHistory();
  const indexType = useDeepCompareMemoize(props.indexType);
  const routeSearchParams = useDeepCompareMemoize(routeParams);
  const isUserLoggedIn = useSelector(isUserSignedIn());

  const [filters, dispatch] = useReducer(selectedFacetsReducer, {
    index: indexType,
    text: routeSearchParams.text || '',
    facets: routeSearchParams.facets || [],
    size: routeSearchParams.size,
  });

  const updateSearchQuery = useCallback((action: FacetAction) => {
    dispatch(action);
  }, []);

  const setFacet = (key: FacetKey, value: string) => {
    if (isValueFacet(key)) {
      updateSearchQuery({ type: 'setValueFacet', key, value });
    }
  };

  const resetFacet = (key: FacetKey) => {
    updateSearchQuery({ type: 'resetFacet', key });
  };

  const setSearchText = (value: string | undefined) => {
    updateSearchQuery({ type: 'setSearchText', value });
  };

  const paginate = useCallback(() => {
    dispatch({ type: 'paginate' });
  }, []);

  // watch filters and sync to url
  useEffect(() => {
    const syncUrl = () => {
      const filterBasedUrl = urlCreator(filters);
      const currentParamUrl = urlCreator(routeSearchParams);
      if (filterBasedUrl !== currentParamUrl) {
        if (SearchParams.replaceHistoryState(filters, routeSearchParams)) {
          history.replace(filterBasedUrl);
        } else {
          history.push(filterBasedUrl);
        }
        service.storeSearchQueryString(filterBasedUrl);
      }
    };
    syncUrl();
  }, [filters, history, service, routeSearchParams, urlCreator]);

  // watch the route params and fetch if mismatch
  useCallbackIfParamsChanged({
    initial: props.storeSearchParams,
    params: routeSearchParams,
    isEqual: (a, b) => areSearchParamsEqual(a, b, false),
    callback(params, previous) {
      if (!params) return;
      const isPagination = areSearchParamsEqual(params, previous, true);
      fetchCallBack(params, isPagination);
    },
    changeOnUserChange: true,
    debug: false,
    isUserLoggedIn,
  });

  return (
    <SearchContext.Provider
      value={{
        currentParams: () => _.cloneDeep(filters),
        selectedFacets: filters.facets,
        selectedText: filters.text,
        resetFacet,
        setFacet,
        setSearchText,
        paginate,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
