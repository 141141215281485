import { AssignmentPin, Colors } from '@lessonup/teaching-core';
import React from 'react';
import { ContentTypeDefaults } from '../../../utils/ContentTypeDefaults';
import { PinViewUtils } from '../../../utils/PinViewUtils';
import './pinContainer.less';

interface PinContainerProps {
  pin: AssignmentPin<any>;
  pinColorOverride?: number;
}

export default class PinContainer extends React.Component<PinContainerProps> {
  private allowImageZoom = (): 0 | 1 => {
    return PinViewUtils.allowImageZoom(this.props.pin) ? 1 : 0;
  };

  private classes = (background = false) => {
    const { pinColorOverride } = this.props;
    const { item } = this.props.pin;
    const type = item.type;

    const colorClass =
      this.useDefaultBackgroundColor() || (pinColorOverride && pinColorOverride !== item.custom.color)
        ? `${type}-color-${pinColorOverride || item.custom.color || ContentTypeDefaults.defaultColors[type]}`
        : '';

    const layoutClass = `${type}-layout-${item.custom.layout || 1}`;
    const hasImageClass = item.image || item.image2 ? 'has-image' : '';
    const backgroundClass = background ? 'content-background' : '';

    return [colorClass, layoutClass, hasImageClass, backgroundClass].join(' ');
  };

  private useDefaultBackgroundColor = () => {
    const { color } = this.props.pin.item.custom;
    return (color && color < 10) || !this.props.pin.item.custom.colorbg;
  };

  private style = () => {
    const { pin } = this.props;
    if (this.useDefaultBackgroundColor()) return {};

    return {
      backgroundColor: pin.item.custom.colorbg,
      color: pin.item.custom.colorfg || Colors.getContrastColor(pin.item.custom.colorbg),
    };
  };

  public render() {
    return (
      <div
        id={this.props.pin.item.type}
        data-allow-image-zoom={this.allowImageZoom()}
        style={this.style()}
        className={'pin-container view-mode ' + this.classes()}
      >
        <div className={this.classes(true)}></div>
        {this.props.children}
      </div>
    );
  }
}
