import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBEM } from '../../../utils/hooks';
import { ModalProps } from '../../../utils/ModalManagerService';
import Button from '../../Button';
import LinkButton from '../../links/LinkButton';
import { Modal } from '../Modal';
import ModalButtonBar from '../ModalButtonBar';
import './ConfirmModal.less';

interface ModalTextOverrides {
  confirm?: string;
  reject?: string;
}

interface BaseProps {
  title?: string;
  translationKey?: string;
  textOverrides?: ModalTextOverrides;
}

interface WithText extends BaseProps {
  text: string;
}

interface WithChildren extends BaseProps {
  children: JSX.Element;
}

export type ConfirmModalParams = WithText | WithChildren;

export type ConfirmModalResponse = boolean | undefined;

type Props = ModalProps<ConfirmModalResponse> & ConfirmModalParams;
export const ConfirmModal: React.FC<Props> = (props) => {
  const { closeModal, children, title, translationKey, textOverrides } = props;
  const { t } = useTranslation(translationKey ? [translationKey, 'confirmModal'] : 'confirmModal');
  const bemClasses = useBEM('ConfirmModal');

  function handleYes() {
    closeModal(true);
  }

  function handleNo() {
    closeModal(false);
  }

  return (
    <Modal
      isOpen
      width="medium"
      title={title || t('confirmModalTitle', { ns: 'confirmModal' })}
      className={bemClasses()}
      onRequestClose={handleNo}
    >
      <div className={bemClasses({ element: 'content-container' })}>
        {props['text'] ? <span className={bemClasses({ element: 'description' })}>{props['text']}</span> : children}
      </div>
      <div>
        <ModalButtonBar className={bemClasses({ element: 'actions', className: 'margin-top-4' })} layout="right">
          <LinkButton
            text={textOverrides?.reject ?? t('cancel', { ns: 'confirmModal' })}
            bgColor="transparent"
            height="medium"
            onClick={handleNo}
          />
          <Button height="medium" version="modern" onClick={handleYes} theme="secondary">
            {textOverrides?.confirm ?? t('confirm', { ns: 'confirmModal' })}
          </Button>
        </ModalButtonBar>
      </div>
    </Modal>
  );
};
