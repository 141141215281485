import { tracker } from '@lessonup/analytics';
import {
  BodyText,
  Button,
  ButtonAnchor,
  Divider,
  Headline,
  Link,
  SpaceBetween,
  spacing,
} from '@lessonup/ui-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { registerRoute } from '../../../../shared-core/services/app/searchRoutes';
import { NewModal } from '../../../../shared-core/ui/components/modals/NewModal';
import { ModalProps } from '../../../../shared-core/ui/utils/ModalManagerService';
import { assertNever } from '../../../../shared-core/utils';
import { incentiveCounter } from '../../../services/IncentiveCounter/IncentiveCounterService';
import { useAppServices } from '../../appServices/AppServicesContext';
import './RegisterOrLoginModal.less';

export interface RegisterOrLoginModalConfig {
  redirectUrl?: string;
  titleOverride?: string;
  descriptionOverride?: string;
}
export type LoginModalProps = ModalProps<void> & RegisterOrLoginModalConfig;

export const RegisterOrLoginModal: React.FC<LoginModalProps> = (props: LoginModalProps) => {
  const { t } = useTranslation('registerOrLoginModal');
  const { user: userService, modals: modalService } = useAppServices();

  async function onSelectLogin() {
    await modalService.open('login', { userService, initialPage: 'login' });
    props.closeModal();
  }

  useEffect(() => {
    const incentiveCounterVariant = incentiveCounter.incentiveCounterExperimentVariant();

    switch (incentiveCounterVariant) {
      case 'default':
        return;
      case 'medium':
        return tracker.events.teacherRegistrationMediumPrompt();
      case 'hard':
        return tracker.events.teacherRegistrationHardPrompt();
      default:
        assertNever(incentiveCounterVariant, 'Missing incentiveCounterVariant');
    }
  }, []);

  function onSelectRegister() {
    props.closeModal();
  }

  return (
    <NewModal
      title={
        <div className="register-or-login-title">
          <Headline as="h1" size="small">
            {props.titleOverride || t('title')}
          </Headline>
        </div>
      }
      isOpen={true}
      onRequestClose={() => props.closeModal()}
      animationOnOpen="slide-from-bottom"
      className="register-login-modal"
      width="small"
      ariaLabel={t('ariaLabel')}
    >
      <SpaceBetween direction="y" spacing={spacing.size16} alignItems="flex-start">
        <BodyText>{props.descriptionOverride || t('description')}</BodyText>
        <Link href={registerRoute('credentials')} external={false} onClick={() => onSelectRegister()}>
          <ButtonAnchor size="large" href={registerRoute()}>
            {t('register')}
          </ButtonAnchor>
        </Link>
        <Divider orientation="horizontal" text={t('or')} />
        <Button size="large" buttonType="neutral-1" noFill showStroke onClick={() => onSelectLogin()}>
          {t('login')}
        </Button>
      </SpaceBetween>
    </NewModal>
  );
};

export default RegisterOrLoginModal;
