import { ActionTrackerTransport, TrackActionConfig } from '@lessonup/client-integration';
import { TrackerAction, TrackerParams, TrackingUserIds, UserDimensions } from '@lessonup/utils';

export type SendActionHandler<K extends TrackerAction = TrackerAction> = (
  action: K,
  params?: TrackerParams<K>,
  userIds?: Partial<TrackingUserIds>
) => void;

/**
 * This class wraps the gtag global. This one is quirky because at the time of writing everyting needs to be in one config.
 * Note that we are only using GA-4 from now on.
 */
export class HttpActionTransport implements ActionTrackerTransport {
  public constructor(private readonly sendHandler: SendActionHandler) {}

  public setUserId(userId: string | undefined): void {
    // noop
  }

  public setCustomDimensions(params: UserDimensions): void {
    // noop
  }

  /** mostly copied from the old trackActionClient */
  public event<K extends TrackerAction>(
    action: K,
    params: TrackerParams<K> & TrackActionConfig,
    userIds: Partial<TrackingUserIds>
  ): void {
    this.sendHandler(action, params, userIds);
  }

  public registerPageViewForExternalTracker(path: string, config: TrackActionConfig): void {
    // we don't want to track pageviews
  }
}
