import { capitalize } from 'lodash';
import qs from 'qs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { teacherHome } from '../../../shared-core/services/app/teacherRoutes';
import ActionButton from '../../../shared-core/ui/components/Button';
import { useAppServices } from '../../components/appServices/AppServicesContext';
import ValidateEmailForm from '../../components/validateEmailForm';
import GrayPageLayout, {
  GrayPageLayoutTitle,
  GrayPageSection,
  GrayPageSubtitle,
} from '../GrayPageLayout/GrayPageLayout';
import './ValidateEmailPage.less';

const TRANSLATION_NS_SUCCESS = 'validationSuccess';
const TRANSLATION_NS_ERROR = 'validationError';
const TRANSLATION_NS_RETRY = 'validationRetry';

const emailValidatedSuccessfully = (t) => (
  <>
    <GrayPageLayoutTitle>{capitalize(t(`${TRANSLATION_NS_SUCCESS}:title`))}</GrayPageLayoutTitle>
    <GrayPageSubtitle>{t(`${TRANSLATION_NS_SUCCESS}:subtitle`)}</GrayPageSubtitle>
    <a href={teacherHome()}>
      <ActionButton>{t(`${TRANSLATION_NS_SUCCESS}:button`)}</ActionButton>
    </a>
  </>
);

const couldntValidateEmail = (t, email, onSubmit) => (
  <>
    <GrayPageLayoutTitle>{capitalize(t(`${TRANSLATION_NS_ERROR}:title`))}</GrayPageLayoutTitle>
    <GrayPageSubtitle>{t(`${TRANSLATION_NS_ERROR}:subtitle`)}</GrayPageSubtitle>
    <ValidateEmailForm onSubmit={onSubmit} email={email} />
  </>
);

const validationEmailSend = (t) => (
  <>
    <GrayPageLayoutTitle>{capitalize(t(`${TRANSLATION_NS_RETRY}:title`))}</GrayPageLayoutTitle>
    <GrayPageSubtitle>{t(`${TRANSLATION_NS_RETRY}:subtitle`)}</GrayPageSubtitle>
  </>
);

interface Props {
  success: boolean;
}

const ValidateEmailPage: React.FC<Props & RouteComponentProps> = (props) => {
  const { success, location } = props;

  const { t } = useTranslation([TRANSLATION_NS_SUCCESS, TRANSLATION_NS_ERROR, TRANSLATION_NS_RETRY]);

  const { user } = useAppServices();
  const email = qs.parse(location.search, { ignoreQueryPrefix: true }).email;
  const [completed, setCompleted] = useState<boolean>(false);

  const onSubmit = async (email) => {
    if (!email) return;
    await user.requestEmailValidation(email).then((success) => {
      setCompleted(success);
    });
  };

  return (
    <GrayPageLayout className="validate-email">
      <GrayPageSection position="top" className="spread-content">
        <div className="text-align-center">
          {success
            ? emailValidatedSuccessfully(t)
            : completed
            ? validationEmailSend(t)
            : couldntValidateEmail(t, email, onSubmit)}
        </div>
      </GrayPageSection>
    </GrayPageLayout>
  );
};

export default ValidateEmailPage;
