import { tracker } from '@lessonup/analytics';
import { logger, trackAction } from '@lessonup/client-integration';
import { AddWhitelistedEmailModal } from '@lessonup/teacher-modern';
import { styled, useModal } from '@lessonup/ui-components';
import { AppError } from '@lessonup/utils';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MongoUser, showSchoolPickerForCountry } from '../../../../../shared-core/domain';
import { Country } from '../../../../../shared-core/domain/country/Country';
import { UnitedStates } from '../../../../../shared-core/domain/country/UnitedStates';
import { IndexedSchoolSuggestion } from '../../../../../shared-core/domain/schoolSuggestions/IndexedSchoolSuggestion';
import Button from '../../../../../shared-core/ui/components/Button';
import TextButton from '../../../../../shared-core/ui/components/buttons/TextButton';
import Heading from '../../../../../shared-core/ui/components/Heading';
import { useFormInput } from '../../../../../shared-core/ui/components/inputElements/useFormInput';
import { Switch } from '../../../../../shared-core/ui/components/Switch/Switch';
import { useBEM } from '../../../../../shared-core/ui/utils/hooks';
import { useAppServices } from '../../../../components/appServices/AppServicesContext';
import { geoLocation, loggedInUser } from '../../../../redux/selectors';
import { ClientOnlyModernWrapper } from '../../../../utils/bridge/ClientOnlyModernWrapper';
import useFeatureFlags from '../../../../utils/featureFlags/useFeatureFlags';
import { RegistrationFormProps } from '../../types';
import { useRegistrationContext } from '../../utils/RegistrationContext';
import { CountryStateForm } from './components/CountryStateForm/CountryStateForm';
import { SchoolSuggestor } from './components/SchoolSuggestor/SchoolSuggestor';
import './SchoolPickerPage.less';
import {
  FormState,
  FormStateSelectedSchool,
  schoolPickerPageReducer,
  SetCountryAction,
  SetUSStateAction,
} from './state/SchoolPickerPageReducer';

type Props = RegistrationFormProps;
const TRANSLATION_NAMESPACE = 'registerSchoolPicker';

export const SchoolPickerPage: React.FC<Props> = (props) => {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);
  const bemClasses = useBEM('SchoolPickerPage');
  const services = useAppServices();
  const user = useSelector(loggedInUser());
  const detectedGeoLocation = useSelector(geoLocation());
  const [schoolPickerError, setSchoolPickerError] = useState(false);
  const { setPickChildSource } = useRegistrationContext();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const whitelistEmailModal = useModal(AddWhitelistedEmailModal);

  useEffect(() => {
    trackAction('register-organization-start');
  }, []);

  function setSchoolName(name: string | undefined): void {
    dispatch({ type: 'setManualEntrySchoolName', name: name || '' });
  }

  function setSchoolCity(city: string | undefined): void {
    dispatch({ type: 'setManualEntrySchoolCity', city: city || '' });
  }

  const initialCountryCode = user?.country || Country.validCountryCode(detectedGeoLocation?.countryCode);
  const [formState, dispatch] = useReducer(schoolPickerPageReducer, {
    countryCode: initialCountryCode,
    state: user?.USState,
    worksAtSchool: !user?.profile?.schoolTypes?.includes('none'),
    message: undefined,
    reasonMessage: undefined,
    selectedSchool: undefined,
  });

  const countryIsComplete = formState.countryCode === 'us' ? !!formState.state : !!formState.countryCode;
  const countryIsSchoolPickerCountry = showSchoolPickerForCountry(formState.countryCode, isFeatureFlagEnabled);

  const showNameField = countryIsComplete && !countryIsSchoolPickerCountry && formState.worksAtSchool;
  const showCityField = (showNameField || formState.selectedSchool?.type === 'manualEntry') && formState.worksAtSchool;

  const [, manualEntryNameInput, validateManualEntryNameInput] = useFormInput({
    type: 'text',
    autoComplete: 'organization',
    label: t('nameLabel'),
    onChange: setSchoolName,
    autoFocus: true,
    validator: (value) => !!value?.trim(),
    requiredMsg: t('nameRequired'),
  });

  const [, manualEntryCityInput, validateManualEntryCityInput] = useFormInput({
    type: 'text',
    autoComplete: 'city',
    label: t('cityLabel'),
    onChange: setSchoolCity,
    autoFocus: !showNameField,
    validator: (value) => !!value?.trim(),
    requiredMsg: t('cityRequired'),
  });

  const [, reasonInput, validateReasonInput] = useFormInput({
    type: 'text',
    placeholder: t('reasonPlaceholder'),
    label: t('reasonInput'),
    className: bemClasses({ element: 'input' }),
    onChange: (value: string) => dispatch({ type: 'setReason', reason: value }),
    validator: (value) => !!value?.trim(),
    requiredMsg: t('reasonRequired'),
    initial: formState.reasonMessage,
  });

  if (!user) return null;

  function onSelectSchoolSuggestion(selectedSuggestion: IndexedSchoolSuggestion): void {
    const suggestionId = selectedSuggestion?._id;
    setSchoolPickerError(false);

    if (!suggestionId) {
      dispatch({ type: 'setSchool', selectedSchool: undefined });
      dispatch({ type: 'setConfirmationMessage', message: undefined });
      return;
    }

    dispatch({
      type: 'setConfirmationMessage',
      message: translationKeyForSchoolSuggestion(selectedSuggestion),
    });

    dispatch({ type: 'setSchool', selectedSchool: { type: 'selectedSuggestion', selectedSuggestion } });
  }

  function translationKeyForSchoolSuggestion(suggestion: IndexedSchoolSuggestion): string | undefined {
    if (!suggestion.organizationId) {
      return 'confirmStart';
    }

    return suggestion.isSuper ? undefined : 'confirmSchoolLicense';
  }

  function validateForm(formState: FormState): formState is FormState & { countryCode: Country.CountryCode } {
    if (!validateCountryIsCompleted()) return false;
    if (!formState.worksAtSchool && validateReasonInput()) return true;
    if (showNameField && !validateManualEntryNameInput()) return false;
    if (showCityField && !validateManualEntryCityInput()) return false;

    return !!formState.selectedSchool;
  }

  function validateCountryIsCompleted() {
    return Boolean(formState.countryCode === 'us' ? formState.state : formState.countryCode);
  }

  async function onSubmit(event?: React.FormEvent) {
    event?.preventDefault();
    const { selectedSchool: school, state, reasonMessage, worksAtSchool } = formState;

    if (!validateForm(formState)) {
      const schoolNameIsBlank = school?.type === 'manualEntry' ? !school.name : !school;
      if (schoolNameIsBlank) setSchoolPickerError(true);
      return;
    }

    try {
      if (!worksAtSchool) {
        return saveReason(formState.countryCode, state, reasonMessage);
      }

      if (!school) {
        return;
      }

      if (school?.type === 'manualEntry' && (!school?.['name'] || !school?.['city'])) {
        return;
      }

      if (school.type === 'selectedSuggestion' && school.selectedSuggestion.isSuper) {
        setPickChildSource('school_picker');
        return props.goToStep('pick-child', school.selectedSuggestion.organizationId);
      }

      if (school.type === 'selectedSuggestion' && school?.selectedSuggestion?.organizationId) {
        const organizationId = school.selectedSuggestion.organizationId;
        const emailWasWhitelisted = await makeSureUserEmailIsWhitelisted(organizationId);

        if (!emailWasWhitelisted) {
          dispatch({ type: 'setErrorMessage', message: 'error.whitelistedEmailNeeded' });
          return;
        }
      }

      const selectedSchool =
        school.type === 'manualEntry'
          ? { name: school.name, city: school.city }
          : { globalSchoolId: school.selectedSuggestion.globalSchoolId };

      const res = await services.user.saveOrganizationRegistration({
        selectedSchool,
        countryCode: formState.countryCode,
        state,
        useV2: isFeatureFlagEnabled('schoolPickerV2'),
      });

      if (res.addedToOrganization) {
        dispatch({ type: 'setConfirmationMessage', message: 'confirmSchoolLicense' });
        if (school.type === 'selectedSuggestion' && school.selectedSuggestion.organizationId) {
          tracker.events.teacherRequestToJoinOrganizationSent({
            organizationId: school.selectedSuggestion.organizationId,
            requestToJoinMethod: 'school_picker',
          });
          tracker.events.registrationUserSchoolChangeForm({
            schoolSuggestionId: school.selectedSuggestion._id,
            city: school.selectedSuggestion.city,
            schoolName: school.selectedSuggestion.name,
          });
        }
      }
      // Note: order of events is important. Please keep the same.
      trackSchoolPickerChangeEvent(school);
      trackRegisterFinishedEvents(formState.countryCode);
      return props.next();
    } catch (error) {
      logger.error(error);
      dispatch({ type: 'setErrorMessage', message: 'genericError' });
    }
  }

  /**
   * @returns whether a new whitelisted email was added correctly
   */
  async function makeSureUserEmailIsWhitelisted(organizationId: string): Promise<boolean> {
    const emails = user ? MongoUser.allEmailsForUser(user) : [];
    if (!emails.length) {
      throw new AppError('unexpected-data', 'User should have an email in this step of the registration', {
        userId: user?._id,
      });
    }

    const hasWhitelistedEmail = await services.domainRules.containsEmailsWhitelistedForOrganization({
      emails,
      organizationId,
    });

    if (hasWhitelistedEmail) return true;

    const onAddEmail = async (email: string) => {
      const isWhitelistedEmail = await services.domainRules.containsEmailsWhitelistedForOrganization({
        emails: [email],
        organizationId,
      });

      if (!isWhitelistedEmail) return false;

      await services.user.addEmail({ email });

      return true;
    };

    const emailAddedSuccessfully = !!(await whitelistEmailModal.show({ onSubmit: onAddEmail }));
    return emailAddedSuccessfully;
  }

  function trackRegisterFinishedEvents(countryCode: Country.CountryCode) {
    trackAction('register-organization-finished', registerFinishedParamsFromFormState({ ...formState, countryCode }));
    tracker.events.registrationCompleted();

    trackAction('register-flow-finished');
  }

  function trackSchoolPickerChangeEvent(school: FormStateSelectedSchool) {
    if (school?.type === 'manualEntry') {
      return tracker.events.registrationUserSchoolChangeForm({
        schoolName: school.name,
        city: school.city,
        schoolSuggestionId: undefined,
      });
    }

    return tracker.events.registrationUserSchoolChangeForm({
      schoolSuggestionId: school.selectedSuggestion.globalSchoolId,
      schoolName: school.selectedSuggestion.name,
      city: school.selectedSuggestion.city,
    });
  }

  function registerFinishedParamsFromFormState(formState: FormState & { countryCode: Country.CountryCode }) {
    if (!formState.worksAtSchool) {
      return {
        countryCode: formState.countryCode,
        worksAtSchool: false,
        usState: formState.state,
      };
    }

    if (!formState.selectedSchool) {
      throw new AppError('unexpected-data', 'SchoolPickerPage: selectedSchool is undefined');
    }

    if (formState.selectedSchool?.type === 'manualEntry') {
      return {
        schoolName: formState.selectedSchool?.['name']?.trim() || '',
        schoolCity: formState.selectedSchool?.['city']?.trim() || '',
        countryCode: formState.countryCode,
        usState: formState.state,
      };
    }

    return {
      selectedSchool: formState.selectedSchool && formState.selectedSchool.selectedSuggestion._id,
      countryCode: formState.countryCode,
      usState: formState.state,
    };
  }

  async function saveReason(
    countryCode: Country.CountryCode,
    state: UnitedStates.StateCode | undefined,
    reason: string | undefined
  ) {
    try {
      if (!reason) return;
      trackRegisterFinishedEvents(countryCode);

      await services.user.setReasonForSignup({
        country: countryCode,
        state,
        reason: reason!.trim(),
        schoolType: 'none',
      });
      props.next();
    } catch (error) {
      logger.error(error);
      dispatch({ type: 'setErrorMessage', message: 'genericError' });
    }
  }

  function handleSetSchoolTypeChange(value: boolean) {
    dispatch({ type: 'setSchoolTypeToNone', schoolTypeIsNone: !value });
  }

  const onSchoolSuggestorChange = (school: IndexedSchoolSuggestion | undefined) => {
    if (school) return;
    dispatch({ type: 'clearMessage' });
  };

  return (
    <form className={bemClasses()} onSubmit={onSubmit}>
      <div className={bemClasses({ element: 'title' })}>
        <Heading as="h1" className={bemClasses({ element: 'heading' })}>
          {t('header')}
        </Heading>
        <p className={bemClasses({ element: 'description' })}>{t('description')}</p>
        <Benefits />
      </div>
      <div className={bemClasses({ element: 'content' })}>
        <Switch
          checked={formState.worksAtSchool}
          label={t('workAtSchool')}
          className={bemClasses({ element: 'switch' })}
          onChange={(val) => handleSetSchoolTypeChange(val)}
        ></Switch>

        <CountryStateForm
          onChange={(action: SetCountryAction | SetUSStateAction) => {
            setSchoolPickerError(false);
            dispatch(action);
          }}
          country={formState.countryCode}
          usState={formState.state}
        />

        {!formState.worksAtSchool ? (
          reasonInput
        ) : countryIsSchoolPickerCountry ? (
          <ClientOnlyModernWrapper>
            <SchoolSuggestor
              countryCode={formState.countryCode}
              onSelect={onSelectSchoolSuggestion}
              onSetManualEntry={(name: string) => dispatch({ type: 'setManualEntrySchoolName', name })}
              hasError={schoolPickerError}
              invalidateError={() => setSchoolPickerError(false)}
              onChange={onSchoolSuggestorChange}
            />
          </ClientOnlyModernWrapper>
        ) : (
          showNameField && manualEntryNameInput
        )}

        {showCityField && manualEntryCityInput}
      </div>
      <Message message={formState.message && t(formState.message.message)} type={formState.message?.type} />
      {formState.message?.message === 'error.whitelistedEmailNeeded' && (
        <TryAgainWrapper>
          <TextButton type="submit">{t('tryAgain')}</TextButton>
        </TryAgainWrapper>
      )}
      <Button
        version="modern"
        theme="secondary"
        type="submit"
        className={bemClasses({ element: 'button' })}
        disabled={formState.message?.type === 'error'}
      >
        <span>{t('cta')}</span>
      </Button>
    </form>
  );
};

const Benefits = () => {
  const bemClasses = useBEM('SchoolPickerPage');
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  return (
    <ul className={bemClasses({ element: 'benefitsList' })}>
      <li>{t('reasonOne')}</li>
      <li>{t('reasonTwo')}</li>
      <li>{t('reasonThree')}</li>
    </ul>
  );
};

const Message: React.FC<{ message: string | undefined; type: 'error' | 'success' | undefined }> = ({
  message,
  type,
}) => {
  const bemClasses = useBEM('Message');

  if (!message || !type) return null;
  return <span className={bemClasses({ modifier: type })}>{message}</span>;
};

const TryAgainWrapper = styled.div`
  text-align: center;
`;
