import { History } from 'history';
import i18next from 'i18next';
import { LessonUpApiService } from '../../shared-core/client/services/api';
import { setLanguageCookie } from '../../shared-core/client/services/language/setLanguageCookie';

import { logger } from '@lessonup/client-integration';
import { updateLanguage } from '@lessonup/intercom';
import { AppError } from '@lessonup/utils';
import { Facets, Language, LanguageSingleton, MongoUserProfile, SearchParams } from '../../shared-core/domain';
import { Api } from '../../shared-core/domain/api/LessonupApi';
import { Country } from '../../shared-core/domain/country/Country';
import { UnitedStates } from '../../shared-core/domain/country/UnitedStates';
import { Location } from '../../shared-core/domain/newExplorers';
import { EnvConfig } from '../../shared-core/services';
import { lessonPlanRoute } from '../../shared-core/services/app/searchRoutes';
import { editorUrl, teacherTeachUrl, TeachSource } from '../../shared-core/services/app/teacherRoutes';
import { ToastManager } from '../../shared-core/ui/components/toast/ToastProvider';
import { AppStore } from '../redux/store';
import configureChannelAppService from './appServices/ChannelAppService';
import configureSchoolPickerAppService from './appServices/SchoolPickerAppService';
import configureVoucherAppService from './appServices/VoucherAppService';
import { SearchAppModalManagerService } from './SearchAppModalService';
import { UserService } from './UserService';

const TRANSLATION_NS = 'AppServices';

export type AppServices = ReturnType<typeof configureServices>;
export interface SaveLessonResult {
  lessonId: string;
  currentUser: boolean;
}

export let appServices: AppServices;

export function configureServices(
  api: LessonUpApiService,
  userService: UserService,
  config: EnvConfig,
  modals: SearchAppModalManagerService,
  store: AppStore,
  i18n: i18next.i18n,
  history: History<any>
) {
  let toastManager: ToastManager;
  const registerToastManager = (manager: ToastManager) => {
    toastManager = manager;
  };

  const user = {
    currentUser: () => userService.currentUser(),
    loginWithPassword: (email: string, password: string, loginSource: 'standalone' | 'modal') => {
      return userService.loginWithEmailPassword({
        email,
        password,
        loginSource,
      });
    },
    logout: () => userService.logout(),
    onAcceptTerms: () => userService.onAcceptTerms(),
    setLanguage: async (language: Language.Key) => {
      i18n.changeLanguage(language);
      LanguageSingleton.set(language);
      setLanguageCookie(language);
      updateLanguage(language);

      if (userService.currentUser()) {
        await userService.setData('language', language);
      }
      const path = history.location.pathname;
      const search = history.location.search;

      const lang = Language.languageFromPath(path);
      if (!lang) {
        logger.error('could not find language in path', { path });
        return;
      }

      const pathWithQuery = [path, search].join('');

      const url = Language.changePathLanguage(pathWithQuery, language);
      history.replace(url);
    },
    setCountry: (country: Country.CountryCode) => userService.setData('country', country),
    setUSState: (USState: UnitedStates.StateCode) => userService.setData('USState', USState),
    storeName: (params: { givenName: string; familyName: string }) => userService.setName(params),
    setAcceptEmails: () => userService.setData('receiveEmails', true),
    registerWithEmailVerificationTokenPassword: (params: Api.AuthMeteorEmailVerifiedRegistrationParams) =>
      userService.registerWithEmailVerificationTokenPassword({
        ...params,
      }),
    requestMagicLink: (email: string, language: string, emailVariant: Api.magicLinkVariant) =>
      userService.requestMagicLink({ email, language, emailVariant }),
    requestPasswordReset: (email: string) => userService.requestPasswordReset({ email }),
    requestEmailValidation: (email: string) => userService.requestEmailValidation({ email }),
    verifyEmailByToken: (params: Api.VerifyEmailByTokenParams) => api.verifyEmailByToken(params),
    sendEmailVerificationEmail: (params: Api.SendEmailVerificationEmailParams) =>
      api.sendEmailVerificationEmail(params),
    resetPassword: (userId: string, token: string, password: string) =>
      userService.resetPassword({ userId, token, password }),
    hasProFeatures: () => userService.hasProFeatures(),
    hasTestAccess: () => userService.hasTestAccess(),
    userHasProducts: () => userService.userHasProducts(),
    sendOrganizationJoinRequest: (organizationId: string) => userService.sendOrganizationJoinRequest(organizationId),
    setSchoolType: (schoolTypes: MongoUserProfile['schoolTypes']) => userService.setSchoolType(schoolTypes),
    setReasonForSignup: (params: Api.SaveReasonParams) => userService.setReasonForRegistration(params),
    saveOrganizationRegistration: (params: Api.SaveOrganizationRegistrationParams) => {
      return userService.saveOrganizationRegistration(params);
    },
    addEmail: (params: Api.AddEmailParams) => api.addEmail(params),
  };

  const search = {
    searchLessons: async (params: SearchParams, subType?: Facets.FacetSubTypes[]) => api.searchLessons(params, subType),
    searchPlans: async (params) => api.searchPlans(params),
    searchPins: async (params) => api.searchPins(params),
    storeSearchQueryString: (qs: string) => localStorage.setItem('latestSearchQueryString', qs),
  };

  const lessonEditor = (lessonId: string, pinId?: string) => {
    window.location.href = editorUrl(lessonId, pinId);
  };

  const createFolder = async (location: Location) => {
    const name = await modals.open('promptString', { nameType: 'createFolderModal' });
    if (!name) return;
    return api.createFolder({ name, location });
  };

  const explorer = {
    get: () => api.explorer(),
    getWithToken: (access_token, refresh_token) => api.explorerWithTokens(access_token, refresh_token),
    fetchContent: (location) => api.folderContent(location),
    fetchContentWithToken: ({ location, access_token, refresh_token }) =>
      api.folderContentWithToken({ location, access_token, refresh_token }),
    createFolder,
    pickLocationToSave: () => {
      return modals.open('locationPicker', {
        createFolder: () => createFolder,
        fetchExplorer: () => api.explorer(),
        translationKey: 'pickLocationToSave',
        enabledTypes: ['folder', 'sharedExplorer'],
      });
    },
  };

  const saveLesson = async (
    lessonId: string,
    preselectedLocation?: Location
  ): Promise<SaveLessonResult | undefined> => {
    if (user.currentUser()) {
      const location = preselectedLocation || (await explorer.pickLocationToSave());
      if (!location) return;
      const res = await api.saveLesson(lessonId, { location });

      return {
        lessonId: res.lessonId,
        currentUser: true,
      };
    }
  };

  const lesson = {
    lessonById: (id: string | undefined, omitHasMore = false) => {
      if (!id) return;
      return api.lessonDetails(id, { omitHasMore });
    },
    cluster: (lessonId) => api.lessonCluster(lessonId),
    setRating: async (lessonId: string, rating: number) => {
      await api.setRating(lessonId, { rating });
    },
    flagLesson: async (lessonId: string, comment: string) => {
      await api.flagLesson(lessonId, comment);
    },
    latestSearchQueryString: () => localStorage.getItem('latestSearchQueryString'),
    saveLesson,
    isLessonSaved: (id: string): boolean => {
      if (user.currentUser()) {
        return store.getState().savedLessons.includes(id);
      }
      return false;
    },
    saveLessonToRoot: async (lessonId: string): Promise<SaveLessonResult | undefined> => {
      const { explorer } = await api.explorer();
      const location: Location = { explorer: explorer._id, folder: explorer._id };
      return saveLesson(lessonId, location);
    },
    addPins: (lessonId: string, params: Api.LessonAddPinParams) => api.addPinToLesson(lessonId, params),
    addPinToActiveLesson: async (pinId: string): Promise<boolean> => {
      const u = user.currentUser();
      const lessonId = u && u.activeLesson && u.activeLesson._id;
      try {
        if (!lessonId) return false;
        const res = await api.addPinToLesson(lessonId, {
          pinIds: [pinId],
        });
        const succes = res.newPinIds.length > 0;
        if (succes) {
          toastManager.add({
            msg: i18n.t('slideAddedConfirmToastMsg', { ns: TRANSLATION_NS }),
            actionButton: {
              label: i18n.t('toEditorBtnText', { ns: TRANSLATION_NS }),
              action: () => {
                lessonEditor(lessonId, res.newPinIds[0]);
              },
            },
          });
        } else {
          throw new AppError('unexpected-data', 'add pin failed');
        }
        return succes;
      } catch (error) {
        logger.error(error);
        toastManager.add({
          msg: i18n.t('slideAddedFailedToastMsg', { ns: TRANSLATION_NS }),
        });
        return false;
      }
    },

    teach: (params: { lessonId: string; pinId?: string; source?: TeachSource; newTab?: boolean }) => {
      const { lessonId, pinId, source, newTab } = params;
      const teachParams = { lessonId, pinId, source: source ?? 'search', language: LanguageSingleton.get() };

      const url = teacherTeachUrl(teachParams);
      if (newTab) {
        window.open(url, '_blank');
        return;
      }
      window.location.href = url;
    },
    shareWithStudents: (lessonId: string) => {
      const url = `${config.teacherUrl}app/lesson/${lessonId}?handout=true`;
      window.location.href = url;
    },
    edit: lessonEditor,
    demoLesson: (params: Api.GetDemoLessonParams) => api.demoLesson(params.language),
    lessonDownloadAttachment: (params: Api.LessonDownloadAttachmentParams) => api.lessonDownloadAttachment(params),
  };

  const gotoPlanUrl = (planId: string) => lessonPlanRoute(planId);

  const lessonPlan = {
    planById: (planId: string) => api.lessonPlanDetails(planId),
    gotoPlan: (planId: string) => {
      window.location.href = gotoPlanUrl(planId);
    },
    gotoPlanUrl,
    saveLessonPlan: async (
      planId: string,
      location: Location
    ): Promise<{ savedToAccount: boolean; planId?: string }> => {
      const res = await api.saveLessonPlan({ planId, location });

      return {
        savedToAccount: res.success,
        planId: res.success ? res.planId : undefined,
      };
    },
    lessonPlanDownloadAttachment: (params: Api.PlanDownloadAttachmentParams) =>
      api.lessonPlanDownloadAttachment(params),
  };

  const domainRules = {
    getSuggestions: () => api.domainRuleSuggestions(),
    containsEmailsWhitelistedForOrganization: (params: Api.ContainsEmailsWhitelistedForOrganizationParams) =>
      api.containsEmailsWhitelistedForOrganization(params),
  };

  const organizations = {
    childOrganizationsForParent: (organizationId: string) => api.getChildrenFromParent(organizationId),
  };

  const referral = {
    referralByReferralToken: (token: string) => api.referralByReferralToken({ token }),
  };

  const appServicesModel = {
    search,
    lesson,
    lessonPlan,
    user,
    channel: configureChannelAppService(api, modals),
    schoolPicker: configureSchoolPickerAppService(api),
    registerToastManager,
    config,
    modals,
    explorer,
    voucher: configureVoucherAppService(api),
    domainRules,
    organizations,
    referral,
  };

  appServices = appServicesModel;

  return appServicesModel;
}

export function ssrSpoofAppServices(envConfig: EnvConfig): AppServices {
  const ssrError = (functionName: string) =>
    new AppError('not-allowed', `${functionName} should not be called during ssr`);
  function reject(functionName: string) {
    return Promise.reject(ssrError(functionName));
  }

  const appServicesModel: AppServices = {
    lesson: {
      latestSearchQueryString: () => null,
      lessonById: () => reject('lessonById'),
      setRating: () => reject('setRating'),
      flagLesson: () => reject('flagLesson'),
      saveLesson: () => reject('saveLesson'),
      isLessonSaved: () => false,
      saveLessonToRoot: () => reject('saveLessonToRoot'),
      teach: () => reject('teach'),
      shareWithStudents: () => reject('shareWithStudents'),
      edit: () => reject('editLesson'),
      cluster: () => reject('clusterLesson'),
      addPins: () => reject('addPIn'),
      addPinToActiveLesson: () => reject('addPinToActiveLesson'),
      demoLesson: () => reject('demoLesson'),
      lessonDownloadAttachment: () => reject('lessonDownloadAttachment'),
    },
    search: {
      searchLessons: () => reject('search'),
      searchPlans: () => reject('search'),
      searchPins: () => reject('search'),
      storeSearchQueryString: () => {},
    },
    user: {
      currentUser: () => undefined,
      registerWithEmailVerificationTokenPassword: () =>
        Promise.reject(ssrError('registerWithEmailVerificationTokenPassword')),
      onAcceptTerms: () => Promise.reject(ssrError('onAcceptTerms')),
      setLanguage: () => Promise.reject(ssrError('setLanguage')),
      setCountry: () => Promise.reject(ssrError('setCountry')),
      setUSState: () => Promise.reject(ssrError('setUSState')),
      setAcceptEmails: () => Promise.reject(ssrError('setAcceptEmails')),
      logout: () => reject('logout'),
      loginWithPassword: () => reject('loginWithPassword'),
      verifyEmailByToken: () => reject('verifyEmailByToken'),
      requestEmailValidation: () => reject('requestEmailValidation'),
      sendEmailVerificationEmail: () => reject('sendEmailVerificationEmail'),
      requestMagicLink: () => reject('requestMagicLink'),
      requestPasswordReset: () => reject('requestPasswordReset'),
      resetPassword: () => reject('resetPassword'),
      hasProFeatures: () => false,
      hasTestAccess: () => false,
      userHasProducts: () => false,
      sendOrganizationJoinRequest: () => reject('sendOrganizationJoinRequest'),
      setSchoolType: () => Promise.reject('setSchoolType'),
      setReasonForSignup: () => Promise.reject('setReasonForSignup'),
      storeName: () => Promise.reject('storeName'),
      saveOrganizationRegistration: () => reject('saveOrganizationRegistration'),
      addEmail: () => Promise.reject('addEmail'),
    },
    lessonPlan: {
      planById: () => reject('planById'),
      gotoPlan: () => reject('gotoPlan'),
      gotoPlanUrl: (planId: string) => lessonPlanRoute(planId),
      saveLessonPlan: () => reject('savePlan'),
      lessonPlanDownloadAttachment: () => reject('lessonPlanDownloadAttachment'),
    },
    channel: {
      unfollowChannel: () => reject('unfollowChannel'),
      fetchChannelOverviewChannels: () => reject('fetchChannelOverviewChannels'),
    } as any,
    schoolPicker: {
      getSchoolPickerSuggestions: () => Promise.reject('getSchoolPickerSuggestions'),
    },
    explorer: {
      get: () => reject('get explorer folder'),
      getWithToken: () => reject('get explorer folder with token'),
      fetchContent: () => reject('get folder content'),
      fetchContentWithToken: () => reject('get folder content with token'),
      pickLocationToSave: () => reject('pick folder'),
      createFolder: () => reject('create folder'),
    },

    voucher: {
      redeem: () => reject('redeemVoucher'),
      validate: () => reject('validateVoucher'),
      redeemOrValidateVoucher: () => reject('redeemOrValidate'),
    },
    domainRules: {
      getSuggestions: () => Promise.reject('getSuggestions'),
      containsEmailsWhitelistedForOrganization: (_: Api.ContainsEmailsWhitelistedForOrganizationParams) =>
        reject('containsEmailsWhitelistedForOrganization'),
    },
    registerToastManager: () => {},
    organizations: {
      childOrganizationsForParent: () => Promise.reject('childOrganizationsForParent'),
    },
    config: envConfig,
    modals: (() => ssrError('searchAppModalService')) as any,
    referral: {
      referralByReferralToken: () => reject('referralByReferralToken'),
    },
  };

  return appServicesModel;
}

export type AppSearchService = typeof appServices.search;
export type AppLessonService = typeof appServices.lesson;
export type AppUserService = typeof appServices.user;
