import React from 'react';
import './Table.less';

export interface TableProps {
  className?: string;
}

export const Table: React.FC<TableProps> = ({ children, className, ...props }) => (
  <table className={`table ${className}`} {...props}>
    {children}
  </table>
);

export default Table;
