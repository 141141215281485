import React from 'react';
import './TableHeader.less';

interface TableHeaderProps {
  className?: string;
  sticky?: boolean;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ children, className, sticky = true, ...props }) => (
  <thead className={`table-header ${sticky && 'table-header--sticky'} ${className}`} {...props}>
    {children}
  </thead>
);

export default TableHeader;
