import { FractionalIndex, ID, Lesson, UserContent, UserContentPrivacy } from '@lessonup/teaching-core';
import { AppError } from '@lessonup/utils';
import { compact, findIndex, first, flatten, isString, last, orderBy, uniq } from 'lodash';
import { Filter } from 'mongodb';
import {
  findFolderInExplorer,
  Folder,
  FolderId,
  FolderMetaWithFolderIndex,
  FolderWithNavigationData,
  Locatable,
  MultiFolderLocation,
} from '.';
import { ChannelId } from '../channels';
import { SymbolicLink } from '../explorers';
import { LessonPlan } from '../lessonPlan';
import { OrganizationSharedExplorer } from '../organizations/OrganizationSharedExplorer';
import { MongoUser, UserSharedExplorer } from '../user';
import { BaseFolder, FolderDetails, FolderMeta } from './Folder';
import { FolderItem } from './Item';
import { Location } from './Location';

export type ExplorerId = string;
export type ExplorerType = 'content' | 'archive' | 'trash';
export type ExplorerOwnership = 'personal' | 'shared';

export type ExplorerContentForType = {
  lesson: Lesson;
  lessonPlan: LessonPlan;
  folder: Folder & FolderMeta;
  sharedExplorer: Explorer;
  symbolicLink: SymbolicLink.WithContent;
};

export type ExplorerCombinedContentTypes = ExplorerContentForType[keyof ExplorerContentForType];

export interface ExplorerLessonContent {
  type: 'lesson';
  content: ExplorerContentForType['lesson'];
}
export interface ExplorerLessonPlanContent {
  type: 'lessonPlan';
  content: ExplorerContentForType['lessonPlan'];
}
export interface ExplorerFolderContent {
  type: 'folder';
  content: ExplorerContentForType['folder'];
}

export interface ExplorerSharedExplorerContent {
  type: 'sharedExplorer';
  content: ExplorerContentForType['sharedExplorer'];
}

export interface ExplorerSymbolicLinkContent {
  type: 'symbolicLink';
  content: ExplorerContentForType['symbolicLink'];
}

export type ExplorerContent =
  | ExplorerLessonContent
  | ExplorerLessonPlanContent
  | ExplorerFolderContent
  | ExplorerSharedExplorerContent
  | ExplorerSymbolicLinkContent;

export type ExplorerUserContent = ExplorerLessonContent | ExplorerLessonPlanContent | ExplorerSymbolicLinkContent;
export type ExplorerUserContentType = ExplorerUserContent['type'];
export type ExplorerUserContentItemContent = ExplorerUserContent['content'];

export type ExplorerContentSplit = {
  folders: ExplorerFolderContent[];
  lessons: ExplorerLessonContent[];
  lessonPlans: ExplorerLessonPlanContent[];
  sharedExplorers: ExplorerSharedExplorerContent[];
  links: ExplorerSymbolicLinkContent[];
};

export type ExplorerContentType = ExplorerContent['type'];

export type ExplorerIconType = ExplorerContentType | 'privateFolder';

export type ExplorerPermission = 'read' | 'write';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isExplorerPermission = (value: any): value is ExplorerPermission | undefined => {
  return value === 'write' || value === 'read' || value === undefined;
};

export interface ExplorerUserSettings {
  id: ID;
  permission?: ExplorerPermission;
}

export interface Explorer extends BaseFolder {
  _id: ExplorerId;
  type: ExplorerType;
  ownership: ExplorerOwnership;
  // Reference to the corresponding 'content' explorer or self-ref if type is 'content'
  mainExplorer: ExplorerId;
  organizations?: OrganizationSharedExplorer.Settings[];
  user: ID | undefined;
  isPublish?: boolean;
  channel?: ChannelId;
  templates?: boolean;
  creationDate: Date;
}

export type ContentExplorer = Explorer & { type: 'content' };
export type ArchiveExplorer = Explorer & { type: 'archive' };
export type TrashExplorer = Explorer & { type: 'trash' };

export type PersonalExplorer = Omit<Explorer, 'organizations'> & { ownership: 'personal'; user: ID };
export type SharedExplorer = Explorer & {
  ownership: 'shared';
  user: undefined;
  organizations: OrganizationSharedExplorer.Settings[];
};
export type PublishExplorer = SharedExplorer & { publish: true; channel: string };

export type PublishContentExplorer = PublishExplorer & { type: 'content' };

export type TemplateExplorer = SharedExplorer & { templates: true };

export type SharedExplorerMemberRole = 'member' | 'owner';

export function isContentExplorer(explorer: Explorer): explorer is ContentExplorer {
  return explorer.type === 'content';
}

export function explorerContentName(item: ExplorerContent): string {
  return (item.type === 'symbolicLink' ? item.content.content?.name : item.content.name) || '';
}

export function sortExplorerContentByOrder(list: ExplorerContent[]): ExplorerContent[] {
  return orderBy(list, 'content.order', ['asc']);
}

export function isArchiveExplorer(explorer: Explorer): explorer is ArchiveExplorer {
  return explorer.type === 'archive';
}

export function isTrashExplorer(explorer: Explorer): explorer is TrashExplorer {
  return explorer.type === 'trash';
}

export function isPublishExplorer(explorer: Explorer): explorer is PublishExplorer {
  return explorer.type === 'content' && !!explorer.isPublish;
}

export function isPersonalExplorer(explorer: Explorer): explorer is PersonalExplorer {
  return explorer.ownership === 'personal' && isString(explorer.user);
}

export function isSharedExplorer(explorer: Explorer): explorer is SharedExplorer {
  return explorer.ownership === 'shared';
}

export function isSharedTrashExplorer(explorer: Explorer): explorer is SharedExplorer {
  return isSharedExplorer(explorer) && isTrashExplorer(explorer);
}

export function isTemplateExplorer(explorer: Explorer): explorer is TemplateExplorer {
  return isSharedExplorer(explorer) && !!explorer.templates;
}

export function rootLocationForExplorerId(id: string): Location {
  return { explorer: id, folder: id };
}

export function isOwnerOfPersonalExplorer(explorer: Explorer, userId: string): explorer is PersonalExplorer {
  return isPersonalExplorer(explorer) && explorer.user === userId;
}

export const canUserAccessExplorer = (user: MongoUser, explorer: Explorer, permission: ExplorerPermission): boolean => {
  if (isPersonalExplorer(explorer)) {
    if (isOwnerOfPersonalExplorer(explorer, user._id)) {
      return true;
    }
    return false;
  } else if (isSharedExplorer(explorer)) {
    return UserSharedExplorer.canUserAccessSharedExplorer(user, explorer, permission);
  }
  throw new AppError('unexpected-data', 'unknown explorerType');
};

export function createLocationObject(explorerId: ExplorerId) {
  return (folderId: FolderId): Location => {
    return {
      explorer: explorerId,
      folder: folderId,
    };
  };
}

export function addExplorerContentType(type: 'lesson'): (object: Lesson) => ExplorerLessonContent;
export function addExplorerContentType(type: 'lessonPlan'): (object: LessonPlan) => ExplorerLessonPlanContent;
export function addExplorerContentType(type: 'folder'): (object: Folder) => ExplorerFolderContent;
export function addExplorerContentType(type: 'sharedExplorer'): (object: Explorer) => ExplorerSharedExplorerContent;
export function addExplorerContentType(
  type: 'symbolicLink'
): (object: SymbolicLink.WithContent) => ExplorerSymbolicLinkContent;
export function addExplorerContentType(type: ExplorerContentType) {
  return (object: ExplorerContent['content']): ExplorerContent => {
    return {
      content: object,
      type,
    } as ExplorerContent;
  };
}

export function moveFolder(
  [source, target, location]: [Explorer, Explorer, Location],
  folder: FolderItem
): [Explorer, Explorer, Location] {
  const [updatedSource, removedFolder] = getAndRemoveFolderFromExplorer(source, folder);
  const updatedTarget = insertFolderInExplorer(target, location, removedFolder);
  return [updatedSource, updatedTarget, location];
}

export function removeFoldersFromExplorer(explorer: Explorer, folders: FolderItem[]): Explorer {
  return folders.reduce((explorer, folder) => {
    const [explorerWithoutFolder] = getAndRemoveFolderFromExplorer(explorer, folder);
    return explorerWithoutFolder;
  }, explorer);
}

function getAndRemoveFolderFromExplorer(explorer: Explorer, folder: FolderItem): [Explorer, Folder] {
  const sourceFolderInfo = findFolderInExplorer(explorer, folder.id);
  if (!sourceFolderInfo) {
    throw new AppError('not-found', "Couldn't find folder");
  }

  if (sourceFolderInfo.privacy === 'private') {
    throw new AppError('not-allowed', 'Moving private folders is not allowed');
  }

  const parent = last(sourceFolderInfo?.parents);
  const parentFolder = parent && findFolderInExplorer(explorer, parent._id);
  if (!parentFolder) {
    throw new AppError('unexpected-data', "Folder has no parent. Shouldn't happen.");
  }

  const folderToMove = parentFolder.children.find((child) => child._id === folder.id);
  if (!folderToMove) {
    throw new AppError('not-found', "Couldn't find folder to move");
  }

  const indexOfFolder = findIndex(parentFolder.children, { _id: folder.id });
  parentFolder.children.splice(indexOfFolder, 1);

  return [explorer, folderToMove];
}

function insertFolderInExplorer(explorer: Explorer, location: Location, folder: Folder): Explorer {
  const destinationFolderInfo = findFolderInExplorer(explorer, location.folder);
  if (!destinationFolderInfo) throw new Error("Couldn't find folder");

  const order = FractionalIndex.generateAppendedOrderKey(destinationFolderInfo.children.map((c) => c.order));

  destinationFolderInfo.children.push({ ...folder, order });
  return explorer;
}

export function tryGetFolderFromExplorer(
  explorer: Explorer,
  folderId?: FolderId
): FolderWithNavigationData | undefined {
  try {
    return getFolderFromExplorer(explorer, folderId);
  } catch (error) {
    if (AppError.isError(error, 'not-found')) {
      console.error(error);
      return;
    }

    throw error;
  }
}

export function getFolderFromExplorer(explorer: Explorer, folderId?: FolderId): FolderWithNavigationData {
  const folderOrExplorerId = folderId || explorer._id;
  const folder = findFolderInExplorer(explorer, folderOrExplorerId);

  if (!folder) {
    throw Error("Couldn't find a folder.");
  }

  return folder;
}

export function folderDetails(folder: FolderWithNavigationData): FolderDetails {
  const { _id, children, parents, name, order, description, image } = folder;

  return {
    _id,
    name,
    parents,
    order,
    children: children.map(({ _id, name, order, products }) => ({ _id, name, order, products })),
    privacy: folderPrivacy(folder),
    products: folderProducts(folder),
    channel: folderChannel(folder),
    description,
    image,
  };
}

export function getFolderDetailsFromExplorer(explorer: Explorer, folderId?: FolderId): FolderDetails {
  return folderDetails(getFolderFromExplorer(explorer, folderId));
}

export function getExplorerIdFromDocument(doc: UserContent): string | undefined {
  return doc.location?.explorer;
}

export function folderPrivacy(folder: FolderWithNavigationData): UserContentPrivacy {
  const privacyValues = folderParentValues(folder, 'privacy').concat(folder.privacy);
  if (privacyValues.some((val) => val === 'protected')) return 'protected';
  if (privacyValues.some((val) => val === 'private')) return 'private';
  return 'public';
}

export function folderProducts(folder: FolderWithNavigationData): string[] {
  return uniq(flatten(compact(folderParentValues(folder, 'products').concat(folder.products))));
}

export function folderChannel(folder: FolderWithNavigationData): string | undefined {
  return first(uniq(flatten(compact(folderParentValues(folder, 'channel').concat(folder.channel)))));
}

export function folderParentValues<T extends keyof FolderMetaWithFolderIndex>(
  folder: FolderWithNavigationData,
  field: T
): FolderMetaWithFolderIndex[T][] {
  const { parents } = folder;
  return parents.map((p) => p[field]);
}

export function getFolderContentFromExplorer(explorer: Explorer, folderId: FolderId): ExplorerFolderContent[] {
  const folder = getFolderFromExplorer(explorer, folderId);
  const childFoldersAsContent = folder.children.map(addExplorerContentType('folder'));
  return childFoldersAsContent;
}

export function isFolderContent(content: ExplorerContent): content is ExplorerFolderContent {
  return content.type === 'folder';
}

export function isLessonPlanContent(content: ExplorerContent): content is ExplorerLessonPlanContent {
  return content.type === 'lessonPlan';
}

export function isLessonContent(content: ExplorerContent): content is ExplorerLessonContent {
  return content.type === 'lesson';
}

export function isSharedExplorerContent(content: ExplorerContent): content is ExplorerSharedExplorerContent {
  return content.type === 'sharedExplorer';
}

export function isSymbolicLinkExplorerContent(content: ExplorerContent): content is ExplorerSymbolicLinkContent {
  return content.type === 'symbolicLink';
}

export function splitExplorerContentsByType(contents: ExplorerContent[]): {
  folders: ExplorerFolderContent[];
  lessons: ExplorerLessonContent[];
  lessonPlans: ExplorerLessonPlanContent[];
  sharedExplorers: ExplorerSharedExplorerContent[];
  links: ExplorerSymbolicLinkContent[];
} {
  return {
    folders: contents.filter(isFolderContent),
    lessonPlans: contents.filter(isLessonPlanContent),
    lessons: contents.filter(isLessonContent),
    sharedExplorers: contents.filter(isSharedExplorerContent),
    links: contents.filter(isSymbolicLinkExplorerContent),
  };
}

export function explorersToSharedExplorerContents(sharedExplorers: Explorer[]): ExplorerSharedExplorerContent[] {
  const addSharedExplorerContentType = addExplorerContentType('sharedExplorer');
  return sharedExplorers.map(addSharedExplorerContentType);
}

export function getContentsFromExplorerContentSplit(
  contents: ExplorerContentSplit
): (ExplorerLessonContent | ExplorerLessonPlanContent)[] {
  return [...contents.lessons, ...contents.lessonPlans];
}

export function locationToMongoSelector<T extends Locatable = Locatable>(location: Location): Filter<T> {
  // Had to cast, as Locatable gives issues due to all fields being optional
  return {
    'location.folder': location.folder,
    'location.explorer': location.explorer,
  } as Filter<unknown> as Filter<T>;
}

export function multiFolderLocationToMongoSelector<T extends Locatable = Locatable>(
  location: MultiFolderLocation
): Filter<T> {
  // Had to cast, as Locatable gives issues due to all fields being optional
  return {
    'location.explorer': location.explorer,
    'location.folder': { $in: location.folders },
  } as Filter<unknown> as Filter<T>;
}

export function allNestedChildrenForFolder(node: BaseFolder): Folder[] {
  const recursive = (node: BaseFolder) => {
    if (node.children.length === 0) {
      return [node];
    }
    const childFolders = node.children.map(recursive);
    return [node, ...flatten(childFolders)];
  };

  return recursive(node);
}

export function allNestedChildrenIdsForFolder(node: BaseFolder): string[] {
  return allNestedChildrenForFolder(node).map(({ _id }) => _id);
}

export const permissionIsHigherOrEqualTo = (
  permission: ExplorerPermission | undefined,
  comparedTo: ExplorerPermission | undefined
): boolean => {
  return permissionsHigherThanOrEqualTo(comparedTo).includes(permission);
};

export const permissionIsLowerOrEqualTo = (
  permission: ExplorerPermission | undefined,
  comparedTo: ExplorerPermission | undefined
): boolean => {
  return permissionsLowerOrEqualTo(comparedTo).includes(permission);
};

export const permissionsLowerOrEqualTo = (
  comparedTo: ExplorerPermission | undefined
): (ExplorerPermission | undefined)[] =>
  comparedTo === 'write' ? ['write', 'read'] : comparedTo === 'read' ? ['read'] : [];

export const permissionsHigherThanOrEqualTo = (
  comparedTo: ExplorerPermission | undefined
): (ExplorerPermission | undefined)[] =>
  comparedTo === 'write' ? ['write'] : comparedTo === 'read' ? ['write', 'read'] : ['write', 'read', undefined];
