import React, { useState } from 'react';
import { useContextGenerator } from '../../shared-core/ui/utils/react';

const TopLevelContext = React.createContext<TopLevelClassnameContextProps | undefined>(undefined);

export interface TopLevelClassnameContextProps {
  className: string;
  setClassName: (className: string) => void;
}

export const TopLevelClassnameProvider: React.FunctionComponent<{}> = (props) => {
  const [className, setClassName] = useState<string>('');

  return <TopLevelContext.Provider value={{ className, setClassName }}>{props.children}</TopLevelContext.Provider>;
};

export const useTopLevelClassnameContext = useContextGenerator(TopLevelContext, 'TopLevelContext');
