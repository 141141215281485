import classNames from 'classnames';
import React from 'react';
import './Tooltip.less';

export type TooltipColor = 'yellow' | 'cyan' | 'dark' | 'light';
export type TooltipOrientation = 'top' | 'right' | 'bottom' | 'left';
export type TooltipDirection = 'top' | 'right' | 'bottom' | 'left' | 'center';

export interface TooltipViewProps {
  color?: TooltipColor;
  orientation?: TooltipOrientation;
  direction?: TooltipDirection;
  customClassNames?: string;
  inline?: boolean;
}

const TooltipView: React.FunctionComponent<TooltipViewProps> = (props) => {
  function colorClass() {
    return `tooltip--${props.color || 'yellow'}`;
  }

  const orientationClass = props.orientation || 'bottom';

  const classes = classNames('tooltip', colorClass(), [`orientation-${orientationClass}`], props.customClassNames, {
    [`direction-${props.direction}`]: !!props.direction,
    inline: props.inline,
  });

  return <div className={classes}>{props.children}</div>;
};

export default TooltipView;
