import { ActionTrackerTransport, globalGTAG, GTAG, TrackActionConfig } from '@lessonup/client-integration';
import { TrackerAction, TrackerParams, TrackingUserIds, UserDimensions } from '@lessonup/utils';
import { channelGAActionFromAction } from '../../../../services/tracking/GAChannelActions';

export interface GoogleEventOptions {
  event_category: string;
  event_label?: string;
  value?: number;
}

export interface GoogleAnalyticsGTagEvent {
  action: string;
  category: string;
  eventLabel?: string;
  eventValue?: number;
}

/**
 * This class wraps the gtag global. This one is quirky because at the time of writing everyting needs to be in one config.
 * Note that we are only using GA-4 from now on.
 */
export class GA4ActionTransport implements ActionTrackerTransport {
  public constructor(
    public readonly ga4Id: string | undefined,
    public readonly awId: string | undefined,
    private readonly gtag: GTAG = globalGTAG()
  ) {
    // ga4 is now configured via the @lessonup/analytics package, this is only there to keep sending the old actions to GA4 for the time being
  }

  public setUserId(userId: string | undefined): void {
    // this is now being done in @lessonup/analytics package
  }

  // we have no support tor this in snowplow, but we leave it here for now. until we remove the total support for it
  // we don't think anyone is really using this information
  public setCustomDimensions(params: UserDimensions): void {
    // Set each property individually. Otherwise the whole user_properties objects gets overwritten.
    Object.entries(params).forEach(([key, value]) => this.gtag('set', `user_properties.${key}`, value));
  }

  /** mostly copied from the old trackActionClient */
  public event<K extends TrackerAction>(
    action: K,
    params: TrackerParams<K> & TrackActionConfig,
    userIds: Partial<TrackingUserIds>
  ): void {
    this.customGAEvent(action, params);

    if (params && params.tracker) {
      this.externalEvent(params.tracker, action, params);
    }
  }

  public registerPageViewForExternalTracker(path: string, config: TrackActionConfig): void {
    if (config.tracker) {
      this.externalPageView(config.tracker, path);
    }
  }

  private customGAEvent<K extends TrackerAction>(action: K, params: TrackerParams<K> & TrackActionConfig): void {
    this.gtag('event', action, params);
  }

  /**
   * sends a pageview to an external analytics account. this does not send user information build set with the gtag('config') and gtag('set')
   */
  private externalPageView(gaCode: string, path: string = window.location.pathname + window.location.search) {
    this.gtag('event', 'page_view', {
      send_to: gaCode,
      page_path: path,
      user_properties: {},
      user_id: null,
    });
  }

  /**
   * send an event to an external analytics account. this does not send user information build set with the gtag('config') and gtag('set')
   */
  private externalEvent<K extends TrackerAction>(
    gaCode: string,
    action: K,
    params: TrackerParams<K> & TrackActionConfig
  ) {
    const actionParams = channelGAActionFromAction(action, params);
    if (!actionParams) return;

    const { eventName, event_category, event_label, value } = actionParams;

    this.gtag('event', eventName, {
      send_to: gaCode,
      event_category,
      event_label,
      value,
      user_properties: {},
      user_id: null,
    });
  }
}
