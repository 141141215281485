import { AssignmentPin, DragQuestion, OpenQuestion, Poll, QuizQuestion, Slide, Wordweb } from '@lessonup/teaching-core';
import classNames from 'classnames';
import React from 'react';
import { ImageUtils } from '../../../utils/ImageUtils';

export interface ImageComponentProps {
  imageUrl: string | undefined;
  type: string;
  opacity: number | undefined;
  imageSize: string | undefined;
  isThumb?: boolean;
  number2?: boolean;
}

export default class ImageComponent extends React.PureComponent<ImageComponentProps> {
  private zoom = () => {};

  public render = () => {
    const { imageUrl, type, opacity, imageSize, isThumb, number2 } = this.props;
    const opacityClass = opacity ? 'opacity-' + opacity : 'opacity-100';

    return (
      <div
        data-component-id={type}
        comp-remote-event="click"
        className={classNames(number2 ? 'image2' : 'image', opacityClass, {
          [`image-size-${imageSize}`]: Boolean(imageSize),
        })}
        style={{ backgroundImage: ImageUtils.urlString(ImageUtils.checkImageUrl(imageUrl, isThumb)) }}
      />
    );
  };
}

type ContentWithImages =
  | Slide.Content
  | DragQuestion.AssignmentContent
  | OpenQuestion.AssignmentContent
  | QuizQuestion.AssignmentContent
  | Wordweb.Content
  | Poll.Content;

export function ImageComponentPropsForPin(
  pin: AssignmentPin<ContentWithImages>,
  type: string,
  isThumb?: boolean
): ImageComponentProps {
  const { item } = pin;
  let imageSize = item.custom.imageSize;

  if (AssignmentPin.isSlide(pin)) {
    const slideContent = pin.item.custom;
    imageSize = type === 'image' ? slideContent.imageSize : slideContent.imageSize2;
  }

  return {
    imageUrl: item[type],
    opacity: item.custom.opacity,
    imageSize,
    type,
    isThumb,
  };
}
