import { Error as FirebaseError, FirebaseAuth, User } from '@firebase/auth-types';
import { logger } from '@lessonup/client-integration';
import { AppError } from '@lessonup/utils';
import _, { isNil } from 'lodash';
import {
  asyncScheduler,
  combineLatest,
  from,
  Observable,
  ObservableInput,
  of,
  ReplaySubject,
  Scheduler,
  SchedulerLike,
} from 'rxjs';
import {
  bufferTime,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  observeOn,
  pairwise,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { calledXTimesWithinTimeFrame, filterNotNil, firstAsPromise, retryWithStrategy } from '../../../utils';
import { MeteorUserIdService } from './MeteorUserIdService';

type Auth = FirebaseAuth;

interface DataProvider {
  customTokenForMeteorUser(): Promise<string>;
}

interface Options {
  allowAnonymousUsers: boolean;
  loggingEnabled?: boolean;
}

interface UserRefs {
  meteorUserId: string | undefined;
  firebaseUser: User | undefined;
}

export class FirebaseAuthService {
  private readonly auth: Auth;
  private readonly dataProvider: DataProvider;
  private readonly meteorUserIdService: MeteorUserIdService;
  private readonly options: Options;

  private readonly firestoreUser: ReplaySubject<User | undefined>;
  private readonly refreshCalls: ReplaySubject<number | undefined> = new ReplaySubject(5);

  private readonly userRefsStream: Observable<UserRefs>;

  private readonly scheduler: SchedulerLike;

  public constructor(
    auth: Auth,
    dataProvider: DataProvider,
    meteorUserIdService: MeteorUserIdService,
    options: Options,
    scheduler: SchedulerLike = asyncScheduler,
    private readonly handleLogout: () => Promise<any>
  ) {
    this.scheduler = scheduler;
    this.auth = auth;
    this.dataProvider = dataProvider;
    this.meteorUserIdService = meteorUserIdService;
    this.options = options;
    this.firestoreUser = new ReplaySubject(1, undefined, scheduler);

    this.userRefsStream = combineLatest([
      this.meteorUserIdService.idAsObservable(),
      this.firestoreUser.asObservable().pipe(distinctUntilChanged(_.isEqual)),
    ]).pipe(
      observeOn(this.scheduler),
      map(([meteorUserId, firebaseUser]): UserRefs => ({ meteorUserId, firebaseUser })),
      shareReplay(1)
    );

    // listen to firebase and store the latest user information
    this.auth.onIdTokenChanged(
      (user) => {
        logger.trace('auth-firebase', `FbAuth ${user && user.uid}`);
        console.info('onAuthStateChanged.id', user && user.uid);
        this.firestoreUser.next((user || undefined) as any);
      },
      (error: FirebaseError) => {
        logger.trace('auth-firebase', `FbAuth error ${_.get(error, 'code')}`);
        logger.error(error.message, { ...error });
      }
    );

    // log the valid users
    this.validUserIdSteam().subscribe((id) => {
      logger.trace('auth-firebase', `Validated userId: ${id}`);
      console.info('ValidatedUserId', id);
    });

    // when the firestore user changes 5 times in 60 sec, we log an error
    this.firestoreUser
      .asObservable()
      .pipe(
        // map to user id
        map((user) => user && user.uid),
        // only emit when the user id changes
        distinctUntilChanged(_.isEqual),
        // buffer for 60 seconds
        bufferTime(60000, this.scheduler),
        // only emit when we had more than 4 emits in 60 sec
        filter((ids) => ids.length >= 4)
      )
      .subscribe(
        // check how many emits we've recieved
        (users) => {
          const error = new AppError('unexpected-data', 'FirebaseAuthService: firebase user ids switched a lot', {
            userUids: users.map(({ uid }) => uid),
          });
          logger.error(error);
          this.handleLogout();
        }
      );

    calledXTimesWithinTimeFrame(this.refreshCalls.asObservable(), 5, 60000).subscribe((called) => {
      const error = new AppError('unexpected-data', 'FirebaseAuthService: too many refresh calls in a minute', {
        called,
      });
      logger.error(error);
      this.handleLogout();
    });

    // listen for user ref changes and validate the state
    this.userRefsStream
      .pipe(
        distinctUntilChanged((a, b) => UserRefs.idsAreEqual(a, b)), // ONLY omit when the ids have changed
        debounceTime(500, this.scheduler), // wait a moment, maybe we get some last minute changes like Meteor server forces the user to logout
        switchMap((refs) => this.validateAuthentication(refs)),
        retryWithStrategy({
          delay: 5000,
          onError: (error) => {
            logger.error(error);
          },
        })
      )
      .subscribe();
  }

  /**
   * @deprecated use userIdService directly
   * should be called from meteor to send its latest known user id
   * */
  public setMeteorUserId(id: string | undefined | null): void {
    this.meteorUserIdService.setMeteorUserId(id);
  }

  private async validateAuthentication(refs: UserRefs): Promise<void> {
    const { meteorUserId, firebaseUser } = refs;

    const firebaseUserId = firebaseUser && firebaseUser.uid;

    logger.trace('auth-firebase', `Start authentication ${meteorUserId} | ${firebaseUserId}`);

    if (this.isUserRefValid(refs)) {
      logger.trace('auth-firebase', `Already logged in correctly`);
      return;
    }

    // otherwise we start a new firebase login attempt

    try {
      if (meteorUserId) {
        logger.trace('auth-firebase', `Custom token login for user ${meteorUserId}`);
        console.info('Firebase signin with custom token');
        const token = await this.dataProvider.customTokenForMeteorUser();
        await this.auth.signInWithCustomToken(token);
      } else if (this.options.allowAnonymousUsers) {
        logger.trace('auth-firebase', `Anonymous login for user ${meteorUserId}`);
        console.info('Firebase signin as anonymous user');
        await this.auth.signInAnonymously();
      }
      logger.trace('auth-firebase', `Signin success`);
    } catch (error) {
      logger.trace('auth-firebase', `Signin failed ${_.get(error, 'code')}`);
      // warn is enough, the validateAuthentication will throw if it keeps failing
      console.warn(`Signin failed: ${_.get(error, 'code')} - ${_.get(error, 'message')}`);
      throw error;
    }
  }

  /** returns a valid user id if found */
  private isUserRefValid(ids: UserRefs): User | undefined {
    const { meteorUserId, firebaseUser } = ids;

    // when we are loggin to meteor AND the firebase id matches
    if (meteorUserId && firebaseUser && meteorUserId === firebaseUser.uid) {
      return firebaseUser;
    }

    // when we have NO meteor user, and the firebase user is already anonymous
    if (!meteorUserId && firebaseUser && firebaseUser.isAnonymous) {
      return firebaseUser;
    }

    return undefined;
  }

  public validUser(): Promise<User | undefined> {
    return firstAsPromise(this.validUserStream());
  }

  public validUserId(): Promise<string | undefined> {
    return firstAsPromise(this.validUserIdSteam());
  }

  /** Stream emiting the valid firebase user */
  public validUserStream(): Observable<User | undefined> {
    return this.userRefsStream.pipe(map((ids) => this.isUserRefValid(ids)));
  }

  /** Stream emiting the valid firebase user id */
  public validUserIdSteam(): Observable<string | undefined> {
    return this.validUserStream().pipe(
      map((user) => user && user.uid),
      distinctUntilChanged(_.isEqual)
    );
  }

  public requestLogoutOnError(error: Error) {
    const code = AppError.code(error);
    logger.trace('auth-firebase', `requestLogoutOnError ${code}`);
    console.warn(`requestLogoutOnError called: ${code}`);
    return this.handleLogout();
  }

  /** only emits when a "non nil user" goes to another "non nil user" or to a "nil user" */
  public validUserIdChangedStream() {
    return this.validUserIdSteam().pipe(
      pairwise(),
      filter(([oldId, newId]) => !_.isNil(oldId) && !_.isEqual(oldId, newId)),
      mapTo(true)
    );
  }

  public waitForValidUser(): Promise<User | undefined> {
    return firstAsPromise(this.validUserStream().pipe(filterNotNil()));
  }

  public async getIdToken(): Promise<string | undefined> {
    const user = await this.validUser();
    if (user) {
      return user.getIdToken();
    }
    return undefined;
  }

  /** forces refresh on the id token and returns it */
  public async refreshIdToken(): Promise<string | undefined> {
    logger.trace('auth-firebase', 'called refreshIdToken');
    this.refreshCalls.next(Date.now());
    try {
      if (!this.auth.currentUser) {
        return undefined;
      }
      return await this.auth.currentUser.getIdToken(true);
    } catch (error) {
      logger.trace('auth-firebase', `Error while refreshing refreshIdToken`);
      throw error;
    }
  }

  public isLoggedInStream(): Observable<boolean> {
    return this.validUserIdSteam().pipe(map((id) => !_.isNil(id)));
  }

  /**
   * when we have a user id. 'generator will be called that needs to return a stream'. If we don't have a user, undefined will be emited
   * when the userId changes (or logs out) the stream will return undefined and closes the previous stream
   */
  public withUserId<T>(
    generator: (id: string) => ObservableInput<T>,
    scheduler: Scheduler | undefined = asyncScheduler
  ): Observable<T | undefined> {
    return this.validUserIdSteam().pipe(switchMap(combineIdWithGeneratorFactory<T>(generator, scheduler)));
  }
  /**
   * Same as above, except that if user is undefined we return a fallback
   */
  public withUserIdAndFallBack<T>(
    fallBack: T,
    generator: (id: string) => ObservableInput<T>,
    scheduler: Scheduler | undefined = asyncScheduler
  ): Observable<T> {
    return this.validUserIdSteam().pipe(
      switchMap((id) => {
        if (_.isNil(id)) {
          return of(fallBack, scheduler);
        } else {
          return from(generator(id), scheduler).pipe(
            tap(undefined, (error) => {
              if (AppError.isFirebaseError(error, 'permission-denied')) {
                logger.trace('auth-firebase', `Error while using userId: ${id}`);
              }
            })
          );
        }
      })
    );
  }
}

namespace UserRefs {
  export function idsAreEqual(a: UserRefs, b: UserRefs): boolean {
    return (
      _.isEqual(a.firebaseUser && a.firebaseUser.uid, b.firebaseUser && b.firebaseUser.uid) &&
      _.isEqual(a.meteorUserId, b.meteorUserId)
    );
  }
}

export function combineIdWithGeneratorFactory<T>(
  generator: (id: string) => ObservableInput<T>,
  scheduler: Scheduler | undefined = asyncScheduler
): (id: string | undefined) => Observable<T | undefined> {
  return (id) => {
    if (isNil(id)) {
      return of(undefined, scheduler);
    } else {
      return from(generator(id), scheduler).pipe(
        tap(undefined, (error) => {
          if (AppError.isFirebaseError(error, 'permission-denied')) {
            logger.trace('auth-firebase', `Error while using userId: ${id}`);
          }
        })
      );
    }
  };
}
