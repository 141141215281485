import React from 'react';
import { LessonPlan } from '../../../shared-core/domain';
import './css/LessonPlanHeader.less';

type Props = {
  child: LessonPlan.Children.Header;
};

const LessonPlanHeader: React.FC<Props> = ({ child }) => {
  const html = child.text;
  return (
    <div className="plan-child lesson-plan-header" data-child-type="header">
      <div className="header">
        <h2 dangerouslySetInnerHTML={{ __html: html }}></h2>
      </div>
    </div>
  );
};

export default LessonPlanHeader;
