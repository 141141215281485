import classNames from 'classnames';
import React, { useState } from 'react';
import '../../../shared-core/ui/components/spinners/RocketSpinner.less';
import { useContextGenerator } from '../../../shared-core/ui/utils/react';

const SpinnerContext = React.createContext<SpinnerContextProps | undefined>(undefined);

export interface SpinnerContextProps {
  open: (withBackground?: boolean) => void;
  close: () => void;
  isShown: boolean;
}

export const SpinnerProvider: React.FunctionComponent<{}> = (props) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [useBackground, setUseBackground] = useState(false);

  function open(withBackground?: boolean) {
    if (withBackground) {
      setUseBackground(true);
    }
    setIsShown(true);
  }

  function close() {
    setIsShown(false);
  }

  return (
    <SpinnerContext.Provider value={{ open, close, isShown }}>
      {props.children}
      {isShown ? (
        <div id="spinner" className={classNames({ 'transparent-background': !useBackground })}>
          <div id="inner-spinner" />
        </div>
      ) : null}
    </SpinnerContext.Provider>
  );
};

export const useSpinnerContext = useContextGenerator(SpinnerContext, 'SpinnerContext');
