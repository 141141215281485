import { Lesson } from '@lessonup/teaching-core';
import {
  differenceInMonths,
  format,
  formatDistanceStrict,
  guesstimateLocaleFromLanguage,
  localeToDateFnsLocale,
} from '@lessonup/utils';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ChannelTitle, Language } from '../../../../shared-core/domain';
import { Explanation, IndexedChannelInfo } from '../../../../shared-core/domain/search';
import { ClusterData } from '../../../../shared-core/domain/search/content/ClusterData';
import { FacetMetaData } from '../../../../shared-core/domain/search/content/FacetMetaData';
import { lessonRoute } from '../../../../shared-core/services/app/searchRoutes';
import LessonPreviewThumbnail from '../../../../shared-core/ui/components/lesson/previewThumbnail/PreviewThumbnail';
import MetaDataTagList from '../../../../shared-core/ui/components/MetaDataTagList';
import ExplanationBox from '../../../../shared-core/ui/components/search/explanation/ExplanationBox';
import { Text } from '../../../../shared-core/ui/components/translations/Text/Text';
import capitalize from '../../../../shared-core/utils/capitalize';
import { htmlDecode } from '../../../utils/htmlDecode';
import ThumbBannerChannel from '../../banner/ThumbBannerChannel';
import './lessonSummaryCard.less';

const TRANSLATION_NS = 'lesson-summary-card';

type LinkTo = (lessenId: string) => string;
export interface Props {
  className?: string;
  userRating?: number | undefined;
  isTip?: boolean;
  lesson: Lesson;
  channel?: IndexedChannelInfo | undefined;
  metaData?: FacetMetaData;
  /* Adds padding and hover effect */
  collapseCount?: ClusterData | undefined;
  inset?: boolean;
  explanation?: Explanation | undefined;
  linkTo?: LinkTo;
  columnClass?: string;
}

const LessonSummaryCard: React.FunctionComponent<Props> = (props) => {
  const { columnClass, inset, isTip, channel, lesson, explanation, collapseCount, linkTo } = props;
  const metaData = props.metaData || FacetMetaData.fromLesson({ lesson });

  const tipLabel = isTip ? (
    <span className="lesson-summary-card__tip-label">
      <Text translateKey="tip-label" options={{ ns: TRANSLATION_NS }} />
    </span>
  ) : (
    false
  );

  const linkUrl = linkTo ? linkTo(lesson._id) : lessonRoute(lesson._id);
  return (
    <div
      className={classNames('base-summary-card', 'lesson-summary-card', { 'lesson-summary-card--no-inset': !inset })}
    >
      <Link to={linkUrl} className={classNames('lesson-summary-card__inner', columnClass)}>
        <div className="lesson-summary-card__top">
          <LessonPreviewThumbnail lesson={lesson} extraElement={tipLabel} />
        </div>
        <div className="lesson-summary-card__bottom">
          <h3 className="lesson-summary-card__title">{htmlDecode(lesson.name) || ''}</h3>
          {lesson.pinCount && <MetaInfo creationDate={lesson.creationDate} count={lesson.pinCount} channel={channel} />}
          <MetaDataTagList className="lesson-summary-card__tags" metaData={metaData} size="small" />
        </div>
      </Link>
      <ThumbBannerChannel
        channel={channel}
        locale={metaData.country[0]}
        channelPublic={channel && channel.channelPublic}
      />
      {explanation && (
        <ExplanationBox explanation={explanation} collapseCount={collapseCount && collapseCount.size} id={lesson._id} />
      )}
    </div>
  );
};

LessonSummaryCard.defaultProps = {
  inset: true,
};

type MetaInfoProps = { creationDate: Date; count: number; channel: IndexedChannelInfo | undefined };

const MetaInfo: React.FC<MetaInfoProps> = ({ creationDate: _creationDate, count, channel }) => {
  const { t, i18n } = useTranslation(TRANSLATION_NS);
  const language = i18n.language as Language.Key;
  const creationDate = new Date(_creationDate);

  const channelTitle =
    channel && channel.channelPublic && channel.title && ChannelTitle.localizeTitle(channel.title, language);

  const slideCountWithChannel = (
    <>
      {t('slideCountPrefix')}{' '}
      <strong>
        {count} {t('slide', { count })}
      </strong>
      {channelTitle && (
        <>
          {' '}
          {t('channelPrefix')} <strong>{channelTitle}</strong>
        </>
      )}
    </>
  );

  return (
    <div className="lesson-summary-card__meta-info">
      <time className="lesson-summary-card__creation-date" dateTime={creationDate.toISOString()}>
        {lessonCreationDate(creationDate, language)}
      </time>
      {' - '}
      {slideCountWithChannel}
    </div>
  );
};

function lessonCreationDate(creationDate: Date, language: Language.Key): string {
  const now = new Date();
  const isMoreThanMonthAgo = differenceInMonths(now, creationDate) >= 1;
  const locale = guesstimateLocaleFromLanguage(language);

  return isMoreThanMonthAgo
    ? capitalize(format(creationDate, 'MMMM yyyy', { locale: localeToDateFnsLocale(locale) }))
    : formatDistanceStrict(creationDate, now, { addSuffix: true, locale: localeToDateFnsLocale(locale) });
}

export default LessonSummaryCard;
