import classNames from 'classnames';
import React from 'react';

interface Props {
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  className?: string;
}

const DefaultForm: React.FunctionComponent<Props> = (props) => {
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (props.handleSubmit) props.handleSubmit(event);
  }

  return (
    <form className={classNames('default-form', props.className)} onSubmit={handleSubmit}>
      {props.children}
    </form>
  );
};
export default DefaultForm;
