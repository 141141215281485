import { logger } from '@lessonup/client-integration';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LessonPlan } from '../../../shared-core/domain';
import { ImageUtils } from '../../../shared-core/utils/ImageUtils';
import { DownloadPlanAttachment } from './components/DownloadPlanAttachment';
import './css/LessonPlanAttachment.less';

type Props = {
  child: LessonPlan.Children.Attachment;
  planId: string;
};

const LessonPlanAttachment: React.FC<Props> = ({ child, planId }) => {
  const { t } = useTranslation('lessonPlanAttachment');
  let attachmentTypeClass = 'type-' + child.attachmentType;
  let divStyle;

  if (!planId) {
    // This should never happen. A planId should only be undefined for channel start / about pages.
    // Those don't contain attachments.
    logger.error('PlanId is not defined in a lesson plan attachment');
  }

  if (child.image) {
    attachmentTypeClass += ' has-image';
    divStyle = { backgroundImage: 'url(' + ImageUtils.checkImageUrl(child.image) + ')' };
  }

  const divClasses = 'plan-attachment ' + attachmentTypeClass;

  return (
    <div className="lesson-plan-attachment">
      <div className={divClasses}>
        {child.image ? <div className="visual" style={divStyle}></div> : null}
        <DownloadPlanAttachment planId={planId} child={child}></DownloadPlanAttachment>
        <div className="info">
          <div className="type">{t(child.attachmentType)}</div>
        </div>
      </div>
    </div>
  );
};

export default LessonPlanAttachment;
