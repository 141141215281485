import React from 'react';
import './PinThumbBottomBar.less';

interface Props {
  scheme?: string;
}

const PinThumbBottomBar: React.FunctionComponent<Props> = (props) => {
  return <div className="pin-thumb-bottom-bar">{props.children}</div>;
};

export default PinThumbBottomBar;
