import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-core/ui/components/Button';
import DefaultForm from '../../../shared-core/ui/components/inputElements/DefaultForm';

interface ValidateEmailFormProps {
  onSubmit: (email: string) => void;
  className?: string;
  email?: string;
}

function ValidateEmailForm(props: ValidateEmailFormProps) {
  const { t } = useTranslation('login');
  const [email, setEmail] = useState<string | undefined>(props.email);

  const handleSubmit = async () => {
    if (!email) return;
    await props.onSubmit(email);
  };

  function emailChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  return (
    <DefaultForm handleSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Email"
        autoComplete="email"
        value={email}
        onChange={emailChangeHandler}
        required
      />
      <Button theme="light" type="submit">
        {t('submit')}
      </Button>
    </DefaultForm>
  );
}

export default ValidateEmailForm;
