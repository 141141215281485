import classNames from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';
import Heading from '../Heading';
import CloseCrossSvg from '../svgIcons/CloseCrossSvg';
import './modal.less';

type AnimationClass = 'slide-from-bottom';

interface Props {
  isOpen: boolean;
  title: string;
  subtitle?: string;
  onRequestClose: () => void;
  animationOnOpen?: AnimationClass;
  className?: string;
  width?: 'small' | 'medium' | 'large';
  theme?: 'red' | 'default';
  shouldCloseOnOverlayClick?: boolean;
}

export class Modal extends React.Component<Props> {
  public render() {
    const classes = ['modal'];
    const { animationOnOpen, width, className, theme, subtitle, title, shouldCloseOnOverlayClick } = this.props;
    if (className) classes.push(className);
    if (animationOnOpen) classes.push(animationOnOpen);
    if (width) classes.push(`modal--${width}`);

    const teacherModalAnimationClass = 'transition-slide-in-perc-2';

    return (
      <ReactModal
        className={classNames(classes, teacherModalAnimationClass, {
          [`theme-${theme}`]: !!theme,
          'increase-title-size': !!subtitle,
        })}
        overlayClassName="modal__glasspane"
        bodyOpenClassName="legacy-modal-open"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        contentLabel={this.props.title}
      >
        <div className="modal__title">
          <div className="modal-title-content">
            <Heading as="h3" className="modal__title_text">
              {title}
            </Heading>
            {subtitle && <span>{subtitle}</span>}
          </div>
          <CloseCrossSvg onClick={this.props.onRequestClose} className="modal__title_close" />
        </div>
        <div className="modal__content">{this.props.children}</div>
      </ReactModal>
    );
  }
}
