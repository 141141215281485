import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  translateKey: string;
  options?: string | i18next.TOptions<i18next.StringMap> | undefined;
}

export const Text = ({ translateKey, options }: Props) => {
  const { t } = useTranslation();
  // Temp fix, as Typescript will complain about the result not being a react element otherwise.
  return <>{t(translateKey, options)}</>;
};
