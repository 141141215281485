import React from 'react';
import { ContentExplorer } from '../../../shared-core/domain';
import Heading from '../../../shared-core/ui/components/Heading';
import { htmlDecode } from '../../utils/htmlDecode';
import './css/FolderInfo.less';

interface Props {
  name?: string;
  description?: string;
}
const FolderInfo: React.FC<Props> = ({ name, description, children }) => {
  return (
    <div className="folder-info">
      <Heading size="h3" as="h3" className="title">
        {htmlDecode(name)}
      </Heading>
      <div className="description">{ContentExplorer.truncateFolderDescription(description)}</div>
      {children ? <div className="actions">{children}</div> : null}
    </div>
  );
};

export default FolderInfo;
