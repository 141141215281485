import { Fonts, Lesson } from '@lessonup/teaching-core';
import _, { compact, isEmpty } from 'lodash';
import { Curriculum, CurriculumName } from '../curricula/Curricula';
import { Language, LanguageKey, LanguageSingleton } from '../language';
import { LessonPlan } from '../lessonPlan';
import { FolderRenderParams, ItemCounts } from '../newExplorers';
import { Product } from '../products';
import { Bucket, ExtendedChannelInfo, IndexedChannelInfo } from '../search';

export interface ChannelTitle {
  en?: string;
  nl?: string;
}

export namespace ChannelTitle {
  export function localizeTitle(title: ChannelTitle, preferedLocale = LanguageSingleton.get()) {
    if (title[preferedLocale]) {
      return title[preferedLocale];
    }

    return compact(Object.values(title))[0];
  }
}
interface ChannelContentPage {
  nl?: LessonPlan.Children;
  en?: LessonPlan.Children;
}

export type ChannelId = string;

// start/ about
export interface Channel {
  _id: ChannelId;
  name: string;
  /** @deprecated should use organizations array instead */
  organization: string;
  organizations: string[];
  covercolor: string | undefined;
  icon: string | undefined;
  cover: string | undefined;
  website?: string | undefined;
  sharedExplorers?: string[] | string;
  coverFocus?: number;
  showSeriesButtonDepth?: number;
  useRootAsTabs?: boolean;
  covertextdark?: boolean;
  showSeries?: boolean;
  showSearch?: 'published' | 'organization';
  curricula?: string[];
  title: ChannelTitle;
  subtitle: ChannelTitle;
  requireFollow?: boolean;
  requestVoucherForm?: boolean;
  analytics?: {
    code: string;
    enabled: boolean;
  };
  meta?: {
    publicationStatus: Channel.PublicationStatus;
    [key: string]: any;
  };
  styling?: Channel.Styling;
  start?: ChannelContentPage;
  about?: ChannelContentPage;
  contentRights?: Channel.Rights;
  /* In LU search we filter out lessons created by users with LessonUp and Q42 emails
  This property overrides that filter so we can show LU channel lessons */
  ignoreLessonUpSearchFilter?: boolean;
  isContentChannel?: boolean; // deprecated?
  schoolId?: string[]; // deprecated?
}

export interface ChannelTab {
  path?: string;
  name: string;
  id: string;
}

export namespace Channel {
  export type Rights = 'none' | 'givesProFeatures';
  export function givesRights(rights: Rights | undefined) {
    return rights && rights !== 'none';
  }
  export type PublicationStatus = Record<LanguageKey, { public: true; spotlight: number } | { public: false }>;

  export type ChannelLike = Channel | IndexedChannelInfo.PartialChanExtended;

  export interface Styling {
    font: Fonts.Key;
  }

  export function getOrganizationIds(channel: Channel): string[] {
    return [channel.organization, ...channel.organizations];
  }

  export function publishedChannelsForLanguage(channels: ChannelLike[], language: LanguageKey): ChannelLike[] {
    return channels.filter((channel) => {
      const publicStatus = channel.meta?.publicationStatus && channel.meta?.publicationStatus[language];
      return publicStatus?.public && typeof publicStatus.spotlight === 'number';
    });
  }

  export function spotlightForLanguageAndChannel(channel: Channel, language: LanguageKey): number | undefined {
    const publicStatus = channel.meta?.publicationStatus && channel.meta?.publicationStatus[language];
    return publicStatus?.public ? publicStatus.spotlight : undefined;
  }

  export function sortChannelsForLanguage(channels: ChannelLike[], language: LanguageKey): ChannelLike[] {
    return _.sortBy(channels, (channel) => {
      const publicStatus = channel.meta?.publicationStatus[language];
      return publicStatus?.public && typeof publicStatus.spotlight === 'number';
    });
  }

  export function followRequired(channel: ChannelLike | IndexedChannelInfo): boolean {
    return !!channel?.requireFollow;
  }

  export function requiresLeadForm(channel: ChannelLike | IndexedChannelInfo): boolean {
    return followRequired(channel);
  }

  export function contentPage(
    channel: Channel,
    contentPageKey: ContentPageKey,
    language: Language.Key | undefined
  ): LessonPlan.Children | undefined {
    const content = channel[contentPageKey];
    if (!content) return;
    if (language && content[language]) {
      return content[language];
    }
    return _.first(Object.values(content));
  }

  export function title(channel: Channel, language = LanguageSingleton.get()): string {
    return channel.title[language] || Object.values(channel.title)[0] || channel.name;
  }

  export function subtitle(channel: Channel, language = LanguageSingleton.get()): string {
    return channel.subtitle[language] || Object.values(channel.subtitle)[0] || '';
  }

  export const root = 'root' as const;

  export type ContentPageKey = 'start' | 'about';
  export type FolderPageKey = 'series';
  export type CurriculaPageKey = 'curricula';
  export type Page = FolderPageKey | ContentPageKey | CurriculaPageKey | 'search' | 'lesson' | 'plan';

  export function isPage(str: string | undefined | null): str is Page {
    const pages: Page[] = ['start', 'about', 'series', 'search'];
    return !!str && pages.includes(str as Page);
  }

  export function defaultPage(channel: Channel, language: LanguageKey): Page {
    const hasStartPage = !isEmpty(channel?.start?.[language]);

    if (hasStartPage) {
      return 'start';
    }

    return channel.showSeries ? 'series' : 'search';
  }

  export function isContentPageKey(str: string | undefined): str is ContentPageKey {
    return !!str && ['start', 'about'].includes(str);
  }

  export function channelTrackerIfEnabled(
    channelInfo?: ExtendedChannelInfo | IndexedChannelInfo | Channel
  ): string | undefined {
    return channelInfo?.analytics?.enabled ? channelInfo.analytics.code : undefined;
  }
  interface ChannelBaseContent {
    lessons: Lesson[];
    lessonPlans: LessonPlan[];
  }

  export interface ContentPageData extends ChannelBaseContent {
    page: ContentPageKey;
    folders: FolderRenderParams[];
    language: Language.Key;
  }

  export interface FolderPageData extends ChannelBaseContent {
    selectionId: string | undefined;
    products: Product[]; // the products in child folders
    error: undefined | 403 | 404;
    itemCounts: ItemCounts;
  }

  export interface CurriculaPageData {
    names: CurriculumName[];
    selectionId: string | undefined;
    curriculum: Curriculum | undefined;
    counts: Bucket[];
  }
}

export function isPublishedChannel(channel: Channel): boolean {
  return channel.showSearch === 'published';
}

export function isDefaultChannelTab(tab: ChannelTab): boolean {
  return ['start', 'about', 'search', 'curricula', 'series'].includes(tab.id);
}
