import classNames from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';
import Heading from '../Heading';
import CloseCrossSvg from '../svgIcons/CloseCrossSvg';
import './modal.less';
import './NewModal.less';

type AnimationClass = 'slide-from-bottom';

interface Props {
  isOpen: boolean;
  title: string | JSX.Element;
  subtitle?: string;
  ariaLabel: string;
  onRequestClose?: () => void;
  cantClose?: boolean;
  animationOnOpen?: AnimationClass;
  className?: string;
  width?: 'small' | 'medium' | 'large';
  theme?: 'red' | 'default';
}

export class NewModal extends React.Component<Props> {
  public render() {
    const classes = ['modal', 'new-modal'];
    const { animationOnOpen, width, className, theme, subtitle, title, cantClose } = this.props;
    if (className) classes.push(className);
    if (animationOnOpen) classes.push(animationOnOpen);
    if (width) classes.push(`modal--${width}`);

    const teacherModalAnimationClass = 'transition-slide-in-perc-2';

    function titleComponent() {
      if (!title) return;

      return typeof title === 'string' ? (
        <Heading as="h3" className="modal__title_text">
          {title}
        </Heading>
      ) : (
        title
      );
    }

    return (
      <ReactModal
        className={classNames(classes, teacherModalAnimationClass, {
          [`theme-${theme}`]: !!theme,
        })}
        overlayClassName="modal-glass"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        shouldCloseOnEsc={!cantClose}
        contentLabel={this.props.ariaLabel}
      >
        <div className="modal-title">
          <div className="modal-title-content">
            {titleComponent()}
            {subtitle && <span>{subtitle}</span>}
          </div>
          {!cantClose && <CloseCrossSvg onClick={this.props.onRequestClose} className="modal__title_close" />}
        </div>
        <div className="modal-content">{this.props.children}</div>
      </ReactModal>
    );
  }
}
