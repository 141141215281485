import React from 'react';

import { AssignmentPin } from '@lessonup/teaching-core';
import ImageComponent, { ImageComponentProps, ImageComponentPropsForPin } from '../../images/ImageComponent';
import PinContainer from '../../pin/PinContainer';
import { MapPinProps } from '../../pin/PinView';
import ViewComponents from '../../viewComponents/ViewComponents';
import Text from './Text';

interface Props extends MapPinProps {
  pin: AssignmentPin.Slide;
  isThumb?: boolean;
}

interface State {
  slideColor?: number;
  colorOverride: boolean;
}

export default class SlideView extends React.Component<Props, State> {
  public constructor(props) {
    super(props);

    this.state = {
      slideColor: this.props.pin.item.custom.color,
      colorOverride: false,
    };

    this.editSlideColor = this.editSlideColor.bind(this);
  }

  private imageProps = (type: string): ImageComponentProps => {
    const { pin, isThumb } = this.props;
    return ImageComponentPropsForPin(pin, type, isThumb);
  };

  private editSlideColor(color: number): void {
    this.setState({
      slideColor: color,
      colorOverride: true,
    });
  }

  public render() {
    const { item } = this.props.pin;
    return (
      <PinContainer
        pin={this.props.pin}
        pinColorOverride={this.state.colorOverride ? this.state.slideColor : undefined}
      >
        <ImageComponent {...this.imageProps('image')} />
        <ImageComponent {...this.imageProps('image2')} number2={true} />
        <Text type={'text1'} item={item} />
        <Text type={'text2'} item={item} />
        <ViewComponents
          components={item.components}
          isThumb={this.props.isThumb}
          editSlideColor={this.editSlideColor}
          expandOnlyOne={item.settings?.closeOtherHotspots}
        />
      </PinContainer>
    );
  }
}
