import { AppError } from '@lessonup/utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { logger } from '@lessonup/client-integration';
import ChannelAnalytics from '../../../shared-core/client/services/tracking/ChannelAnalytics';
import { Channel, LanguageSingleton } from '../../../shared-core/domain';
import { channelRoute, lessonSearchRoute } from '../../../shared-core/services/app/searchRoutes';
import useCallbackIfParamsChanged from '../../../shared-core/ui/utils/hooks/useCallbackIfParamsChanged';
import { fetchedChannelAction, fetchedChannelErrorAction, fetchingChannelAction } from '../../redux/actions/actions';
import { channelStore, isUserSignedIn } from '../../redux/selectors';
import { ChannelAppService } from '../../services/appServices/ChannelAppService';
import ErrorPageView from '../error';
import ChannelPageView, { ActiveChannelTab } from './ChannelPageView';

type Props = RouteComponentProps & {
  name: string;
  channelService: ChannelAppService;
  channelPage?: string;
  selectionId?: string;
  subSelectionId?: string;
};

const ChannelPage: React.FC<Props> = ({ name, channelService, channelPage, selectionId, subSelectionId, history }) => {
  const store = useSelector(channelStore());
  const userIsLoggedIn = useSelector(isUserSignedIn());
  const { channelDetails, error, slug } = store;
  const dispatch = useDispatch();

  // this one is only used for the initial data, so we only need to check the slug
  useCallbackIfParamsChanged({
    initial: slug,
    params: name,
    callback: async (slug: string) => {
      const page = Channel.isPage(channelPage) ? channelPage : undefined;
      try {
        dispatch(fetchingChannelAction(slug));
        const { details } = await channelService.channelDetailsByName(slug, LanguageSingleton.get(), page, selectionId);
        dispatch(fetchedChannelAction(details));
      } catch (error) {
        if (AppError.isError(error, 'not-found')) {
          dispatch(fetchedChannelErrorAction(404, slug));
        } else {
          logger.error(error);
          dispatch(fetchedChannelErrorAction(500, slug));
        }
      }
    },
  });

  // TODO: goto channels if route is finished
  const backUrl = lessonSearchRoute();

  if (error) {
    return <ErrorPageView type="channel" backUrl={backUrl} code={error} />;
  }

  if (!channelDetails || slug !== name) return null;

  const changeActiveTab = ({ channelPage, subSelectionId, selectionId }: ActiveChannelTab, replace = false) => {
    const path = channelRoute({
      channelSlug: slug,
      channelPage,
      selectionId,
      subSelectionId,
    });
    replace ? history.replace(path) : history.push(path);
  };

  const page = channelPage || Channel.defaultPage(channelDetails.channel, LanguageSingleton.get());

  return (
    <>
      <ChannelPageView
        details={channelDetails}
        userIsLoggedIn={userIsLoggedIn}
        activeTab={{ channelPage: page, selectionId, subSelectionId }}
        channelService={channelService}
        changeTab={changeActiveTab}
      />
      <ChannelAnalytics channelSlug={channelDetails.channel.name} channelId={channelDetails.channel._id} />
    </>
  );
};

export default ChannelPage;
