import React from 'react';
import { ImageUtils } from '../../../utils/ImageUtils';
import { BackGroundColor, ForeGroundColor } from '../../definitions/colors';
import './PromoButton.less';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  bgColor?: BackGroundColor;
  fgColor?: ForeGroundColor;
  imageUrl?: string;
  className?: string;
}

export default class PromoButton extends React.Component<Props, React.ButtonHTMLAttributes<HTMLButtonElement>> {
  public render() {
    const { bgColor, fgColor, imageUrl, className, ...buttonProps } = this.props;
    const img = imageUrl && (
      <span className="icon-wrapper">
        <span className="button-icon" style={{ backgroundImage: ImageUtils.urlString(imageUrl) }}>
          {' '}
        </span>
      </span>
    );
    const hasIcon = imageUrl ? `has-icon` : '';
    const bgColorClass = bgColor ? `bg-${bgColor}` : '';
    const fgColorClass = fgColor ? `fg-${fgColor}` : '';
    const newClassName = `promo-button ${bgColorClass} ${fgColorClass} ${hasIcon} ${className}`;
    return (
      <button className={newClassName} {...buttonProps}>
        {img}
        {this.props.children}
      </button>
    );
  }
}
