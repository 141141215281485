import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ChannelTitle } from '../../../../shared-core/domain';
import capitalize from '../../../../shared-core/utils/capitalize';
import transformServingImage from '../../../../shared-core/utils/transformServingImage';
import pageTitle from '../../../utils/pageTitle';

const TRANSLATION_KEY = 'channelPageMeta';

interface Props {
  channelTitle: ChannelTitle;
  channelIcon: string | undefined;
  page: string;
  subPage: string;
}
const ChannelPageMeta: React.FC<Props> = ({ channelTitle, page, subPage, channelIcon }) => {
  const { t } = useTranslation(TRANSLATION_KEY);
  const localizedTitle = ChannelTitle.localizeTitle(channelTitle);

  const tabsToExcludeForTitle = ['search', 'plan'];
  const description = localizedTitle && t('description', { channelTitle: localizedTitle });
  const image = imageForPage({ subPage, channelIcon });
  const title =
    localizedTitle && !tabsToExcludeForTitle.includes(page)
      ? pageTitle(capitalize(t('channelTitle', { channelTitle: localizedTitle })))
      : null;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      <meta name="robots" content="index" />
      {description && <meta name="description" content={description} />}
      {image && <meta name="og:image" content={image} />}
    </Helmet>
  );
};

interface ImageProps {
  subPage: string;
  channelIcon: string | undefined;
}
function imageForPage({ subPage, channelIcon }: ImageProps) {
  const subPagesToExclude = ['lesson', 'plan'];
  if (subPagesToExclude.includes(subPage) || !channelIcon) {
    return null;
  }

  return transformServingImage(channelIcon, {});
}
export default ChannelPageMeta;
