import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { Country, Language } from '../../shared-core/domain';
import { EnvConfig } from '../../shared-core/services';
import { lessonSearchRoute } from '../../shared-core/services/app/searchRoutes';
import { useAppServices } from '../components/appServices/AppServicesContext';

interface Locale {
  language: Language.Key;
  country?: string;
}

interface Props {
  countryCode?: string;
}

export const HrefLangTags: React.FC<Props> = ({ countryCode }) => {
  const { config } = useAppServices();
  const { pathname } = useLocation();
  const urlLang = Language.languageFromPath(pathname);
  const locales: Locale[] = urlLang ? localesForAllLanguages(urlLang, countryCode) : [];

  return (
    <Helmet>
      {localesToTags(config, pathname, locales)}
      <link
        data-rh="true"
        rel="alternate"
        hrefLang="x-default"
        href={config.appUrl('search', lessonSearchRoute({ language: Language.defaultLanguage }))}
      />
    </Helmet>
  );
};

function localeToString({ language, country }: Locale): string {
  const validatedCountry = Country.isCountryCode(country) && country;

  return validatedCountry ? `${language}-${validatedCountry}` : language;
}

function localesForAllLanguages(currentLanguage: Language.Key, country?: string): Locale[] {
  return Language.mainKeys
    .filter((language) => language !== currentLanguage)
    .map((language) =>
      country
        ? {
            language,
            country,
          }
        : {
            language,
          }
    );
}

function localesToTags(config: EnvConfig, pathname: string, locales: Locale[]) {
  return locales.map((locale) => (
    <link
      rel="alternate"
      href={config.appUrl('search', Language.changePathLanguage(pathname, locale.language))}
      hrefLang={localeToString(locale)}
      key={localeToString(locale)}
    />
  ));
}
