import classNames from 'classnames';
import React from 'react';
import './Tags.less';

type TagsProps = {
  className?: string;
};

type Size = 'large' | 'small';

export type TagProps = {
  children: string;
  title?: string;
  color?: 'cyan' | 'yellow' | 'red' | 'silver';
  size?: Size;
  className?: string;
};

export const Tags: React.FunctionComponent<TagsProps> = ({ className, children }) => {
  return <div className={classNames('ui-tags', className)}>{children}</div>;
};

export const Tag = ({ children, title, color, size, className }: TagProps) => {
  return (
    <span
      title={title || children}
      className={classNames('ui-tag', `ui-tag--${size}`, `bg-${color || 'cyan'}`, className)}
    >
      {children}
    </span>
  );
};

Tag.defaultProps = {
  color: 'cyan',
  size: 'small',
};
