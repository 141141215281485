import { logger } from '@lessonup/client-integration';
import { DemoLesson } from '@lessonup/teaching-core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DomainRuleSuggestion } from '../../../../shared-core/domain';
import useDeepCompareEffectNoCheck from '../../../../shared-core/ui/utils/hooks/useDeepCompareEffectNoCheck';
import { useContextGenerator } from '../../../../shared-core/ui/utils/react';
import { useAppServices } from '../../../components/appServices/AppServicesContext';
import { loggedInUser } from '../../../redux/selectors';

type PagesLinkingToChildPicker = 'school_picker' | 'email_domain_suggestion';
export interface Context {
  domainRuleSuggestions: DomainRuleSuggestion[];
  demoLesson?: DemoLesson;
  pickChildSource: PagesLinkingToChildPicker | undefined;
  setPickChildSource: (page: PagesLinkingToChildPicker) => void;
  email?: string;
  setEmail: (email?: string | undefined) => void;
}

const RegistrationContext = React.createContext<Context | undefined>(undefined);

export const RegistrationContextProvider: React.FunctionComponent = (props) => {
  const [domainRuleSuggestions, setDomainRuleSuggestions] = useState<DomainRuleSuggestion[]>([]);
  const [email, setEmail] = useState<string | undefined>();
  const [pickChildSource, setPickChildSource] = useState<PagesLinkingToChildPicker | undefined>();
  const user = useSelector(loggedInUser());
  const appServices = useAppServices();

  useDeepCompareEffectNoCheck(() => {
    if (!user) return;
    fetchSuggestions();
  }, [user]);

  async function fetchSuggestions() {
    try {
      const suggestions = await appServices.domainRules.getSuggestions();
      setDomainRuleSuggestions(suggestions);
    } catch (error) {
      logger.error(error);
    }
  }

  return (
    <RegistrationContext.Provider
      value={{ domainRuleSuggestions, demoLesson: undefined, pickChildSource, setPickChildSource, email, setEmail }}
    >
      {props.children}
    </RegistrationContext.Provider>
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useRegistrationContext = useContextGenerator(RegistrationContext, 'RegistrationContext');
