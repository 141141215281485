import React from 'react';
import { Link } from 'react-router-dom';

const AnchorOrLink: React.FC<{
  url: string;
  className?: string;
  isAnchor?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}> = (props) => {
  const { url, children, isAnchor, onClick, ...linkProps } = props;
  if (isAnchor) {
    return (
      <a href={url} {...linkProps} onClick={onClick}>
        {children}
      </a>
    );
  }

  return (
    <Link to={url} {...linkProps} onClick={onClick}>
      {children}
    </Link>
  );
};

export default AnchorOrLink;
