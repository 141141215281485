import {
  anyToEditDataId,
  EduSystem,
  formatLevel,
  formatSchoolType,
  formatYears,
  PinUtils,
} from '@lessonup/teaching-core';
import i18next from 'i18next';
import _ from 'lodash';
import {
  Bucket,
  FacetKey,
  FacetResult,
  Language,
  LanguageKey,
  SingleValueSelectedFacet,
  TermAggregation,
} from '../domain';
import { Country } from '../domain/country/Country';
import { PinViewUtils } from './PinViewUtils';

export type Option = { label: string; value: string; count: number | undefined };

export function getOptions(
  facetKey: FacetKey,
  schoolType: string | undefined,
  eduSystem: any,
  options: Option[],
  translate?: i18next.TFunction,
  removeCounts?: boolean
): Option[] {
  let result = [...options];

  if (facetKey === 'schoolType') {
    result = eduSystem
      .getSchoolTypes()
      .map((schoolType) => options.find((option) => option.value === schoolType.id))
      .filter(Boolean);
  }

  if (facetKey === 'levels' && schoolType) {
    const levelOrder = eduSystem.getSystemData({ schoolType, fieldName: 'levels' });

    if (!levelOrder || !levelOrder.options) return options;

    result = levelOrder.options
      .map((level) => {
        return options.find((option) => option.value.toString() === level.id.toString());
      })
      .filter(Boolean);
  }

  if (facetKey === 'years') {
    result = _.sortBy(options, (option) => parseInt(option.value, 10));
  }

  if (facetKey === 'type') {
    result = options.map((option) => {
      return {
        ...option,
        label: translate ? translate(option.label, { ns: 'slides' }) : option.label,
      };
    });
  }

  if (facetKey === 'source') {
    result = options.map((option) => {
      return {
        ...option,
        label: translate ? translate(option.label, { ns: 'sources' }) : option.label,
      };
    });
  }

  if (removeCounts) {
    result = result.map((val) => ({ ...val, count: undefined }));
  }

  return result;
}

export function createOption(item: Bucket, labelFormatter: (string) => string | undefined): Option | undefined {
  const label = item.label || labelFormatter(item.key);
  if (!label) return undefined;
  return {
    label,
    value: item.key.toString(),
    count: item.doc_count,
  };
}

export function createLabelFormatter(
  facetKey: FacetKey,
  schoolType: string | undefined,
  eduSystem: EduSystem.System,
  country: string,
  language: LanguageKey
): (key: string) => string | undefined {
  return (itemKey: string): string | undefined => {
    if (facetKey === 'schoolType') {
      return formatSchoolType(eduSystem, itemKey);
    }

    if (facetKey === 'levels' && schoolType) {
      return formatLevel(eduSystem, schoolType, itemKey);
    }

    if (facetKey === 'years' && schoolType) {
      return formatYears(eduSystem, schoolType, parseInt(itemKey, 10));
    }

    if (facetKey === 'country') {
      return Country.lookupCountryByAbbreviation(itemKey, language === 'nl');
    }

    if (facetKey === 'contentType') {
      if (PinUtils.isPinType(itemKey)) {
        return PinViewUtils.labelForPinType(itemKey, Language.defaultLanguageForCountry(country));
      }
    }
    return itemKey;
  };
}

export function formatFacetPlaceholder(
  key: FacetKey,
  eduSystem: EduSystem.System,
  schoolType: string | undefined,
  t: i18next.TFunction
): string {
  const fallback = t('select', { facet: t(`${key}`) });
  const fieldName = anyToEditDataId(key);

  if (!fieldName) return fallback;

  const data = eduSystem.getSystemDataField({ schoolType, fieldName });

  if (!data?.reference) return fallback;

  return t('select', { facet: t(`${data.reference}`) });
}

export function translatedFacetPlaceholder(facet: SingleValueSelectedFacet, t: i18next.TFunction) {
  if (facet.key !== 'source') {
    return facet.value;
  }

  return t(`sources:${facet.value}`);
}

export function validOptionsForFacetResult({
  country,
  schoolType,
  result,
  language,
}: {
  country: string;
  schoolType: string | undefined;
  result: FacetResult;
  language: LanguageKey;
}): Option[] {
  //reuse facet logic to get the next valid steps
  const system = EduSystem.get(country);

  const formatter = createLabelFormatter(result.key, schoolType, system, country, language);
  const list = _.compact(result.items.map((i) => createOption(i, formatter)));

  return getOptions(result.key, schoolType, system, list);
}

export function basicFacetResult(key: FacetKey, agg: TermAggregation): FacetResult {
  return {
    key,
    selected: undefined,
    items: agg.buckets.filter((item) => !_.isEmpty(item.key) || _.isNumber(item.key)),
  };
}

export function removeAllNonSelectedFromList(facet: FacetResult): FacetResult {
  if (!facet.selected) return facet;
  const item = facet.items.find((i) => i.key === facet.selected?.value);
  return {
    ...facet,
    items: _.compact([item]),
  };
}
