import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import PassiveTooltip from '../tooltip/PassiveTooltip';
import './tagList.less';

export interface Tag {
  key?: string;
  label: string;
  className?: string;
}

export interface TagListProps {
  tags: Tag[];
  className?: string;
  theme?: string;
  onClick?: (label: string) => void;
  passiveTooltip?: string;
}

export interface TagListState {}

class TagList<T> extends React.Component<TagListProps, TagListState> {
  public constructor(props: TagListProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  public handleClick(label) {
    this.props.onClick && this.props.onClick(label);
  }

  public render() {
    return (
      <div className={`taglist ${this.props.className || ''} ${!!this.props.onClick ? 'taglist--clickable' : ''}`}>
        {this.props.tags.map((tag, index) => (
          <TagListElement
            key={index}
            tag={tag}
            onClick={this.handleClick}
            clickable={Boolean(this.props.onClick)}
            passiveTooltip={this.props.passiveTooltip}
            className={tag.className}
          />
        ))}
        {this.props.children}
      </div>
    );
  }
}

export default TagList;

export interface TagListElementProps {
  tag: Tag;
  onClick?: (key: string) => void;
  clickable?: boolean;
  passiveTooltip?: string;
  className?: string;
}

export interface TagListElementState {}

class TagListElement extends React.Component<TagListElementProps, TagListElementState> {
  public constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  public handleClick() {
    const { key, label } = this.props.tag;
    this.props.onClick && this.props.onClick(key || label);
  }

  public handleKeyPress(event) {
    if (!this.props.onClick) {
      return;
    }

    if (event.which === 13 || event.which === 32) {
      event.preventDefault();
      this.props.onClick(this.props.tag.label);
    }
  }

  public render() {
    const optionalProps: HTMLAttributes<HTMLDivElement> = this.props.clickable ? { tabIndex: 0, role: 'button' } : {};

    return (
      <div
        className={classNames(
          'taglist__list-element',
          'rel',
          {
            tipped: Boolean(this.props.passiveTooltip),
            rel: Boolean(this.props.passiveTooltip),
          },
          this.props.className
        )}
        onClick={this.handleClick}
        onKeyPress={this.handleKeyPress}
        {...optionalProps}
      >
        {this.props.tag.label}
        {this.props.passiveTooltip && (
          <PassiveTooltip direction="center" customClassNames="tag-element__tooltip" hasInteractiveContent={true}>
            <span>{this.props.passiveTooltip}</span>
          </PassiveTooltip>
        )}
      </div>
    );
  }
}
