import classNames from 'classnames';
import React from 'react';
import './Heading.less';

type Size = 'h0' | 'h1' | 'h2' | 'h3' | 'h4';

type Props = {
  as?: React.ElementType;
  size?: Size;
  className?: string;
  style?: React.CSSProperties;
  text?: string;
};

const Heading: React.FC<Props> = ({ as: T = 'h2', children, className, size = 'h1', style, text }) => {
  return (
    <T className={classNames('ui-heading', `ui-heading--${size}`, className)} style={style}>
      {text || children}
    </T>
  );
};

export default Heading;
