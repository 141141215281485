import React from 'react';
import { LessonPlan } from '../../../shared-core/domain';
import { ImageUtils } from '../../../shared-core/utils/ImageUtils';
import './css/LessonPlanImage.less';

type Props = {
  child: LessonPlan.Children.Image;
};

const LessonPlanImage: React.FC<Props> = ({ child }) => {
  const divStyle = {
    backgroundImage: 'url(' + ImageUtils.checkImageUrl(child.url) + ')',
  };

  const imageSizeHandler = (size) => {
    return size === 'contain' ? 'image-size-contain' : 'image-size-cover';
  };

  const imageSizeClass = imageSizeHandler(child.imageSize);

  return (
    <div className="plan-image">
      <div className={`plan-image ${imageSizeClass}`} style={divStyle}></div>
    </div>
  );
};

export default LessonPlanImage;
