import { ID } from '@lessonup/teaching-core';
import { AppError } from '@lessonup/utils';
import { includes } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { BaseExplorerTableProps, splitContentAndItemsToExplorerContent } from '../..';
import {
  ExplorerContent,
  ExplorerContentForType,
  ExplorerContentSplit,
  ExplorerContentType,
  ExplorerId,
  ExplorerUserContent,
  idToLessonItem,
  idToPlanItem,
  isPrivateFolder,
  Item,
  Location,
} from '../../../../../domain/newExplorers';
import { useBEM } from '../../../../utils/hooks';
import { FolderSvgGradients } from '../../../svgIcons/FolderSvg';
import { Cell, Table, TableBody } from '../../../Table';
import { Row } from '../../../Table/rows/Row/Row';
import { CheckboxCell, ImageCell } from '../../cells';
import { useExplorerSelectionContext } from '../../contexts';
import { useExplorerSorting } from '../../hooks';
import { SymbolicLinkOverLay } from '../../overlays';
import './CondensedExplorerTable.less';

interface ExplorerTableProps extends BaseExplorerTableProps {
  contents: ExplorerContentSplit;
  onLocationChange: (location: Omit<Location, 'explorer'> & { explorer?: ExplorerId }) => void;
  contentSelection?: {
    onContentSelect: (content: ExplorerUserContent[] | undefined) => void;
    isMultiselect?: boolean;
  };
  disabledRows?: Item[];
  isInPublicationFolder?: boolean;
}

type ElementForContent<T extends ExplorerContentType> = (content: ExplorerContentForType[T]) => JSX.Element;
type ElementForContentDict = {
  [key in ExplorerContentType]: ElementForContent<key>;
};

export const CondensedExplorerTable: React.FC<ExplorerTableProps> = ({
  contents,
  onLocationChange,
  contentSelection,
  disabledRows,
  enabledTypes,
  isInPublicationFolder,
}) => {
  const bemClasses = useBEM('CondensedExplorerTable');
  const { sorted } = useExplorerSorting(contents);
  const { isSelected, toggleRow } = useExplorerSelectionContext();
  const isMultiselect = contentSelection?.isMultiselect || false;

  const onContentClick = useCallback(
    (item: Item) => {
      if (!contentSelection) return;
      const { onContentSelect } = contentSelection;
      if (isMultiselect) return toggleRow(item);
      const content = splitContentAndItemsToExplorerContent(contents, item);
      if (!content) throw new AppError('not-found', 'Cannot find selected row in contents');
      onContentSelect([content]);
    },
    [contents, contentSelection, toggleRow]
  );

  const idIsDisabled = (id: ID): boolean => {
    const disabledRowsIds = disabledRows?.map((item) => item.id);
    return includes(disabledRowsIds, id);
  };

  const typeIsDisabled = (type: ExplorerContentType): boolean =>
    (Array.isArray(enabledTypes) && !enabledTypes.includes(type)) || false;

  const jsxForContentType: ElementForContentDict = useMemo(
    () => ({
      folder: (folder) => (
        <Row
          className={typeIsDisabled('folder') || idIsDisabled(folder._id) ? 'disabled' : ''}
          onClick={
            typeIsDisabled('folder') || idIsDisabled(folder._id)
              ? undefined
              : () => onLocationChange({ folder: folder._id })
          }
          key={folder._id}
        >
          {isMultiselect && <Cell />}
          <ImageCell
            type={isPrivateFolder(folder) ? 'privateFolder' : 'folder'}
            isInPublicationFolder={isInPublicationFolder}
          />
          <Cell>{folder.name}</Cell>
        </Row>
      ),
      sharedExplorer: ({ name, isPublish, _id }) => (
        <Row
          className={typeIsDisabled('sharedExplorer') || idIsDisabled(_id) ? 'disabled' : ''}
          onClick={
            typeIsDisabled('sharedExplorer') || idIsDisabled(_id)
              ? undefined
              : () => onLocationChange({ folder: _id, explorer: _id })
          }
          key={_id}
        >
          {isMultiselect && <Cell />}
          <ImageCell type="sharedExplorer" isPublish={isPublish} />
          <Cell>{name}</Cell>
        </Row>
      ),
      lesson: ({ name, _id, thumbnail }) => (
        <Row
          className={typeIsDisabled('lesson') || idIsDisabled(_id) ? 'disabled' : ''}
          key={_id}
          onClick={
            typeIsDisabled('lesson') || idIsDisabled(_id) ? undefined : () => onContentClick(idToLessonItem(_id))
          }
        >
          {isMultiselect && (
            <CheckboxCell
              id={`checkboxcell-lesson-${_id}`}
              onChange={() => toggleRow(idToLessonItem(_id))}
              isSelected={isSelected(_id)}
              cellType={'checkboxCell'}
            ></CheckboxCell>
          )}
          <ImageCell type="lesson" imageSource={thumbnail?.url} />
          <Cell>{name}</Cell>
        </Row>
      ),
      lessonPlan: ({ name, _id }) => (
        <Row
          className={typeIsDisabled('lessonPlan') || idIsDisabled(_id) ? 'disabled' : ''}
          key={_id}
          onClick={
            typeIsDisabled('lessonPlan') || idIsDisabled(_id) ? undefined : () => onContentClick(idToPlanItem(_id))
          }
        >
          {isMultiselect && (
            <CheckboxCell
              id={`checkbox-lessonplan-${_id}`}
              onChange={() => toggleRow(idToPlanItem(_id))}
              isSelected={isSelected(_id)}
              cellType={'checkboxCell'}
            ></CheckboxCell>
          )}
          <ImageCell type="lessonPlan" />
          <Cell>{name}</Cell>
        </Row>
      ),
      symbolicLink: (item) => {
        if (!item.content) {
          return <></>;
        }
        let imageCell = <Cell></Cell>;

        if (item.type === 'lesson') {
          const { thumbnail } = item.content;
          imageCell = (
            <ImageCell type="lesson" imageSource={thumbnail?.url}>
              <SymbolicLinkOverLay />
            </ImageCell>
          );
        }

        if (item.type === 'lessonPlan') {
          imageCell = (
            <ImageCell type="lessonPlan">
              <SymbolicLinkOverLay />
            </ImageCell>
          );
        }

        return (
          <Row className={typeIsDisabled('symbolicLink') ? 'disabled' : ''} key={item._id}>
            {imageCell}
            <Cell>{item.content!.name}</Cell>
          </Row>
        );
      },
    }),
    [onLocationChange]
  );

  const rows: JSX.Element[] = useMemo(
    () =>
      sorted.map((item: ExplorerContent) => {
        return jsxForContentType[item.type](item.content as any);
      }),
    [sorted, jsxForContentType]
  );

  return (
    <div className={bemClasses()}>
      <Table className={bemClasses({ element: 'table', className: 'table-padding-1 th-left' })}>
        <TableBody>{rows}</TableBody>
        <FolderSvgGradients />
      </Table>
    </div>
  );
};
