import { logger } from '@lessonup/client-integration';
import { AppError } from '@lessonup/utils';
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { loginRoute, LoginStep, registerRoute } from '../../../shared-core/services/app/searchRoutes';
import capitalize from '../../../shared-core/utils/capitalize';
import { isUserSignedIn } from '../../redux/selectors';
import { RedirectService } from '../../services/RedirectService';
import pageTitle from '../../utils/pageTitle';
import GrayPageLayout, { GrayPageLayoutTitle, GrayPageSection } from '../GrayPageLayout/GrayPageLayout';
import LoginForm from './loginSteps/LoginForm';
import LoginSelectApplication from './loginSteps/LoginSelectApplication';

const TRANSLATION_NS = 'login';

export interface LoginStepProps {
  next: () => void;
}
type Props = RouteComponentProps;

const LoginPage: React.FC<Props> = (props) => {
  const { t } = useTranslation(TRANSLATION_NS);
  const userSignedIn = useSelector(isUserSignedIn());
  const step: LoginStep | undefined = props.match.params['step'];
  RedirectService.setRedirectUrlFromQueryString(props.location.search);

  const gotoStep = useCallback(
    (nextStep: LoginStep) => {
      const route = loginRoute(nextStep);
      step !== 'select' ? props.history.replace(route) : props.history.push(route);
    },
    [step, props.history]
  );

  useEffect(() => {
    if (!step) gotoStep('select');
  }, [gotoStep, props.history, step, userSignedIn]);

  function next() {
    if (step === 'form') {
      const error = new AppError('not-found', 'Already on last page');
      logger.error(error);
      return;
    }
    gotoStep('form');
  }

  return (
    <GrayPageLayout className="login-page">
      <LoginPageMetaTags />
      <GrayPageSection position="top">
        <div>
          <GrayPageLayoutTitle className="login-title">{capitalize(t('title'))}</GrayPageLayoutTitle>
          {RedirectService.getRedirectUrl() ? (
            <p className="require-login-message">{t('loginRequired')}</p>
          ) : (
            <p style={{ textAlign: 'center' }}>
              {step === 'select' ? t('loginSelectSubtitle') : t('loginFormSubtitle')}
            </p>
          )}
        </div>
      </GrayPageSection>
      <GrayPageSection position="center" className="grow">
        {step === 'form' ? <LoginForm /> : <LoginSelectApplication next={next} />}
      </GrayPageSection>
      <GrayPageSection position="bottom">
        <Link to={registerRoute(step === 'form' ? 'credentials' : undefined)} className="back-link">
          {t('register')}
        </Link>
      </GrayPageSection>
    </GrayPageLayout>
  );
};

function LoginPageMetaTags() {
  const { t } = useTranslation(TRANSLATION_NS);

  return (
    <Helmet>
      <title>{pageTitle(t('pageTitle'))}</title>
    </Helmet>
  );
}

export default LoginPage;
