import classNames from 'classnames';
import React from 'react';
import './GrayTabs.less';

export interface TabProps {
  name: string;
  active: string | undefined;
  clickHandler?: (value: string) => void;
}

interface Props {
  secondaryChild?: React.ReactNode;
}

export const GrayTabs: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="gray-tabs-bar">
      <div className="tabs">{props.children}</div>
      {props.secondaryChild && <div className="secondary-child">{props.secondaryChild}</div>}
    </div>
  );
};

export const GrayTab: React.FunctionComponent<TabProps> = (props) => {
  const { name, active } = props;

  function clickHandler() {
    return props.clickHandler && props.clickHandler(name);
  }

  return (
    <div className={classNames('gray-tab', { active: active === name })} onClick={clickHandler}>
      {props.children}
    </div>
  );
};
