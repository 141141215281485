import { Colors, Component } from '@lessonup/teaching-core';
import React, { useEffect, useRef, useState } from 'react';
import { ImageUtils } from '../../../../utils/ImageUtils';
import { secondsToPrettyTime } from '../../../../utils/TimeUtils';
import ComponentContainer from '../componentContainer/ComponentContainer';
import './AudioComponentView.less';

interface AudioComponentViewProps {
  component: Component.Audio;
  onClickHandler?: Function;
  defaultState?: string;
  isThumb?: boolean;
}

interface TitleProps {
  title: string | undefined;
}

const AudioComponentView: React.FC<AudioComponentViewProps> = (props) => {
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);
  const [showAudioDuration, setShowAudioDuration] = useState<boolean>(false);
  const [audioTime, setAudioTime] = useState<number>();
  const [isAudioLoaded, setIsAudioLoaded] = useState<boolean>(false);

  const componentTypeClasses = () => {
    const { position } = props.component;
    const positionX = position.left > 50 ? 'pos-right' : 'pos-left';
    const positionY = position.top > 50 ? 'pos-bottom' : 'pos-top';
    return `view-component-audio ${positionX} ${positionY}`;
  };
  const { settings } = props.component;
  const audioFile = useRef<HTMLAudioElement>(null);

  const audioProps: AudioHandlerProps = {
    audioFile: audioFile.current,
    isAudioPlaying,
    setIsAudioPlaying,
    setShowAudioDuration,
    showAudioDuration,
    setAudioTime,
  };

  useEffect(() => {
    if (audioFile.current && !isAudioLoaded && !props.isThumb && audioFile.current.readyState < 4) {
      audioFile.current
        .play()
        .catch(() => {
          // Error won't be noticed by the user and error handling is not needed.
        })
        .finally(() => audioFile?.current?.pause());

      setIsAudioLoaded(true);
    }
  }, [audioFile, isAudioLoaded, props.isThumb]);

  const handleTimeUpdate = () => {
    if (audioFile.current) calculateAudioTime(audioProps);
  };

  return (
    <div>
      <ComponentContainer
        {...props}
        componentTypeClasses={componentTypeClasses()}
        isInteractive={!props.isThumb}
        onClickHandler={(e) => audioHandler(e, audioProps)}
      >
        <Title title={settings.title} />
        <div className="border" data-grippy="move"></div>
        {settings.image ? (
          <IconImage image={settings.image} imageSize={settings.imageSize} color={settings.color} />
        ) : (
          <Icon color={settings.color} icon={settings.icon} />
        )}
        {settings.url ? (
          <>
            <audio ref={audioFile} onTimeUpdate={handleTimeUpdate}>
              <source src={settings.url} type={settings.contentType} />
            </audio>
            {audioFile.current && showAudioDuration ? (
              <p className="audio-duration font-courier paused">
                <span
                  className="icon icon-undo"
                  data-role="reset-player"
                  onClick={(e) => resetAudio(e, audioProps)}
                ></span>
                {secondsToPrettyTime(audioTime)}
              </p>
            ) : null}
          </>
        ) : null}
      </ComponentContainer>
    </div>
  );
};

export default AudioComponentView;

function Title(props: TitleProps) {
  if (!props.title) return null;
  return (
    <div className="audio-title" data-grippy="move">
      {props.title}
    </div>
  );
}

function Icon(props) {
  const iconClass = props.icon ? 'icon-' + props.icon : 'icon-plus';
  const darkClass = Colors.needsDarkIcon(props.color) ? 'icon-dark' : '';
  const colorClass = props.color ? `bg-${props.color}` : '';

  return (
    <div className={`core ${darkClass} ${colorClass}`} style={{ backgroundColor: props.color }} data-grippy="move">
      <div className={`core-icon icon ${darkClass} ${iconClass}`} data-grippy="move"></div>
    </div>
  );
}

function IconImage(props) {
  const darkClass = Colors.needsDarkIcon(props.color) ? 'icon-dark' : '';
  const colorClass = props.color ? `bg-${props.color}` : '';

  const style = () => ({
    backgroundImage: ImageUtils.urlString(ImageUtils.checkImageUrl(props.image)),
    backgroundSize: props.imageSize,
  });

  return (
    <div className={`core ${darkClass} ${colorClass}`} data-grippy="move">
      <div className={`core-image`} style={style()} data-grippy="move"></div>
    </div>
  );
}

interface AudioHandlerProps {
  isAudioPlaying: boolean;
  setIsAudioPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  showAudioDuration: boolean;
  setShowAudioDuration: React.Dispatch<React.SetStateAction<boolean>>;
  audioFile: HTMLAudioElement | null;
  setAudioTime: React.Dispatch<React.SetStateAction<number>>;
}

const playAudio = ({ audioFile, setIsAudioPlaying }: AudioHandlerProps) => {
  if (!audioFile) return;
  audioFile.play();
  setIsAudioPlaying(true);
};

const pauseAudio = ({ audioFile, setIsAudioPlaying }: AudioHandlerProps) => {
  if (!audioFile) return;
  audioFile.pause();
  setIsAudioPlaying(false);
};

const audioHandler = (event: React.MouseEvent, audioProps: AudioHandlerProps) => {
  const { isAudioPlaying, showAudioDuration, setShowAudioDuration, audioFile } = audioProps;
  if (!audioFile) return;

  if (event && !isAudioPlaying) {
    playAudio(audioProps);
  } else {
    pauseAudio(audioProps);
  }
  if (!showAudioDuration) setShowAudioDuration(true);
};

const resetAudio = (event: React.MouseEvent | undefined, audioProps: AudioHandlerProps) => {
  const { setIsAudioPlaying, setShowAudioDuration, audioFile } = audioProps;
  event?.stopPropagation();
  if (!audioFile) return;
  pauseAudio(audioProps);
  setShowAudioDuration(false);
  setIsAudioPlaying(false);
  audioFile.currentTime = 0;
};

const calculateAudioTime = (audioProps: AudioHandlerProps) => {
  const { audioFile, setAudioTime } = audioProps;
  if (!audioFile) return;
  const { currentTime, duration } = audioFile;
  const totalSeconds = duration - currentTime;

  if (totalSeconds === 0) {
    resetAudio(undefined, audioProps);
  }

  return setAudioTime(totalSeconds);
};
