import React from 'react';

interface TableBodyProps {
  ref?: React.Ref<HTMLTableSectionElement>;
  className?: string;
}

export const TableBody: React.FC<TableBodyProps> = React.forwardRef(({ children, className }, ref) => (
  <tbody className={className} ref={ref}>
    {children}
  </tbody>
));

export default TableBody;
