import i18next from 'i18next';
import { Facets, IndexedChannelInfo, SelectedFacet } from '../../../../shared-core/domain';

export default function generateMetaDescription(
  selectedFacets: SelectedFacet[],
  count: number | undefined,
  type: 'lessons' | 'plans',
  t: i18next.TFunction,
  channelInfo?: IndexedChannelInfo
): string {
  if (!selectedFacets.length) return type === 'lessons' ? t('lessonsMetaFallback') : t('plansMetaFallback');

  const { schoolType, yearAndLevel, subject } = Facets.obtainMetaDataInfoForSelectedFacets(selectedFacets);

  let description =
    t('metaLookingFor', { subject: subject || schoolType || '' }) +
    ' ' +
    t(type === 'lessons' ? 'metaDescriptionContentLessons' : 'metaDescriptionContentPlans', {
      subject: subject || '',
      count,
      formattedCount: count && count.toLocaleString(),
    });

  if (schoolType) description += ' ' + schoolType.toLowerCase();
  if (yearAndLevel) description += ' ' + yearAndLevel;
  return description + '.';
}
