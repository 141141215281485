import React from 'react';
import { useContextGenerator } from '../../../shared-core/ui/utils/react';
import { AppServices } from '../../services/AppServices';

type Context = AppServices | undefined;

export const AppServicesContext = React.createContext<Context>(undefined);

interface Props {
  services: AppServices;
}

export class AppServicesProvider extends React.PureComponent<Props> {
  public render() {
    return <AppServicesContext.Provider value={this.props.services}>{this.props.children}</AppServicesContext.Provider>;
  }
}

export const useAppServices = useContextGenerator(AppServicesContext, 'AppServicesContext');

interface ConsumerProps {
  render: (services: AppServices) => React.ReactNode;
}

export class AppServicesConsumer extends React.PureComponent<ConsumerProps> {
  public render() {
    return (
      <AppServicesContext.Consumer>
        {(services) => {
          if (services === undefined) {
            throw new Error('AppServicesContext must be used within a Provider');
          }
          return this.props.render(services);
        }}
      </AppServicesContext.Consumer>
    );
  }
}
