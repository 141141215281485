import { PinItem, Slide } from '@lessonup/teaching-core';
import _ from 'lodash';
import React from 'react';
import { FontsSingleton } from '../../../../services/fonts/FontsSingleton';
import { ContentTypeDefaults } from '../../../../utils/ContentTypeDefaults';

interface Props {
  type: 'text1' | 'text2';
  item: PinItem<Slide.Content>;
}

export default class Text extends React.Component<Props> {
  public componentDidMount() {
    const custom = this.props.item.custom;
    const font = this.props.type === 'text1' ? custom.font1 : custom.font2;
    if (font) FontsSingleton.add([font]);
  }

  private prettifiedSlideContents = () => {
    const { type, item } = this.props;
    const html = item.custom[type]?.replace(/\n/gi, '<br />');
    return <div className="text-inner" dangerouslySetInnerHTML={{ __html: html || '' }}></div>;
  };

  private classes = () => {
    const { align1, align2, font1, font2 } = this.props.item.custom;
    const { type } = this.props;
    const align = type === 'text1' ? align1 : align2;
    const fontSize = `font-size-${this.fontSize(type)}`;
    const font = type === 'text1' ? font1 : font2;

    return `text text-comp ${this.renderText1AsText2() ? 'text2' : type} text-align-${align} ${fontSize} font-${font}`;
  };

  private fontSize = (type: string) => {
    const pinType = this.props.item.type;

    if (type === 'text1') {
      return this.props.item.custom.fontSize1 || ContentTypeDefaults.getDefaultFontSize(1, pinType);
    } else {
      return this.props.item.custom.fontSize2 || ContentTypeDefaults.getDefaultFontSize(2, pinType);
    }
  };

  private renderText1AsText2 = (): boolean => _.includes([7, 8], this.props.item.custom.layout);

  public render() {
    if (!this.props.item.custom[this.props.type]) {
      return null;
    }

    return (
      <div id={this.props.type} className={this.classes()}>
        {this.prettifiedSlideContents()}
      </div>
    );
  }
}
