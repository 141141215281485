import { addWeeks } from '@lessonup/utils';
import { captureMessage, Severity, withScope } from '@sentry/browser';
import i18n, { PostProcessorModule } from 'i18next';
import Cookies from 'js-cookie';
import { compact, isArray } from 'lodash';
import { initReactI18next } from 'react-i18next';

import { Language } from '../../domain';
import { formalizeTranslation } from '../../services/translations/utils';
import { Translations } from './translations';

export const FORMALIZATION_POST_PROCESSOR_NAME = 'formalizationPostProcessor';
export const FORMALIZATION_COOKIE_NAME = 'useFormalLanguage';

export type LanguageKey = Language.Key;

const FALLBACK_LANGUAGE: LanguageKey[] = ['nl', 'en', 'fr'];

export interface CustomI18n extends i18n.i18n {
  setFormalization: (useFormal: boolean) => void;
}

export const useFormalLanguageOnLoad = !!Cookies.get(FORMALIZATION_COOKIE_NAME);

export function initi18n(resources: i18n.Resource, userLanguage: Language.Key = Language.defaultLanguage): CustomI18n {
  const instance = i18n.createInstance() as CustomI18n;

  instance.use(initReactI18next);
  instance.use(formalizationPostProcessor);

  instance.init({
    resources,
    fallbackLng: FALLBACK_LANGUAGE,
    saveMissing: true,
    lng: userLanguage,
    missingKeyHandler: (lang, namespace, key, fallbackValue) => {
      console.warn(`Missing key ${key} in namespace ${namespace} for language ${lang}`);
      withScope((scope) => {
        scope.setLevel(Severity.Warning);
        // Set tags so that we can easily search for this warning in Sentry
        scope.setTag('category', 'i18n');
        scope.setTag('key', 'missing-translation');
        captureMessage(`Missing key ${key} in namespace ${namespace} for language ${lang}`);
      });
    },
    interpolation: {
      escapeValue: false,
    },
    postProcess: useFormalLanguageOnLoad ? [FORMALIZATION_POST_PROCESSOR_NAME] : [],
  });

  function setFormalization(useFormal: boolean): void {
    const currentProcessors: string[] = compact(
      isArray(instance.options.postProcess) ? instance.options.postProcess : [instance.options.postProcess]
    );

    if (useFormal) {
      const expires = addWeeks(new Date(), 1);
      Cookies.set(FORMALIZATION_COOKIE_NAME, 'true', { expires });
      instance.options.postProcess = [...currentProcessors, FORMALIZATION_POST_PROCESSOR_NAME];
    } else {
      Cookies.remove(FORMALIZATION_COOKIE_NAME);
      instance.options.postProcess = currentProcessors.filter(
        (processor) => processor !== FORMALIZATION_POST_PROCESSOR_NAME
      );
    }
    instance.changeLanguage(instance.language);
  }

  instance.setFormalization = setFormalization;

  Translations.initialize((key, params) => instance.t(key, params));

  return instance;
}

const formalizationPostProcessor: PostProcessorModule = {
  type: 'postProcessor',
  name: FORMALIZATION_POST_PROCESSOR_NAME,
  process(value) {
    return formalizeTranslation(value);
  },
};
