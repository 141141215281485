import { Lesson, LessonPin, PinType, UserContentPrivacy } from '@lessonup/teaching-core';
import { EnvConfig } from '../../../services';
import { Channel } from '../../channels';
import { LessonPlan } from '../../lessonPlan';
import { MongoUser } from '../../user';

export interface FacetMetaData {
  creationDate: Date;
  subjects: string[];
  schoolType: string[];
  levels: string[];
  years: number[];
  organizations: string[];
  country: string[];
  products: string[] | undefined;
  privacy: UserContentPrivacy;
  isTest: boolean;
}

export namespace FacetMetaData {
  let _envConfig: EnvConfig | undefined;

  //TODO: fix so dependencies are clear
  export function setConfig(config: EnvConfig) {
    _envConfig = config;
  }
  // never add empty array!!
  function productList(products: string[] | undefined) {
    if (products && products.length) {
      return products;
    }
  }

  function privacyForUser(user?: MongoUser): UserContentPrivacy {
    const privateEmails =
      _envConfig && (_envConfig.isLocal || _envConfig?.isDev)
        ? []
        : ['@q42.nl', '@lessonup.io', '@lessonup.com', '@lessonup.nl'];

    if (user) {
      const email = MongoUser.emailFromMongoUser(user);
      if (email && privateEmails.some((e) => email.indexOf(e) > -1)) {
        return 'private';
      }
    }

    return 'public';
  }

  function isPrivateForPins(pins: LessonPin[]): boolean {
    const pintypes = getPrivatePinTypes();
    return pins.some((pin) => pintypes.includes(pin.item.type));
  }

  export const getPrivatePinTypes = (): PinType[] => {
    return [];
  };

  function privacyForLesson({
    lesson,
    user,
    pins,
    channel,
  }: {
    lesson: Lesson;
    user?: MongoUser;
    pins?: LessonPin[];
    channel?: Channel;
  }): UserContentPrivacy {
    if (lesson.privacy === 'private' || lesson.productsLocked) return 'private';
    if (pins?.length && isPrivateForPins(pins)) return 'private';
    if (lesson.privacy === 'protected') return 'protected';
    if (channel?.ignoreLessonUpSearchFilter) return 'public';

    return privacyForUser(user);
  }

  export function fromLesson(params: {
    lesson: Lesson;
    user?: MongoUser;
    pins?: LessonPin[];
    channel?: Channel;
  }): FacetMetaData {
    const { country, schoolType, levels, years, subjects, products, isTest, creationDate } = params.lesson;

    return {
      creationDate,
      country: country || [],
      schoolType: schoolType || [],
      levels: levels || [],
      years: years || [],
      organizations: MongoUser.memberOfOrganizationList(params.user).map((org) => org.id),
      subjects: subjects || [],
      products: productList(products),
      privacy: privacyForLesson(params),
      isTest: isTest || false,
    };
  }

  function privacyForPlan(plan: LessonPlan, user?: MongoUser): UserContentPrivacy {
    if (plan.privacy === 'private') return 'private';
    return privacyForUser(user);
  }

  export function fromPlan(plan: LessonPlan, user?: MongoUser): FacetMetaData {
    const { schoolType, levels, years, subjects } = plan.metaData || {
      schoolType: [],
      levels: [],
      years: [],
      subjects: [],
    };

    const { products } = plan;

    return {
      creationDate: plan.creationDate,
      country: plan.country || [],
      schoolType,
      levels,
      organizations: MongoUser.memberOfOrganizationList(user).map((org) => org.id),
      years,
      subjects,
      products: productList(products),
      privacy: privacyForPlan(plan, user),
      isTest: false,
    };
  }
}
