import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { logger, trackAction } from '@lessonup/client-integration';
import Button from '../../../../shared-core/ui/components/Button';
import { followChannelAction, unfollowChannelAction } from '../../../redux/actions/actions';
import { isChannelFollowed, loggedInUser } from '../../../redux/selectors';
import { useAppServices } from '../../appServices/AppServicesContext';

interface Props {
  channelSlug: string;
  requireFollow: boolean;
  channelTitle?: string;
  followContext: {
    trigger: 'follow-button' | 'save-lesson' | 'play-lesson' | 'channel-page' | 'lesson-page' | 'plan-page';
    planId?: string;
    lessonId?: string;
  };
  tracker: string | undefined;
  className?: string;
}

type FollowAction = 'follow' | 'unfollow';

const TRANSLATION_NAMESPACE = 'channel-info';

// TODO: see if the not logged in stuff can go.
const FollowChannelButton: React.FC<Props> = ({
  channelSlug,
  requireFollow,
  channelTitle,
  followContext,
  tracker,
  className,
}) => {
  const services = useAppServices();
  const user = useSelector(loggedInUser());
  const isFollowing = useSelector(isChannelFollowed(channelSlug));
  const dispatch = useDispatch();

  if (!user) return null;

  function toggleFollowChannel(action?: FollowAction) {
    const follow = action ? action === 'follow' : !isFollowing;
    if (follow) {
      followChannel();
    } else {
      unFollowChannel();
    }
  }

  async function followChannel() {
    try {
      if (requireFollow) {
        const response = await services.channel.followChannelAndRequestLeadForm(channelSlug, channelTitle ?? '', {
          type: 'channel',
        });
        if (!response.success) {
          return;
        }
      } else {
        await services.channel.followChannelWithUserId(channelSlug, { type: 'channel' });
      }
      dispatch(followChannelAction(channelSlug));
    } catch (error) {
      logger.error(error);
    }
  }

  async function unFollowChannel() {
    try {
      await services.channel.unfollowChannel(channelSlug);
      dispatch(unfollowChannelAction(channelSlug));
      trackAction('unfollow-channel', {
        channelSlug,
        ...followContext,
        tracker,
      });
    } catch (error) {
      logger.error(error);
    }
  }

  return isFollowing ? (
    <UnFollowButton handleClick={toggleFollowChannel} className={className} />
  ) : (
    <FollowButton handleClick={toggleFollowChannel} className={className} />
  );
};

type FollowClickHandler = (action?: FollowAction) => void;

function FollowButton({ handleClick, className }: { handleClick: FollowClickHandler; className?: string }) {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  return (
    <Button
      theme="tertiary"
      height="small"
      onClick={() => handleClick('follow')}
      className={classNames('icon icon-user-plus', className)}
    >
      {t('follow')}
    </Button>
  );
}

function UnFollowButton({ handleClick, className }: { handleClick: FollowClickHandler; className?: string }) {
  const { t } = useTranslation(TRANSLATION_NAMESPACE);

  return (
    <Button
      theme="tertiary"
      height="small"
      onClick={() => handleClick('unfollow')}
      className={classNames('icon icon-user-check', className)}
    >
      {t('unfollow')}
    </Button>
  );
}

export default FollowChannelButton;
